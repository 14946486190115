import { uniq } from 'underscore'

export const DocumentTypeFilters = [
    { "key": "Slide Decks/Slide Decks", "doc_count": 2312 },
    { "key": "Slide Decks/Slide Decks/Regions", "doc_count": 2312 },
    { "key": "Slide Decks/Slide Decks/Regions/ASPAC", "doc_count": 267 },
    { "key": "Slide Decks/Slide Decks/Regions/ASPAC/Proactive", "doc_count": 267 },
    { "key": "Slide Decks/Slide Decks/Regions/Core Slide Decks", "doc_count": 317 },
    { "key": "Slide Decks/Slide Decks/Regions/EMEA", "doc_count": 203 },
    { "key": "Slide Decks/Slide Decks/Regions/EMEA/Reactive", "doc_count": 203 },
    { "key": "Slide Decks/Slide Decks/Regions/LATAM", "doc_count": 299 },
    { "key": "Slide Decks/Slide Decks/Regions/LATAM/HCP-Proactive", "doc_count": 202 },
    { "key": "Slide Decks/Slide Decks/Regions/LATAM/HCP-Reactive", "doc_count": 242 },
    { "key": "Slide Decks/Slide Decks/Regions/North America", "doc_count": 1226 },
    { "key": "Slide Decks/Slide Decks/Regions/North America/Proactive Decks for Dissemination", "doc_count": 38 },
    { "key": "Slide Decks/Slide Decks/Regions/North America/Proactive Decks for Presentation", "doc_count": 277 },
    { "key": "Slide Decks/Slide Decks/Regions/North America/Reactive Decks for Dissemination", "doc_count": 513 },
    { "key": "Slide Decks/Slide Decks/Regions/North America/Reactive Decks for Presentation", "doc_count": 825 },
    { "key": "Slide Decks/Slide Decks/Countries", "doc_count": 2102 },
    { "key": "Slide Decks/Slide Decks/Countries/Antigua and Barbuda", "doc_count": 255 },
    { "key": "Slide Decks/Slide Decks/Countries/Argentina", "doc_count": 273 },
    { "key": "Slide Decks/Slide Decks/Countries/Aruba", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Australia", "doc_count": 30 },
    { "key": "Slide Decks/Slide Decks/Countries/Bahamas", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Barbados", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Belice", "doc_count": 253 },
    { "key": "Slide Decks/Slide Decks/Countries/Bermuda", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Bolivia", "doc_count": 265 },
    { "key": "Slide Decks/Slide Decks/Countries/Brazil", "doc_count": 285 },
    { "key": "Slide Decks/Slide Decks/Countries/Canada", "doc_count": 90 },
    { "key": "Slide Decks/Slide Decks/Countries/Cayman Islands", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Chile", "doc_count": 270 },
    { "key": "Slide Decks/Slide Decks/Countries/China", "doc_count": 227 },
    { "key": "Slide Decks/Slide Decks/Countries/Colombia", "doc_count": 271 },
    { "key": "Slide Decks/Slide Decks/Countries/Costa Rica", "doc_count": 270 },
    { "key": "Slide Decks/Slide Decks/Countries/Curacao", "doc_count": 253 },
    { "key": "Slide Decks/Slide Decks/Countries/Dominican Republic", "doc_count": 271 },
    { "key": "Slide Decks/Slide Decks/Countries/EMEA", "doc_count": 192 },
    { "key": "Slide Decks/Slide Decks/Countries/Ecuador", "doc_count": 270 },
    { "key": "Slide Decks/Slide Decks/Countries/El Salvador", "doc_count": 265 },
    { "key": "Slide Decks/Slide Decks/Countries/Grenada", "doc_count": 253 },
    { "key": "Slide Decks/Slide Decks/Countries/Guatemala", "doc_count": 269 },
    { "key": "Slide Decks/Slide Decks/Countries/Guyana", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Haiti", "doc_count": 253 },
    { "key": "Slide Decks/Slide Decks/Countries/Honduras", "doc_count": 267 },
    { "key": "Slide Decks/Slide Decks/Countries/Hong Kong", "doc_count": 18 },
    { "key": "Slide Decks/Slide Decks/Countries/India", "doc_count": 2 },
    { "key": "Slide Decks/Slide Decks/Countries/Jamaica", "doc_count": 252 },
    { "key": "Slide Decks/Slide Decks/Countries/Korea", "doc_count": 34 },
    { "key": "Slide Decks/Slide Decks/Countries/LATAM", "doc_count": 190 },
    { "key": "Slide Decks/Slide Decks/Countries/Malaysia", "doc_count": 3 },
    { "key": "Slide Decks/Slide Decks/Countries/Mexico", "doc_count": 270 },
    { "key": "Slide Decks/Slide Decks/Countries/Nicaragua", "doc_count": 266 },
    { "key": "Slide Decks/Slide Decks/Countries/Panama", "doc_count": 268 },
    { "key": "Slide Decks/Slide Decks/Countries/Paraguay", "doc_count": 268 },
    { "key": "Slide Decks/Slide Decks/Countries/Peru", "doc_count": 271 },
    { "key": "Slide Decks/Slide Decks/Countries/Saint Martin", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Singapore", "doc_count": 11 },
    { "key": "Slide Decks/Slide Decks/Countries/St. Lucia", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/St. Vincent", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Surinam", "doc_count": 254 },
    { "key": "Slide Decks/Slide Decks/Countries/Taiwan", "doc_count": 27 },
    { "key": "Slide Decks/Slide Decks/Countries/Thailand", "doc_count": 2 },
    { "key": "Slide Decks/Slide Decks/Countries/Trinidad and Tobago", "doc_count": 253 },
    { "key": "Slide Decks/Slide Decks/Countries/United States", "doc_count": 1126 },
    { "key": "Slide Decks/Slide Decks/Countries/Uruguay", "doc_count": 270 },
    { "key": "Slide Decks/Slide Decks/Countries/Venezuela", "doc_count": 208 },
    { "key": "Slide Decks/Slide Decks/Countries/Vietnam", "doc_count": 5 },
    { "key": "Scientific Response/Scientific Response", "doc_count": 4483 },
    { "key": "Scientific Response/Scientific Response/Audience", "doc_count": 3947 },
    { "key": "Scientific Response/Scientific Response/Audience/Consumer", "doc_count": 2291 },
    { "key": "Scientific Response/Scientific Response/Audience/HCP", "doc_count": 2291 },
    { "key": "Scientific Response/Scientific Response/Regions", "doc_count": 2312 },
    { "key": "Scientific Response/Scientific Response/Regions/ASPAC", "doc_count": 267 },
    { "key": "Scientific Response/Scientific Response/Regions/EMEA", "doc_count": 203 },
    { "key": "Scientific Response/Scientific Response/Regions/Global (GCSRs only)", "doc_count": 203 },
    { "key": "Scientific Response/Scientific Response/Regions/LATAM", "doc_count": 299 },
    { "key": "Scientific Response/Scientific Response/Regions/North America", "doc_count": 1226 },
    { "key": "Scientific Response/Scientific Response/Countries", "doc_count": 3658 },
    { "key": "Scientific Response/Scientific Response/Countries/Australia", "doc_count": 21 },
    { "key": "Scientific Response/Scientific Response/Countries/Canada", "doc_count": 92 },
    { "key": "Scientific Response/Scientific Response/Countries/China", "doc_count": 11 },
    { "key": "Scientific Response/Scientific Response/Countries/EMEA", "doc_count": 8 },
    { "key": "Scientific Response/Scientific Response/Countries/Japan", "doc_count": 5 },
    { "key": "Scientific Response/Scientific Response/Countries/LATAM", "doc_count": 778 },
    { "key": "Scientific Response/Scientific Response/Countries/Malaysia", "doc_count": 5 },
    { "key": "Scientific Response/Scientific Response/Countries/New Zealand", "doc_count": 14 },
    { "key": "Scientific Response/Scientific Response/Countries/United States", "doc_count": 2738 },
    { "key": "Labels/Labels", "doc_count": 4483 },
    { "key": "Labels/Labels/CCDS Current Clean In Use", "doc_count": 4483 },
    { "key": "Labels/Labels/USPI Current Clean In Use", "doc_count": 4483 },
]

export const processGenAIDocumentTypeFilters = (response) => {
    const facetOrder = {
        'Slide Decks': 1,
        'Scientific Response': 2,
    }

    const tmpFilters = [...response]

    // First, ensure the filters are sorted alphabetically
    // tmpFilters.sort((a, b) => {
    //     if (a.key < b.key) {
    //         return -1
    //     }

    //     if (a.key > b.key) {
    //         return 1
    //     }

    //     return 0
    // })

    // Convert the filters into a nested tree
    var filters = tmpFilters.reduce((result, { key, doc_count: count }) => {
        let tmp = result
        const parts = key.split('/')

        const lastPart = parts.pop()

        // Traverse to the part of the tree where this filter lives (creating any
        // parents as necessary)
        parts.forEach(part => {
            if (tmp[part] === undefined) {
                tmp[part] = {
                    label: part,
                    count: 0,
                    children: {}
                }
            }

            tmp = tmp[part].children
        })

        // Add the filter to the tree
        if (tmp[lastPart]) {
            tmp[lastPart].count = count
        } else {
            const partData = {
                key,
                label: lastPart,
                count,
                children: {}
            }

            if (parts.length <= 0) {
                partData.order = facetOrder[key] || 100
            }

            tmp[lastPart] = partData
        }
        /* this.setState({ filters: result },()=>{
            //console.log(this.state.document,this.state.documentCount)
            this.props.handleAllfilters(this.state.filters,this.state.appliedFilters,this.state.selectedFilters,this.state.document,this.state.documentCount,this.state.query)
            this.props.handleIsProcessing(true)
        }) */
        //console.log(result)
        return result
    }, {})
    return filters
};

export const GenAI2ContentFilter = {
    "Labels": "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)",
    "Slide Decks": "Document Type/Slide Decks",
    "Posters": "Document Type/Medical Literature/Posters",
    "Clinical Study Reports": "Document Type/Submission-Related Documents/Clinical Study Reports",
    "Protocols": "Document Type/Submission-Related Documents/Protocols",
}
export const GenAI2DocTypes = {
    "Scientific Response": "Document Type/Scientific Response Documents",
    "Slide Decks": "Document Type/Slide Decks",
    "Posters": "Document Type/Medical Literature/Posters",
    "Labels": "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use);Document Type/Labeling Documents - Core, EU, US/Core Labeling/Company Core Data Sheets/CCDS Current clean version (Clean, In-Use)",
    "Clinical Study Reports": "Document Type/Submission-Related Documents/Clinical Study Reports",
    "Protocols": "Document Type/Submission-Related Documents/Protocols",
};
export const processFilters = (response) => {

    if (response && response.length === 0) {
        return [];
    }
    let slideDecksFilters = [], slideDecksCountriesFilters = [],
        scientificResponseFilters = [], scientificResponseRegionsFilters = [], scientificResponseCountriesFilters = [], finalFilters = [];
    response.map((dtItem, dtIndex) => {
        if (dtItem.includes('Scientific Response/Scientific Response')) {
            dtItem = dtItem.replace('Scientific Response/Scientific Response', 'Document Type/Scientific Response Documents')
            dtItem = dtItem.replace('/Audience', '/Scientific Responses')



            if (dtItem.endsWith('/Regions')) {
                dtItem = dtItem.replace('/Regions', '');
                scientificResponseFilters.push(dtItem);
            }
            else if (dtItem.includes('/Regions')) {
                let dtItemSplit = dtItem.split('Regions/');
                if (dtItemSplit.length > 1) {
                    scientificResponseRegionsFilters.push(dtItemSplit[1])
                }
            }
            else if (dtItem.endsWith('/Countries')) {
                dtItem = dtItem.replace('/Countries', '');
                scientificResponseFilters.push(dtItem);
            }
            else if (dtItem.includes('/Countries')) {
                let dtItemSplit = dtItem.split('Countries/');
                if (dtItemSplit.length > 1) {
                    scientificResponseCountriesFilters.push(dtItemSplit[1])
                }
            } else {
                scientificResponseFilters.push(dtItem);
            }
        } else if (dtItem.includes('Slide Decks/Slide Decks')) {
            dtItem = dtItem.replace('Slide Decks/Slide Decks', 'Document Type/Slide Decks')

            if (dtItem.includes('/Regions')) {
                dtItem = dtItem.replace('/Regions', '');
                slideDecksFilters.push(dtItem);
            }
            else if (dtItem.endsWith('/Countries')) {
                dtItem = dtItem.replace('/Countries', '');
                slideDecksFilters.push(dtItem);
            }
            else if (dtItem.includes('/Countries')) {
                let dtItemSplit = dtItem.split('Countries/');
                if (dtItemSplit.length > 1) {
                    slideDecksCountriesFilters.push(dtItemSplit[1])
                }
            } else {
                slideDecksFilters.push(dtItem);
            }
        } else if (dtItem.includes('Labels/Labels')) {
            if (dtItem.endsWith('Labels/Labels')) {
                finalFilters.push('Document Type/Labeling Documents - Core, EU, US/Core Labeling/Company Core Data Sheets/CCDS Current clean version (Clean, In-Use)');
                finalFilters.push('Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)');
            }
            else if (dtItem.endsWith('/CCDS') || dtItem.endsWith('/CCDS Current Clean In Use')) {
                finalFilters.push('Document Type/Labeling Documents - Core, EU, US/Core Labeling/Company Core Data Sheets/CCDS Current clean version (Clean, In-Use)');
            }
            else if (dtItem.endsWith('/USPI') || dtItem.endsWith('/USPI Current Clean In Use')) {
                finalFilters.push('Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)');
            }
        } else {
            finalFilters.push(dtItem);
        }

    })
    slideDecksFilters = uniq(slideDecksFilters)
    slideDecksCountriesFilters = uniq(slideDecksCountriesFilters)
    scientificResponseFilters = uniq(scientificResponseFilters)
    scientificResponseRegionsFilters = uniq(scientificResponseRegionsFilters)
    scientificResponseCountriesFilters = uniq(scientificResponseCountriesFilters);
    if (slideDecksFilters.length > 0) {
        slideDecksFilters.map(x => {
            let countries = "";
            if (slideDecksCountriesFilters.length > 0) {
                countries = '$$$Countries/' + slideDecksCountriesFilters.join('###Countries/');
            }
            finalFilters.push(`${x}${countries}`)
        })
    } else if (slideDecksCountriesFilters.length > 0) {
        let countries = "";
        if (slideDecksCountriesFilters.length > 0) {
            countries = '$$$Countries/' + slideDecksCountriesFilters.join('###Countries/');
        }
        finalFilters.push(`Document Type/Slide Decks${countries}`)
    }

    if (scientificResponseFilters.length > 0) {
        scientificResponseFilters.map(x => {
            let countries = "", regions = "";
            if (scientificResponseCountriesFilters.length > 0) {
                countries = '$$$Countries/' + scientificResponseCountriesFilters.join('###Countries/');
            }
            if (scientificResponseRegionsFilters.length > 0) {
                regions = '$$$Regions/' + scientificResponseRegionsFilters.join('###Regions/');
            }
            finalFilters.push(`${x}${regions}${countries}`)
        })
    } else if (scientificResponseCountriesFilters.length > 0 || scientificResponseRegionsFilters.length > 0) {
        let countries = "", regions = "";
        if (scientificResponseCountriesFilters.length > 0) {
            countries = '$$$Countries/' + scientificResponseCountriesFilters.join('###Countries/');
        }
        if (scientificResponseRegionsFilters.length > 0) {
            regions = '$$$Regions/' + scientificResponseRegionsFilters.join('###Regions/');
        }
        finalFilters.push(`Document Type/Scientific Response Documents/Scientific Responses${regions}${countries}`)
    }
    return finalFilters;

}