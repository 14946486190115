/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import {
  popCancelBttn,
  imsLoader
} from "../../assets";
import { Component } from "react";
import Button from "../Button";
import "./GlobalSearchFeaturesPopUp.css";
import "./PinToReadingListPopUp.css";
import SuccessReadingList from "./SucessReadingList";
import ApiServices from "../../utils/apiServices";
import RenameTemplateNamePopup from "./RenameTemplateNamePopup";
import { snippetLongString } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "./ConfirmMessagePopUpModal"

class PromptTemplateListPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
      isPin: true,
      isManage: false,
      isUserPromptTemplate: true,
      isSystemPromptTemplate: false,
      isCreatePromptTemplate: false,
      userApiResponse: [],
      templateApiResponse: [],
      promptTemplateName: "",
      promptTemplateText: "",
      promptTemplateId: -1,
      docID: "",
      isLoaded: false,
      showSuccess: false,
      showRenamePop: false,
      itemtoBeRenamedName: "",
      itemtoBeRenamedID: "",
      successMessage: "",
      error: "",
      nodeName: "",
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
      isInsertSuccess: false,
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageId: -1,
      selectedUserPromptTemplates: [],
      selectedSystemPromptTemplates: [],
      isEditViewButtonClicked: false,
    };
  }
  async componentDidMount() {
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    let service = new ApiServices();
    this.setState({
      showPop: this.props.showModal,
      promptTemplateText: this.props.promptTemplateText,
      isUserPromptTemplate: this.props.isUserPromptTemplate,
      isSystemPromptTemplate: this.props.isSystemPromptTemplate,
    });
    let apiRes = await service.getUserPromptLists(wwid);
    let templateapiRes = await service.getTemplatePromptLists()
    this.setState(
      {
        userApiResponse: apiRes,
        templateApiResponse: templateapiRes,
        isLoaded: true,
        isEditViewButtonClicked: false,
      }
    );
    if (this.props.isFromGlobalFeaturesMenu) {
      this.setState({
        isPin: false,
        isManage: true,
      })
    }
  }

  showConfirmMessage = (message, id) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageId: id
    })
  }

  confirmHandler = async () => {
    const { confirmMessageId } = this.state;
    let username = JSON.parse(localStorage.getItem('username'))
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    if (username) {
      username = username.toLowerCase();
    }
    let service = new ApiServices();

    await service.deleteUserPromptTemplatesLists(confirmMessageId, wwid);
    this.setState(
      {
        isLoaded: false,
        confirmMessageContent: "",
        confirmMessageShowModal: false,
        confirmMessageId: -1
      },
      async () => {
        let apiRes = await service.getUserPromptLists(wwid);
        this.setState({
          userApiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  }


  closeConfirmMessage = () => {
    this.setState({
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }

  handleInputPromptTemplateName = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      promptTemplateName: e.target.value,
    });
  };

  handleInputPromptTemplateText = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      promptTemplateText: e.target.value,
    });
  };

  onclickAddtoPromptTemplate = () => {
    let { selectedSystemPromptTemplates, selectedUserPromptTemplates, userApiResponse, templateApiResponse } = this.state;
    if (selectedSystemPromptTemplates.length === 0 && selectedUserPromptTemplates.length === 0) {
      this.errorAlertMessage("Please select either my prompt template or system prompt template to proceed.")
    } else {
      if (selectedSystemPromptTemplates.length + selectedUserPromptTemplates.length > 5) {
        this.errorAlertMessage("Kindly select maximum five prompt template to proceed.")
      } else {
        let selectPromptTemplates = "";
        selectedUserPromptTemplates.map((item) => {
          let _promptTemplateDetails = userApiResponse.find(x => x.id === item);
          if (_promptTemplateDetails) {
            selectPromptTemplates += selectPromptTemplates ? `\n\n${_promptTemplateDetails.prompt}` : _promptTemplateDetails.prompt
          }
        })
        selectedSystemPromptTemplates.map((item) => {
          let _promptTemplateDetails = templateApiResponse.find(x => x.id === item);
          if (_promptTemplateDetails) {
            selectPromptTemplates += selectPromptTemplates ? `\n\n${_promptTemplateDetails.prompt}` : _promptTemplateDetails.prompt
          }
        })
        this.props.handleAddToPromptTemplateText(selectPromptTemplates)
      }
    }
  }

  onclickProceed = async () => {
    // let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let wwid = JSON.parse(localStorage.getItem("wwid"));

    let service = new ApiServices();
    if (this.handleValidation()) {

      let { promptTemplateName, promptTemplateText, promptTemplateId } = this.state;
      if (promptTemplateId === -1) {
        if (promptTemplateName !== "" && promptTemplateText !== "") {
          await service.insertPromptTemplateList(
            wwid,
            promptTemplateName,
            promptTemplateText);

          this.setState({
            successMessage:
              "The prompt template was saved successfully.",
          }, () => {
            this.setState({
              isUserPromptTemplate: true,
              isSystemPromptTemplate: false,
              isCreatePromptTemplate: false
            })

          });
        }
      } else {

        await service.updatePromptTemplate(
          promptTemplateId,
          promptTemplateName,
          promptTemplateText);

        this.setState({
          successMessage:
            "The prompt template was updated successfully.",
        }, () => {
          this.setState({
            isUserPromptTemplate: true,
            isSystemPromptTemplate: false,
            isCreatePromptTemplate: false
          })
        });
      }


      this.setState({
        showSuccess: true,
        isInsertSuccess: true,
        promptTemplateId: -1
      });
    }
  };
  handleAddtoMyPromptTemplates = async (ptId, sptemplateName) => {
    this.setState(
      {
        isLoaded: false,
      },
      async () => {
        let promptTemplateitem = this.state.userApiResponse.find(data => data.template_name.toLowerCase() === sptemplateName.toLowerCase());
        if (!promptTemplateitem) {
          let service = new ApiServices();
          let wwid = JSON.parse(localStorage.getItem("wwid"));
          let apiResinfo = await service.insertUserPromptTemplateList(wwid, ptId);
          let apiRes = await service.getUserPromptLists(wwid);
          this.setState({
            userApiResponse: apiRes,
            isLoaded: true,
          }, () => {
            this.setState({
              showSuccess: true,
              successMessage:
                "The prompt template added to my prompt template successfully.",
            });
          });
        } else {
          this.setState({
            isLoaded: true,
          }, () => {
            this.errorAlertMessage(`You already have a selected prompt template name.`);
          })
        }
      }
    );


  };
  handleOnClickView = async (nID) => {
    let { userApiResponse } = this.state;
    let _promptTemplateDetails = userApiResponse.find(x => x.id === nID);
    if (_promptTemplateDetails) {
      this.setState({
        promptTemplateName: _promptTemplateDetails.template_name,
        promptTemplateText: _promptTemplateDetails.prompt,
        promptTemplateId: _promptTemplateDetails.id,
        isInsertSuccess: false,
        isManage: false,
        isPin: true,
        error: "",
        isEditViewButtonClicked: true,
      })
    }
  };
  handleRename = (id, name) => {
    this.setState({
      showRenamePop: true,
      itemtoBeRenamedName: name,
      itemtoBeRenamedID: id,
    });
  };
  handleRenameClose = (status) => {
    this.setState(
      {
        showRenamePop: status,
        isLoaded: false,
      },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getUserPromptLists(wwid);
        this.setState({
          userApiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  };
  handleRemove = async (id) => {
    this.showConfirmMessage("Are you sure you want to delete this prompt template?", id)
  };
  handleSuccessClose = (status) => {
    this.setState({
      showSuccess: status,
      //showPop: false,
      isManage: true,
      isPin: false,
      isLoaded: false
    },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getUserPromptLists(wwid);
        this.setState(
          {
            userApiResponse: apiRes,
            isLoaded: true
          }
        );
      });
    //this.props.handleClose(false);
  };
  handleOnFocusInput = (e) => {
    this.setState({ nodeName: e.target.nodeName });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    let promptTemplateList = this.state.userApiResponse.filter(x => x.id !== this.state.promptTemplateId).map(data => data.template_name.toLowerCase())
    if (this.state.promptTemplateName.length <= 0) {
      formIsValid = false;
      errors["pinPromptTemplateList"] = `Please enter a valid name to proceed.`;
    }
    else if (promptTemplateList.includes(this.state.promptTemplateName.toLowerCase())) {
      formIsValid = false;
      errors[
        "pinPromptTemplateList"
      ] = `You already have a prompt template with entered title. Please enter a new title.`;
    }
    else if (
      this.state.promptTemplateText.length <= 0 //&&
      //this.state.nodeName === "TEXTAREA"
    ) {
      formIsValid = false;
      errors["pinPromptTemplateList"] = `Please enter a valid prompt template to proceed.`;
    }
    else if (
      this.state.promptTemplateName.length <= 0 &&
      this.state.promptTemplateText.length <= 0
    ) {
      formIsValid = false;
      errors["pinPromptTemplateList"] = `Please enter all the fields to proceed.`
    }
    this.setState({ error: errors });
    return formIsValid;
  }
  handleSelectUserPromptTemplate = (e, cid) => {
    let { selectedUserPromptTemplates } = this.state;
    if (e.target.checked) {
      selectedUserPromptTemplates.push(cid)
    } else {
      selectedUserPromptTemplates = selectedUserPromptTemplates.filter(x => x !== cid);
    }
    this.setState({ selectedUserPromptTemplates: selectedUserPromptTemplates });

  }
  handleSelectSystemPromptTemplate = (e, cid) => {
    let { selectedSystemPromptTemplates } = this.state;
    if (e.target.checked) {
      selectedSystemPromptTemplates.push(cid)
    } else {
      selectedSystemPromptTemplates = selectedSystemPromptTemplates.filter(x => x !== cid);
    }
    this.setState({ selectedSystemPromptTemplates: selectedSystemPromptTemplates });
  }

  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, promptTemplateId, selectedUserPromptTemplates, selectedSystemPromptTemplates } = this.state;
    const { confirmMessageContent, confirmMessageShowModal, isEditViewButtonClicked } = this.state;
    let isInsertSuccess
    if (isEditViewButtonClicked) { isInsertSuccess = false }
    else
      isInsertSuccess = this.state.isInsertSuccess || this.props.isFromGlobalFeaturesMenu;
    return (
      <>
        <Modal
          show={this.state.showPop}
          size="xl"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition"
          className="z-index-authoring"
          backdropClassName="z-index-authoring-backdrop"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={(e) => {
                  this.setState({ showPop: false });
                  this.props.handleClose(false);
                }}
              />
            </div>
            <div>
              <Modal.Header
                className="gsfModalHeader"
                style={{ justifyContent: "left" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "60%",
                    paddingTop: "1%",
                  }}
                >
                  <Modal.Title>
                    <div className="actionItem-headerPart" style={{ justifyContent: "flex-start" }}>
                      {/* {!isInsertSuccess && (<>
                        <div
                          onClick={(e) => {
                            this.setState({ isPin: true, isManage: false });
                          }}
                          className={
                            this.state.isPin
                              ? "active-features-button"
                              : "features-buttons"
                          }
                        >
                          {promptTemplateId === -1 ? <span>Create Prompt Template</span> : <span>View/Edit Prompt Template</span>}
                        </div>
                        <div className="action-button-saperation-line"></div>
                      </>
                      )} */}

                      <div
                        onClick={(e) => {
                          this.setState({ isPin: false, isManage: true });
                        }}
                        className={
                          this.state.isManage
                            ? "active-features-button"
                            : "features-buttons"
                        }
                      >
                        <span>Manage Prompt Templates</span>
                      </div>
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ height: "65vh" }}>
                {this.state.isPin && (
                  <div>
                    {this.state.isLoaded ? (
                      <>

                        <div>
                          Prompt Template Name:
                        </div>
                        <input
                          type="text"
                          placeholder="Type here"
                          value={this.state.promptTemplateName}
                          className="reading-list-input-box"
                          onFocus={this.handleOnFocusInput}
                          onChange={this.handleInputPromptTemplateName}
                        />
                        <br />
                        <span className="length-info">Name with a maximum of 100 characters.</span>
                        <div>
                          Prompt:
                        </div>
                        <textarea className="reading-list-textarea-box"
                          placeholder="Replace the word <Generic name> with the word <Product Name> and use the past tense when summarizing this text"
                          value={this.state.promptTemplateText}
                          onFocus={this.handleOnFocusInput}
                          onChange={this.handleInputPromptTemplateText} />

                        <div className="errorInvalidName">
                          {this.state.error["pinPromptTemplateList"]}
                        </div>
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {this.state.isManage && (
                  <div>
                    {this.state.isLoaded ? (
                      <>

                        <span
                          onClick={(e) => {
                            this.setState({ isUserPromptTemplate: false, isSystemPromptTemplate: true, isCreatePromptTemplate: false });
                          }}
                          className={
                            this.state.isSystemPromptTemplate
                              ? "reading-list-action-text"
                              : "reading-list-deaction-text"
                          }
                          style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                          title="System Prompt Templates">
                          System Prompt Templates
                        </span>
                        <span className="action-items-separate-divider"></span>
                        <span
                          onClick={(e) => {
                            this.setState({ isUserPromptTemplate: true, isSystemPromptTemplate: false, isCreatePromptTemplate: false });
                          }}
                          className={
                            this.state.isUserPromptTemplate
                              ? "reading-list-action-text"
                              : "reading-list-deaction-text"
                          }
                          style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                          title="My Prompt Templates"
                        >
                          My Prompt Templates
                        </span>
                        <span className="action-items-separate-divider"></span>
                        <span
                          onClick={(e) => {
                            this.setState({ isUserPromptTemplate: false, isSystemPromptTemplate: false, isCreatePromptTemplate: true });
                          }}
                          className={
                            this.state.isCreatePromptTemplate
                              ? "reading-list-action-text"
                              : "reading-list-deaction-text"
                          }
                          style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                          title="Prompts"
                        >
                          Prompts
                        </span>
                        {this.state.isSystemPromptTemplate && <>
                          {this.state.templateApiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper comment-list-view-wrapper">
                                <tr>
                                  <th style={{ width: "5%" }}></th>
                                  <th style={{ width: "25%" }}>Template Name</th>
                                  <th style={{ width: "35%" }}>Prompts</th>
                                  <th style={{ textAlign: "center" }}>Action</th>
                                </tr>
                                <tbody>
                                  {this.state.templateApiResponse.map((item, index) => {
                                    return (
                                      <tr key={item.id} style={index % 2 === 0 ? { background: "#e7e0e0" } : { background: "#eaeaee" }}>
                                        <td style={{ width: "5%", textAlign: "center" }}>
                                          <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleSelectSystemPromptTemplate(e, item.id)} checked={selectedSystemPromptTemplates.includes(item.id)} />
                                        </td>
                                        <td style={{ width: "25%" }}>{item.template_name}</td>
                                        <td style={{ width: "35%", whiteSpace: "pre-wrap" }}>{item.prompt}</td>
                                        <td style={{
                                          cursor: "pointer",
                                          wordBreak: "normal",
                                          textAlign: "center"
                                        }}><span
                                          onClick={(e) =>
                                            this.handleAddtoMyPromptTemplates(item.id, item.template_name)
                                          }

                                          title="Add to My Prompt Templates"
                                          className="reading-list-action-text"
                                        >
                                            Add to My Prompt Templates
                                          </span></td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span style={{ marginLeft: "15px" }}>
                              <br />
                              <br />
                              {" "}
                              You currently do not have any prompt templates created.
                            </span>
                          )}</>}
                        {this.state.isUserPromptTemplate && <>
                          {this.state.userApiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper comment-list-view-wrapper">
                                <tr>
                                  <th style={{ width: "5%" }}></th>
                                  <th style={{ width:  "25%" }}>Template Name</th>
                                  <th style={{ width: "35%" }}>Prompts</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                                <tbody>
                                  {this.state.userApiResponse.map((item, index) => {
                                    return (
                                      <tr key={item.id} style={index % 2 === 0 ? { background: "#e7e0e0" } : { background: "#eaeaee" }} >
                                        <td style={{ width: "5%", textAlign: "center" }}>
                                          <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleSelectUserPromptTemplate(e, item.id)} checked={selectedUserPromptTemplates.includes(item.id)} />
                                        </td>
                                        <td style={{ width: "25%"  }}>{item.template_name}</td>
                                        <td style={{ width: "35%", whiteSpace: "pre-wrap" }}>{item.prompt}</td>
                                        <td
                                          style={{
                                            cursor: "pointer",
                                            wordBreak: "normal",
                                            textAlign: "center"
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(item.id)
                                            }
                                            title="View/Edit"
                                            className="reading-list-action-text"
                                          >
                                            View/Edit
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.id,
                                                item.template_name
                                              )
                                            }
                                            title="Rename"
                                            className="reading-list-action-text"
                                          >
                                            Rename
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span style={{ marginLeft: "15px" }}>
                              <br />
                              <br />
                              {" "}
                              You currently do not have any prompt templates created.
                            </span>
                          )}</>}
                        {this.state.isCreatePromptTemplate && <>
                          <div>
                            Prompt Template Name:
                          </div>
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.promptTemplateName}
                            className="reading-list-input-box"
                            onFocus={this.handleOnFocusInput}
                            onChange={this.handleInputPromptTemplateName}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div>
                            Prompt:
                          </div>
                          <textarea className="reading-list-textarea-box"
                            placeholder="Replace the word <Generic name> with the word <Product Name> and use the past tense when summarizing this text"
                            value={this.state.promptTemplateText}
                            onFocus={this.handleOnFocusInput}
                            onChange={this.handleInputPromptTemplateText} />

                          <div className="errorInvalidName">
                            {this.state.error["pinPromptTemplateList"]}
                          </div>
                        </>}
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              {(this.state.isPin || this.state.isCreatePromptTemplate) ? (
                <div className="gsf-popup-button-wrapper command-list-button-wrapper">
                  <Button
                    text="Save Prompt Template"
                    customColor="#2EB000"
                    action={this.onclickProceed}
                  />
                  <Button
                    text="Cancel"
                    customColor="#0074B0"
                    action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }}
                  />

                </div>
              ) : (
                <div className="reading-list-button-wrapper command-list-button-wrapper">
                  <Button
                    text="Apply Prompt Template"
                    customColor="#2EB000"
                    action={this.onclickAddtoPromptTemplate}
                  />
                  <Button
                    text="Cancel"
                    customColor="#0074B0"
                    action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {this.state.showSuccess && (
            <>
              <SuccessReadingList
                display={this.state.showSuccess}
                handleClose={(status) => this.handleSuccessClose(status)}
                data={this.state.successMessage}
                width={this.props.width}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
          {this.state.showRenamePop && (
            <>
              <RenameTemplateNamePopup
                showModal={this.state.showRenamePop}
                handleClose={(status) => this.handleRenameClose(status)}
                cName={this.state.itemtoBeRenamedName}
                cID={this.state.itemtoBeRenamedID}
                width={this.props.width}
                apiResponse={this.state.userApiResponse}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
        </Modal>
        {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
      </>
    );
  }
}
export default PromptTemplateListPopUp;