import Box from '@mui/material/Box';
import './PhonemeLibrary.css';
import { useState } from 'react';
const PhonemeLibrary = (props) => {
    const [val, setVal] = useState('');
    const Syllable = ['.', 'ˈ', 'ˌ'];
    const vowel = ['ju', 'aɪ', 'aʊ', 'eɪ', 'i', 'oʊ', 'u', 'æ', 'ɑ', 'ɔ', 'ɔɪ', 'ə', 'ɛ', 'ɝ', 'ɪ', 'ʊ', 'ʌ'];
    const consonant = ['b', 'd', 'dʒ', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 's', 't', 'tʃ', 'v', 'w', 'z', 'ð',
        'ŋ', 'ɹ', 'ʃ', 'ʒ', 'θ']
    const others = ['ɪɹ', 'ɛɹ', 'ʊɹ', 'aiɹ', 'aʊɹ', 'ɔɹ', 'ɑɹ', 'dz', 'on', 'oˈn', 'oˌn', 'ts', 'ɑn', 'ɑˈn',
        'ɑˌn', 'əl', 'əm', 'ən', 'əɹ', 'əˈl', 'əˈm', 'əˈn', 'əˈɹ', 'əˌl', 'əˌm', 'əˌn', 'əˌɹ', 'ɛn', 'ɛˈn', 'ɛˌn',
        'ɜ˞', 'ɜ˞ˈ', 'ɜ˞ˌ', 'ɝl', 'ɪ͡ə' , 'ʌn','ʌˈn','ʌˌn']

        let count_Others = 0;
        let count = 0;

        const displaySyll = () =>{
            return(
                Syllable.map((value,index)=>{
                        return <div className='ms-TooltipHost'><button className='phoneme-lib-button'
                        style={{margin:'0.2rem'}}
                        onClick={()=>{setVal(value)
                            if(!props.fromLibrary){
                                props.setPhoneme(val.concat(value));
                                props.setFromLibrary(true)
                            }
                            else{
                                props.setPhoneme(props.phoneme.concat(value));                            
                            }
                            }}>{value}</button></div>
                })
            )
        }
        const displayVowel = () =>{
            return(
                vowel.map((value1,i)=>{
                        return <button className='phoneme-lib-button'
                    onClick={()=>{
                        if(!props.fromLibrary){
                        props.setPhoneme(value1)
                    props.setFromLibrary(true)}
                else{
                    props.setPhoneme(props.phoneme.concat(value1))
                }}}>{value1}</button>
                })
            )
        }
        const displayConsonant = () =>{
            return (
                consonant.map((value2 , i)=>{
                        return <button className='phoneme-lib-button'
                    onClick={()=>{//setVal(value2)
                        if(!props.fromLibrary){
                            props.setPhoneme(value2);
                            props.setFromLibrary(true)
                        }
                        else{
                            props.setPhoneme(props.phoneme.concat(value2))
                        }
                        }}>{value2}</button>
                })
            )
        }
        const displayOther = () =>{
            return(
                others.map((value3, i)=>{
                        return <button className='phoneme-lib-button'
                    onClick={()=>{
                        if(!props.fromLibrary){
                            props.setPhoneme(value3);
                            props.setFromLibrary(true)         
                        }
                        props.setPhoneme(val.concat(value3))}}>{value3}</button>
                })
            )
        }
    return (<>
        <Box>
            <div className='margin-top_15'>
                <div className='display'>Syllable and stress</div>
                <div className='note-button'>
                {displaySyll()} </div>
            </div>
            <div className='display'>Vowels</div>
            <div className='note-button'>
                {displayVowel()}
            </div>
            <div className='display'>Consonant</div>
            <div class='note-button'>
                {displayConsonant()}
            </div>
            <div className='display'>Other</div>
            <div class ='note-button'>
                {displayOther()}
            </div>

        </Box></>)
}
export default PhonemeLibrary