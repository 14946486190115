import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import moment from "moment";

class GenAILibraryCollectionItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            collectionItem: props.collectionItem,
        }
    }
    render() {
        let { collectionItem } = this.state;
        let { added_on } = collectionItem;
        return (<>
            <div className="gen-ai-answering-library-collection-item">
                <div className="gen-ai-answering-library-collection-item-title">
                    {window.location.pathname.startsWith('/genAILibraryPOC') ?
                        <Link to={`/genAICollectionPOC/${collectionItem.collection_id}`} title={collectionItem.collection_name} className="gen-ai-answering-library-collection-item-title-link" >
                            {collectionItem.collection_name}
                        </Link>
                        : <Link to={`/genAICollection/${collectionItem.collection_id}`} title={collectionItem.collection_name} className="gen-ai-answering-library-collection-item-title-link" >
                            {collectionItem.collection_name}
                        </Link>}
                </div>
                <div className="gen-ai-answering-library-collection-item-time-action">
                    <div className="gen-ai-answering-library-collection-item-count" title="Threads">
                        <div className="gen-ai-answering-library-collection-item-count-svg">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="layer-group" className="svg-inline--fa fa-layer-group " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path fill="currentColor" d="M288 0c-8.5 0-17 1.7-24.8 5.1L53.9 94.8C40.6 100.5 32 113.5 32 128s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 5.1C305 1.7 296.5 0 288 0zm-5.9 49.2C284 48.4 286 48 288 48s4 .4 5.9 1.2L477.7 128 293.9 206.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 128 282.1 49.2zM53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 235.5 477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256 146 235.5 85.1 209.4 53.9 222.8zm0 128C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4 53.9 350.8z"></path>
                            </svg>
                        </div>
                        <div class="gen-ai-answering-library-collection-item-count-lable">&nbsp; {collectionItem.threads_count}</div>
                    </div>
                    <div className="gen-ai-answering-library-collection-item-time">
                        <div className="gen-ai-answering-library-thread-item-time-ago" title={moment(added_on).format("MMM DD, YYYY hh:mm a")} data-state="closed">
                            <span>
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                </svg>
                            </span>
                            <span className="truncate">&nbsp; {moment(added_on).fromNow()}</span>
                        </div>
                    </div>

                </div>
            </div>
        </>)
    }
}
export default withRouter(GenAILibraryCollectionItem);

