import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import {
    robotHeadChatgpt, imsLoader, chatbotHeaderIcon, crossIcon, iconUserChatbot, iconiMSAuthoring, GenAiDOCIcon, GenAiPDFIcon, GenAiPPTIcon, expandAllBlueIcon,
    globalExpandAllIcon, pdfIcon, rightSideArrow, BarGraph, BubbleGraph, PieGraph, LinePlot, LineScatterGraph, arrowIconBlue
} from '../../assets/index';
import { v4 as uuidv4 } from 'uuid';
import "./index.css"
import { Modal } from "react-bootstrap";
import { LocationOrigin, getCurrentDate } from '../../utils/utilityServices';
import { processFilters, GenAI2DocTypes } from '../../utils/documentTypeUtilityServices';
import GenAIAnsweringDisclaimer from './GenAIAnsweringDisclaimer'
import ApiServices from "../../utils/apiServices";
import GenAIAuthoringSidebar from './GenAIAuthoringSidebar'
import ExpandContentPopUpModal from '../PopUp/ExpandContentPopUpModal';
import CommandListPopUp from "../PopUp/CommandListPopUp";
import { clone, uniq } from 'underscore'
import moment from "moment";
import axios from 'axios';
import GenAIAuthoringDisplayString from './GenAIAuthoringDisplayString'
import { sanitize } from 'dompurify';
import AuthoringUserSummery from "../Authoring/AuthoringUserSummery"
let _locationOrigin = LocationOrigin;
_locationOrigin = 'https://predev-ims.jnj.com';
const ReactLike = 'Like';
const ReactUnlike = 'Unlike';
const ReactNone = 'None';
const summarizeResult = 'SummarizeResult';
const userQuestion = 'UserQuestion';

class GenAIAuthoringIndex extends Component {
    constructor(props) {
        super(props);
        this.messagesEndRef = createRef()
        let _showDisclaimerPopup = true;
        let strShowDisclaimerPopup = localStorage.getItem('showGlobalAuthoringDisclaimer');
        if (strShowDisclaimerPopup) {
            _showDisclaimerPopup = !JSON.parse(strShowDisclaimerPopup);
        }
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            sessionID: props.location.state ? props.location.state.sessionID : (props.match.params && props.match.params.id) ? props.match.params.id : null,
            question: props.location.state ? props.location.state.newQuestion : null,
            attachedFiles: props.location.state ? props.location.state.attachedFiles : [],
            threadID: props.location.state ? props.location.state.threadID : null,
            selectedFilters: props.location.state ? props.location.state.selectedFilters : [],
            showDisclaimerPopup: _showDisclaimerPopup,
            collapsed: false,
            commandText: props.location.state ? props.location.state.newQuestion : '',
            commandType: 'UserInstructions',
            citationText: '',
            isCitationProcessing: false,
            selectedAttachedFileguid: (props.location.state.attachedFiles && props.location.state.attachedFiles.length > 0) ? props.location.state.attachedFiles[0].guid : '',
            selectedPageNo: 1,
            expandCommand: false,
            savingCommandText: '',
            showCommandList: false,
            isUserCommand: true,
            isTemplateCommand: false,
            isFromGlobalFeaturesMenu: false,
            chatConversation: [],
            isProcessing: false,
            spinnerMessage: '',
            isGraph: false,
            selectedGraph: '',
        }
    }
    handleCollapsedClick = async (isCollapsed) => {
        if (!isCollapsed) {
            setTimeout(() => {
                this.setState({ collapsed: isCollapsed })
            }, 500);
        } else {
            this.setState({ collapsed: isCollapsed })
        }
    }
    handleDisclaimerClose = async () => {
        let strShowDisclaimerPopup = localStorage.getItem('showGlobalAuthoringDisclaimer');
        if (strShowDisclaimerPopup && !JSON.parse(strShowDisclaimerPopup)) {
            let service = new ApiServices();
            let wwid = JSON.parse(localStorage.getItem("wwid"));
            await service.UpdateDisclaimerStatus(wwid, 'isshowglobalauthoringdisclaimer', true);
        }
        this.setState({ showDisclaimerPopup: false });
        localStorage.setItem('showGlobalAuthoringDisclaimer', JSON.stringify(true));
    }
    handleChangefiles = (_selectedAttachedFileguid) => {
        this.setState({ selectedAttachedFileguid: _selectedAttachedFileguid });
    };
    handleOnClickcommandTextExpand = () => {
        this.setState({ expandCommand: true })
    }
    handleCommandTextExpand = (commandText) => {
        this.setState({ commandText: commandText, expandCommand: false })
    }
    handleAddToCommandText = (commandText) => {
        this.setState({ commandText: commandText, showCommandList: false })
    }
    handleClickOnMyCommand = () => {
        this.setState({
            isUserCommand: true,
            isTemplateCommand: false,
            showCommandList: true,
            isFromGlobalFeaturesMenu: true
        })

    }
    handleClickOnTemplateCommand = () => {
        this.setState({
            isUserCommand: false,
            isTemplateCommand: true,
            showCommandList: true,
            isFromGlobalFeaturesMenu: true
        })

    }
    handleAddToCommand = () => {
        let { commandText } = this.state;
        if (commandText) {
            this.setState({
                showCommandList: true,
                isUserCommand: true,
                isTemplateCommand: false,
                isFromGlobalFeaturesMenu: false
            })
        }
    }
    handleCloseCommandList = () => {
        this.setState({
            showCommandList: false,
            isUserCommand: true,
            isTemplateCommand: false,
            isFromGlobalFeaturesMenu: false,
            isFromGlobalFeaturesMenuForNotebook: false
        });
    }
    handleChangeCommandType = (e) => {
        this.setState({ commandType: e.target.value });
        /*if (e.target.value === 'Graph') {
            this.setState({ isGraph: true })
        }
        else {
            this.setState({ isGraph: false, selectedGraph: "" })

        }*/
    };
    handleComment = (e) => {
        this.setState({ commandText: e.target.value })
    }
    onSubmitClick = async (e) => {
        let { commandText, commandType } = this.state;
        if (commandType === 'UserInstructions' && !commandText) {
            this.errorAlertMessage("User Command Required, Please enter a command.");
        } else if (commandType === 'UserInstructions' && commandText) {
            this.runGenAISearch()
        }
    }
    handleClickOnCitationNumber = (document_guid, pageNumbers) => {
        this.setState({
            selectedAttachedFileguid: document_guid,
            selectedPageNo: pageNumbers.length > 0 ? pageNumbers[0] : 1
        })
    }
    processChatHistories = (index) => {
        let { attachedFiles, chatConversation, threadID, sessionID } = this.state;
        let _chatHistories = {};
        let _chatConversation = Object.assign([], chatConversation);
        _chatConversation = _chatConversation.filter((x, i) => i < index).filter(x => x.threadID === threadID);
        if (chatConversation.length !== 0 && _chatConversation.length === 0 && index !== 0) {
            _chatConversation = Object.assign([], chatConversation);
            _chatConversation = _chatConversation.filter(x => x.threadID === threadID);
        }
        try {
            if (attachedFiles.length > 0) {
                attachedFiles.map((fileItem, fileIndex) => {
                    _chatHistories[fileItem.guid] = [];
                    let responseFilters = _chatConversation.filter(x => x.SourcesMultiFiles.findIndex(y => y.document_guid === fileItem.guid) > -1);
                    responseFilters.map((resItem, resIndex) => {
                        let indexOfSource = resItem.SourcesMultiFiles.findIndex(y => y.document_guid === fileItem.guid)
                        let restDisplayStringMultifilter = resItem.DisplayStringMultiFiles.filter(d => d.source_no.includes(indexOfSource + 1));
                        let finalAns = restDisplayStringMultifilter.map(x => x.display_ans).join(' ')
                        _chatHistories[fileItem.guid].push({ "Question": resItem.Question, "Answer": finalAns })
                    })
                })
            } else {
                _chatHistories[sessionID] = [];
                _chatConversation = _chatConversation.filter(x => x.isMultiFiles === false);
                _chatConversation.map((resItem, resIndex) => {
                    let finalAns = resItem.DisplayString.map(x => x.display_ans).join(' ');
                    _chatHistories[sessionID].push({ "Question": resItem.Question, "Answer": finalAns })
                })
            }
        } catch (error) {
            console.log(error)
        }
        return _chatHistories;
    }
    processSourceSearchIds = (index) => {
        let { chatConversation } = this.state;
        let _sourceSearchIds = [];
        try {
            let _chatConversation = Object.assign([], chatConversation);
            if (index - 1 >= 0 && index - 1 < _chatConversation.length) {
                _sourceSearchIds = _chatConversation[index - 1].Sources.map(x => x.searchID);
            }
            _sourceSearchIds = uniq(_sourceSearchIds);
        } catch (error) {
            console.log(error)
        }
        return _sourceSearchIds;
    }
    generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }
    runGenAISearch = () => {
        let { commandText, sessionID, chatHistory, isFirstQuestion, attachedFiles, threadID, selectedFilters, renderfilters, isMultiFileThread } = this.state;
        let _chatConversation = this.state.chatConversation;
        let _lastCreatedDatehour = getCurrentDate();
        let url = '';
        let _chatHistory = [];
        let query = commandText.trim().replace(/\n\n/g, '\n').split(/\n/g).filter(x => x);
        let _threadAttachedFiles = clone(attachedFiles.map(x => { return { name: x.name } }));
        url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_answers`
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_answers`
        }
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
        this.setState({ isProcessing: true, spinnerMessage: "Please wait." })
        _chatHistory = this.processChatHistories(_chatConversation.length);
        let _sourceSearchId = this.processSourceSearchIds(_chatConversation.length);
        var config;
        let filterStr = "";
        let selectedFilter = [];
        var eventid = uuidv4();
        let _attachedFiles = attachedFiles.map(x => { return { name: x.name } })
        let _function = localStorage.getItem('function').replace(/"/g, '');
        if (userAccessGroup.includes("AllContent") && userAccessGroup.includes("MISEHCP")) {
            _function = `MISE;${localStorage.getItem('function').replace(/"/g, '')}`;
        }
        else if (userAccessGroup.includes("MISEHCP")) {
            _function = `MISE`;
        }
        else if (userAccessGroup.includes("AllContent")) {
            _function = localStorage.getItem('function').replace(/"/g, '');
        }
        Object.keys(GenAI2DocTypes).map(key => {
            selectedFilter.push(GenAI2DocTypes[key]);
        })
        filterStr = this.generateArrayString(selectedFilter);
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": query,
                "filters": filterStr,
                "function": _function,
                "doctypepref": localStorage.getItem('doctypepref').replace(/"/g, ''),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "source": "docTitle,documentNumber,downloadUrl,docType,searchID,year,fileName",
                "from": 0,
                "size": 7,
                "sortBy": "",
                "sortField": "",
                "defaultOperator": "or",
                "sessionID": sessionID,
                "threadID": threadID,
                "chat_history": _chatHistory,
                "sourceSearchId": _sourceSearchId,
                "isFirstQuestion": _chatConversation.length === 0,
                "IsComprehensive": false,
                "isMoreInformation": false,
            }
        }
        axios(config)
            .then(async (resp) => {

                query.map((questionItem, questionIndex) => {
                    let response = resp.data[questionIndex];
                    let isImageUpload = _attachedFiles.some(x =>
                        x.name.toLowerCase().endsWith('.gif')
                        || x.name.toLowerCase().endsWith('.jpeg')
                        || x.name.toLowerCase().endsWith('.jpg')
                        || x.name.toLowerCase().endsWith('.png'))

                    if (response.display_string_multi && response.display_string_multi.length > 0 || isImageUpload) {
                        _chatConversation.push({ from: userQuestion, _commandType: 'UserInstructions', IsSucceed: true, message: questionItem, messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, threadID: threadID, lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true })
                        _chatConversation.push({ from: summarizeResult, _commandType: 'UserInstructions', IsSucceed: true, OutputAnswer: response.output_answer, Sources: [], SourcesMultiFiles: response.sources_multi, DisplayString: [], RelevantInformation: {}, ShowRelevantInformation: false, DisplayStringMultiFiles: response.display_string_multi, RecommandationResults: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: response.combined_summary, threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true })
                        //this.indextrackingData(clone(eventid), (isFirstQuestion && questionIndex === 0) ? "MainQuestion" : "FollowUpQuestion", questionItem, response.rephrased_question, response.output_answer, [], [], [], _attachedFiles, response.sources_multi, response.display_string_multi, response.combined_summary, _lastCreatedDatehour, {}, query.length > 1, true, true);
                    } else {
                        _chatConversation.push({ from: userQuestion, _commandType: 'UserInstructions', IsSucceed: false, message: questionItem, messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, threadID: threadID, lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true })
                        _chatConversation.push({ from: summarizeResult, _commandType: 'UserInstructions', IsSucceed: false, Question: questionItem, OutputAnswer: "No Answer found. Please try a new question.", Sources: [], SourcesMultiFiles: [], DisplayString: [], RelevantInformation: {}, ShowRelevantInformation: false, DisplayStringMultiFiles: [], RecommandationResults: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: false, isSuccess: true })
                        //this.indextrackingData(clone(eventid), (isFirstQuestion && questionIndex === 0) ? "MainQuestion" : "FollowUpQuestion", questionItem, questionItem, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], '', _lastCreatedDatehour, {}, query.length > 1, false, true);
                    }
                    eventid = uuidv4();
                })
            })
            .catch(async (error) => {
                query.map((questionItem, questionIndex) => {
                    _chatConversation.push({ from: userQuestion, _commandType: 'UserInstructions', IsSucceed: false, message: questionItem, messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, threadID: threadID, lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: true, isSuccess: true })
                    _chatConversation.push({ from: summarizeResult, _commandType: 'UserInstructions', IsSucceed: false, Question: questionItem, OutputAnswer: "No Answer found. Please try a new question.", SourcesMultiFiles: [], DisplayString: [], RelevantInformation: {}, ShowRelevantInformation: false, DisplayStringMultiFiles: [], RecommandationResults: [], SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: clone(eventid), isMultiFiles: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, threadAttachedFiles: _threadAttachedFiles, isGetAnyAns: false, isSuccess: false })
                    //this.indextrackingData(clone(eventid), (isFirstQuestion && questionIndex === 0) ? "MainQuestion" : "FollowUpQuestion", questionItem, questionItem, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], '', _lastCreatedDatehour, {}, query.length > 1, false, false);
                    eventid = uuidv4();
                })
                console.log("error", error);
            })
            .finally(async () => {
                this.setState({
                    chatConversation: _chatConversation,
                    isProcessing: false,
                    commandText: '',
                    chatHistory: chatHistory,
                    isFirstQuestion: false,
                    renderfilters: renderfilters,
                    selectedFilters: selectedFilters,
                    spinnerMessage: ""
                }, async () => {
                    this.scrollToBottom();
                })
            });
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    renderFileIcon = (name) => {
        if (name.toLowerCase().endsWith('.pdf')) {
            return (<><img src={GenAiPDFIcon} style={{ marginBottom: "5px" }} /></>)
        }
        else if (name.toLowerCase().endsWith('.doc') || name.toLowerCase().endsWith('.docx')) {
            return (<><img src={GenAiDOCIcon} style={{ marginBottom: "5px" }} /></>)
        }
        else if (name.toLowerCase().endsWith('.ppt') || name.toLowerCase().endsWith('.pptx')) {
            return (<><img src={GenAiPPTIcon} style={{ marginBottom: "2px" }} /></>)
        } else if (name.toLowerCase().endsWith('.jpg')
            || name.toLowerCase().endsWith('.jpeg')
            || name.toLowerCase().endsWith('.png')
            || name.toLowerCase().endsWith('.gif')) {
            return (<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5" stroke-width="0" fill="#059cf7" opacity="0.1"></polygon>
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5"></polygon>
                    <rect x="2" y="2" width="20" height="20" rx="0"></rect>
                    <line x1="6.99" y1="7" x2="7" y2="7" stroke-linecap="round" stroke-width="2"></line>
                </g>
            </svg> &nbsp;</>)
        }
        else {
            return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>)
        }
    }
    renderDisplayStringMultiFiles = (conversation) => {
        let { SourcesMultiFiles, Question, questionType, DisplayStringMultiFiles, OutputAnswer, isMultiFiles, eventid, threadAttachedFiles } = conversation;
        let { sessionID, threadID, attachedFiles, selectedAttachedFileguid } = this.state;
        if (DisplayStringMultiFiles.length > 0) {
            return (<div className="gen-ai-answering-result-main-answer-wrapper">{
                SourcesMultiFiles.map((smitem, smindex) => {
                    let filterDisplayStringMultiFiles = DisplayStringMultiFiles.filter(x => x.source_no.includes(smindex + 1));
                    return (<>
                        <div className="gen-ai-answering-result-main-answer-display-string-multi-files-document-title">
                            <b>
                                {this.renderFileIcon(smitem.document_title)}
                                {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" className="svg-inline--fa fa-file " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>*/}File: {smitem.document_title}</b>
                        </div>
                        {
                            filterDisplayStringMultiFiles.map((dsitem, dsindex) => {
                                dsitem.display_ans = dsitem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                                return (<GenAIAuthoringDisplayString key={`gen-ai-answering-result-main-answer${dsindex}`}
                                    displayAns={dsitem.display_ans} sourceNo={dsitem.source_no}
                                    contextIds={dsitem.context_id}
                                    pageNumber={dsitem.page_number} sources={SourcesMultiFiles}
                                    isMultiFiles={isMultiFiles} attachedFiles={attachedFiles}
                                    eventid={eventid}
                                    DisplayString={DisplayStringMultiFiles}
                                    handleClickOnCitationNumber={this.handleClickOnCitationNumber}
                                    sessionID={sessionID} Question={Question} questionType={questionType} threadID={threadID} />)
                            })
                        }
                        {(SourcesMultiFiles.length > smindex + 1) && <hr style={{ margin: "10px 00px" }}></hr>}
                    </>)
                })
            }</div>)
        } else {
            let isImageUpload = threadAttachedFiles.some(x =>
                x.name.toLowerCase().endsWith('.gif')
                || x.name.toLowerCase().endsWith('.jpeg')
                || x.name.toLowerCase().endsWith('.jpg')
                || x.name.toLowerCase().endsWith('.png'))
            if (isImageUpload) {
                return (<div className="gen-ai-answering-result-main-answer-wrapper">
                    <span dangerouslySetInnerHTML={{ __html: sanitize(`${OutputAnswer}`) }} />
                </div>)
            } else {
                if (OutputAnswer === "We don't know the answer. Can you try new questions.") {
                    OutputAnswer = `No Answer found. Please try a new question.`;
                }
                return (<div className="gen-ai-answering-result-main-answer-wrapper">
                    {OutputAnswer}
                </div>)
            }
        }
    }
    renderChatConversation = () => {
        let { chatConversation, isProcessing, isShowMore, previewURL } = this.state;
        return (
            <>
                <div className="chat-body">
                    {chatConversation.map((conversation, conversationIndex) => {

                        if (conversation._commandType === "UserInstructions") {
                            if (conversation.from === userQuestion) {
                                return (
                                    <AuthoringUserSummery key={`my-message-${conversationIndex}`} conversation={conversation} />
                                )
                            } else {
                                return (
                                    <div className="message info" key={`summery-message-${conversationIndex}`}>
                                        {/* <img alt='avatar' className="img-circle medium-image" src="https://bootdey.com/img/Content/avatar/avatar1.png" /> */}
                                        <img alt='avatar' className="img-circle medium-image" src={robotHeadChatgpt} />
                                        <div className="message-body">
                                            <div className="message-text"   >
                                                {this.renderDisplayStringMultiFiles(conversation)}
                                            </div>
                                            <div className="message-info message-info-add-to-notes message-info-gpt-add-to-notes">
                                                <h5>{conversation.messageTimeInText} </h5>
                                                {/* {(conversation.IsSucceed) && <span title="Add to Notebook" className="add-to-notebook-action-text add-to-notebook-action-text-gpt" onClick={() => { previewURL ? this.handleAddToNotebookPreview(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) : this.handleAddToNotebook(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) }}>Add to Notebook</span>} */}
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )
                            }

                        } else {
                            if (conversation.from === userQuestion) {
                                return (
                                    <AuthoringUserSummery key={`my-message-${conversationIndex}`} conversation={conversation} />
                                )

                            } else {
                                return (
                                    <div className="message info" key={`summery-message-${conversationIndex}`}>
                                        {/* <img alt='avatar' className="img-circle medium-image" src="https://bootdey.com/img/Content/avatar/avatar1.png" /> */}
                                        <img alt='avatar' className="img-circle medium-image" src={robotHeadChatgpt} />
                                        <div className="message-body">
                                            <div className="message-text" dangerouslySetInnerHTML={{ __html: sanitize(conversation.message) }} />
                                            <div className="message-info message-info-add-to-notes message-info-gpt-add-to-notes">
                                                <h5>{conversation.messageTimeInText} </h5>
                                                {/* {(conversation.IsSucceed && conversation.commandType !== 'CreateTable') && <span title="Add to Notes" className="add-to-notes-action-text" onClick={() => this.handleAddToNotes(conversation.message)}>Add to Notes</span>} */}
                                                {(conversation.IsSucceed) && <span title="Add to Notebook" className="add-to-notebook-action-text add-to-notebook-action-text-gpt" onClick={() => { previewURL ? this.handleAddToNotebookPreview(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) : this.handleAddToNotebook(conversation.message, conversation.commandType, conversation.orignalText, conversation.commandText) }}>Add to Notebook</span>}
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )
                            }
                        }
                    })
                    }
                    <div style={{ width: "100%" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                </div>

            </>
        )
    }
    render() {
        const { showDisclaimerPopup, collapsed, commandText, commandType, citationText, isCitationProcessing, attachedFiles, expandCommand } = this.state;
        const { selectedAttachedFileguid, selectedPageNo, isTemplateCommand, isUserCommand, isFromGlobalFeaturesMenu, showCommandList, spinnerMessage } = this.state;
        const { isGraph, selectedGraph } = this.state;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-authoring-container">
                <div className="gen-ai-answering-authoring-top">
                    <div className="gen-ai-answering-authoring-img-wrapper">
                        <img
                            src={chatbotHeaderIcon}
                            alt="iMS profile image"
                            className="gen-ai-answering-authoring-img"
                            style={{ backgroundColor: '#ffffff' }}
                        />
                    </div>
                    <a className="authoring-model-body-content-link" onClick={() => { this.setState({ showDisclaimerPopup: true }) }} >Gen Ai Adherence Policy</a>
                </div>
                <div className="gen-ai-answering-authoring-bottom">
                    <GenAIAuthoringSidebar selectedAttachedFileguid={selectedAttachedFileguid} selectedPageNo={selectedPageNo} attachedFiles={attachedFiles} handleChangefiles={this.handleChangefiles} customWidth={this.state.customWidth} customHeight={this.state.customHeight} handleCollapsedClick={this.handleCollapsedClick} />
                    <div className={collapsed ? "gen-ai-answering-authoring-right gen-ai-answering-authoring-right-collapsed" : "gen-ai-answering-authoring-right gen-ai-answering-authoring-right-expand"}>
                        <div className="answering-authoring-summery-result-wrapper-gpt">
                            <div className="answering-authoring-result-label-gpt">
                                <span>Results</span>
                            </div>
                            <div className="answering-authoring-result-panel-gpt">
                                {this.renderChatConversation()}
                            </div>
                        </div>
                        <div className="answering-authoring-source-result-wrapper-gpt">
                            <div className="answering-authoring-source-label-gpt">
                                <span>+Source Text</span>
                            </div>
                        </div>
                        <div className="answering-authoring-summery-comment-send-save-notebook-wrapper-gpt">
                            <div className="answering-authoring-command-label-gpt">
                                <span>Command</span>
                                <button title="Command Expand" class="btn-like-expandAll" onClick={e => {
                                    this.handleOnClickcommandTextExpand()
                                }}><img src={expandAllBlueIcon} alt="Expand" />
                                </button>
                            </div>
                            <div className="answering-authoring-command-wrapper-panel-gpt">
                                <div className="answering-authoring-command-dropdown-panel-gpt">
                                    <select
                                        className="answering-authoring-command-dropdown-gpt"
                                        onChange={this.handleChangeCommandType}
                                        value={commandType}>
                                        <option value="UserInstructions">User Instructions​</option>
                                        <option value="CreateTable">Create Table​</option>
                                        <option value="Summarize">Summarize</option>
                                        <option value="Translate">Translate</option>
                                        { /*<option value="Graph">Graph</option>*/}
                                        {/* <option value="QnA">QnA</option> */}
                                    </select>
                                    {/*{(selectedGraph) && <div className='select-graph-wrapper'>
                                        <span>{selectedGraph}</span>
                                       <span onClick={()=>this.setState({isGraph: !(isGraph)})}><svg width="16px" height="14px" style={{ marginTop: "2px" }} 
                                        viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" 
                                        xmlns="http://www.w3.org/2000/svg" fill="black">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0">
                                                </g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" 
                                                stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                                                    <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="black"></path></g>
                                                    </svg></span>
                                        </div>}*/}
                                </div>
                                {/*{(isGraph) && <div>
                                        <ul className="graph-sub-wrapper-menu">
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Scatter Plot'})
                                        }}><img className ="menu-image"src={LineScatterGraph}/>Scatter Plot</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Line Charts'})
                                            }}><img className ="menu-image" src ={LinePlot}/>Line Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Bar Chart'})
                                            }}><img className ="menu-image"src={BarGraph}/>Bar Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Pie Chart'})
                                            }}><img className ="menu-image" src ={PieGraph}/>Pie Charts</li>
                                            <li onClick={()=>{
                                                this.setState({isGraph : false})
                                                this.setState({selectedGraph:'Bubble Chart'})
                                            }}><img className ="menu-image" src ={BubbleGraph}/>Bubble Charts</li>
                                            </ul></div>}*/}

                                <div className="answering-authoring-summery-comment-send-gpt">
                                    <div className="answering-authoring-comment-panel-gpt">
                                        <textarea name="commandText" value={commandText} placeholder="Type Additional Commands here." onChange={(e) => this.handleComment(e)} />
                                        <span title="Save Command" className="add-to-command-action-text add-to-command-action-save" onClick={() => this.handleAddToCommand()}>Save Command</span>
                                    </div>
                                    <div className="answering-authoring-send-panel-gpt">
                                        <button className="btnNotebook" type="button" onClick={(e) => this.onSubmitClick()}  >Submit</button>
                                        <span title="My Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnMyCommand()}>My Commands</span>
                                        <span title="Template Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnTemplateCommand()}>Template Commands</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="answering-authoring-summery-citation-wrapper-gpt">
                            <div className="answering-authoring-gpt-citation-label">
                                <span>Citation</span>
                            </div>
                            <div className="answering-authoring-gpt-summery-citation-send">
                                {isCitationProcessing ? <>
                                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                        <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                    </div>
                                </> : <>
                                    <textarea name="citationText" value={citationText} placeholder="Enter your citation here." onChange={(e) => this.handleCitation(e)} />
                                    <div className="add-to-citation-action-text add-to-command-gpt-action-save add-to-command-action-gpt">
                                        <span className="answering-authoring-summery-gpt-citation-link" title="Convert Citation" onClick={() => this.handleAddToConvertCitation()}>Convert Citation</span>
                                        <span className="answering-authoring-summery-citation-seprater"></span>
                                        <span className="answering-authoring-summery-gpt-citation-link" title="Clear Citation" onClick={this.handleOnClickClearCitationExpand}>Clear Citation</span>
                                    </div>
                                </>}

                            </div>
                        </div>
                    </div>
                </div>
                {showDisclaimerPopup && <GenAIAnsweringDisclaimer showPopup={showDisclaimerPopup} handleDisclaimerClose={this.handleDisclaimerClose} />}
                {expandCommand && <ExpandContentPopUpModal placeholder='Enter your command here.' content={commandText} showModal={expandCommand} handleSaveContent={this.handleCommandTextExpand} />}
                {showCommandList && <CommandListPopUp showModal={showCommandList} handleClose={this.handleCloseCommandList}
                    width={this.props.customWidth} commandText={commandText} handleAddToCommandText={this.handleAddToCommandText}
                    isUserCommand={isUserCommand} isTemplateCommand={isTemplateCommand} isFromGlobalFeaturesMenu={isFromGlobalFeaturesMenu}
                />}
                {this.state.isProcessing &&
                    <Modal
                        show={this.state.isProcessing}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="gen-ai-answering-search-spinner-popup"
                        backdropClassName="z-index-gen-ai-answering-search-spinner-popup"
                        scrollable={true}>
                        <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100} />
                                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>{spinnerMessage ? spinnerMessage : `Please wait while finding answers.`} </div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>}
            </div>)
        }
    }

}
export default withRouter(GenAIAuthoringIndex);