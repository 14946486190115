import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import threadingService from "../../services/threadingService";
import { UserData } from "../../auth/Authorization";
import Pagination from "../Paginate/Pagination";
import { imsLoader } from "../../assets";
import GenAILibraryThreadItem from "./GenAILibraryThreadItem";
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal"
import { ContextMenu, MenuItem, ContextMenuTrigger, showMenu, hideMenu } from "react-contextmenu";
import ApiServices from "../../utils/apiServices";
import "./index.css"
import GenAIAskQuestion from "./GenAIAskQuestion"
import RenameCollectionPopup from "../PopUp/RenameCollectionPopUp";
import GenAIClose from "./GenAIClose";

class GenAICollectionsList extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        super(props);
        this.myDiv = createRef();
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            collectionID: props.match.params.id,
            itemsPerPage: "10",
            currentPage: "1",
            documentCount: 0,
            response: [],
            collectionThreadResponse: [],
            isProcessing: false,
            openedContextMenuId: this.props.openedContextMenuId,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,
            scrollingUp: false,
            collectionDetails: null,
            showRenamePop: false,
            itemtoBeRenamedName: '',
            itemtoBeRenamedID: '',
            apiResponse: [],

        }
    }


    componentDidMount = async () => {

        if (this.props.match.params && this.props.match.params.id) {
            await this.context.getLatestAnswerThread();
            await this.getCollectionByCollectionId();
            await this.getCollectionById();
            await this.getThreadByCollectionId();
        } else {
            if (window.location.pathname.startsWith('/genAICollectionPOC')) {
                this.props.history.push('/genAIAnsweringPOC');
            }
            else {
                this.props.history.push('/genAIAnswering');
            }
        }
        window.addEventListener('scroll', this.handleScroll, true);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }
    handleScroll = () => {
        let currScroll = 0;
        if (this.myDiv && this.myDiv.scrollTop) {
            currScroll = this.myDiv.scrollTop;
        }
        this.setState({ scrollingUp: currScroll > 30 })

    }
    handleOnOpenedContextMenuClick = (_id) => {
        this.setState({ openedContextMenuId: _id })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openedContextMenuId !== this.state.openedContextMenuId) {
            this.setState({ openedContextMenuId: nextProps.openedContextMenuId })
        }
    }
    async getCollectionById() {
        this.setState({ isProcessing: true })
        let wwid = JSON.parse(localStorage.getItem('wwid'));
        let service = new ApiServices();
        let apiRes = await service.getCollectionById(wwid);
        apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
        this.setState({
            apiResponse: apiRes,
            isProcessing: false
        })
    }
    async getCollectionByCollectionId() {
        this.setState({ isProcessing: true })
        let { collectionID } = this.state;
        let service = new ApiServices();
        let response = await service.getCollectionByCollectionId(collectionID)
        if (response && response.length > 0) {
            this.setState({ collectionDetails: response[0] });
        } else {
            this.setState({ collectionDetails: null });
        }
    }
    async getThreadByCollectionId() {
        this.setState({ isProcessing: true })
        let { collectionID } = this.state;
        let service = new ApiServices();
        let response = await service.getThreadByCollectionId(collectionID)
        if (response) {
            this.setState(
                {
                    collectionThreadResponse: response,
                }, async () => {
                    if (response && response.length > 0) {
                        await this.getAnswerThreadDataBySessionIds()
                    } else {
                        this.setState({
                            documentCount: 0,
                            response: [],
                            isProcessing: false,
                        });
                    }
                })
        } else {
            this.setState(
                {
                    collectionThreadResponse: [],
                    isProcessing: false,
                })
        }

    }
    getAnswerThreadDataBySessionIds = async () => {
        this.setState({ isProcessing: true })
        const ts = new threadingService();
        if (UserData && UserData.sub) {
            let { collectionThreadResponse } = this.state;
            let from = parseInt(this.state.currentPage) * parseInt(this.state.itemsPerPage) - parseInt(this.state.itemsPerPage);
            let size = parseInt(this.state.itemsPerPage)
            let session_ids = collectionThreadResponse.map(x => x.session_id);
            let response = await ts.getAnswerThreadDataBySessionIds(UserData.sub, session_ids, from, size)
            if (response && response.data) {
                this.setState(
                    {
                        documentCount: response.data.hits.total.value,
                        response: response.data.hits.hits,
                        isProcessing: false,
                    })
            } else {
                this.setState(
                    {
                        documentCount: 0,
                        response: [],
                        isProcessing: false,
                    })
            }
        } else {
            this.setState(
                {
                    documentCount: 0,
                    response: [],
                    isProcessing: false,
                })
        }
    }

    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }

    confirmHandler = async () => {
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                this.setState({ isProcessing: true });
                let { collectionID } = this.state;
                let wwid = JSON.parse(localStorage.getItem('wwid'));
                let service = new ApiServices();
                await service.deleteCollection(wwid, collectionID);
                if (window.location.pathname.startsWith('/genAICollectionPOC')) {
                    this.props.history.push({
                        pathname: '/genAIAnsweringPOC',
                    })
                }
                else {
                    this.props.history.push({
                        pathname: '/genAIAnswering',
                    })
                }
                this.setState({ confirmMessageId: -1, isProcessing: false });
            }
        );
    }

    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    handleDeleteCollectionClick = (e) => {
        this.showConfirmMessage("Are you sure you want to delete this collection?", "")
    }
    handleEditCollectionClick = (e) => {
        let { collectionDetails } = this.state;
        if (collectionDetails) {
            this.setState({
                showRenamePop: true,
                isProcessing: true,
                itemtoBeRenamedName: collectionDetails.collection_name,
                itemtoBeRenamedID: collectionDetails.collection_id,
            })
        }
    }
    handleLeftMouseClick = (e, _id) => {
        const x = e.clientX - 5;
        const y = e.clientY;
        showMenu({
            position: { x, y },
            id: _id
        });
    }
    handleRename = (id, name, property) => {
        this.setState({
            showRenamePop: true,
            itemtoBeRenamedName: name,
            itemtoBeRenamedID: id,
        })
    }
    handleRenameClose = (status) => {
        this.setState({
            showRenamePop: status,
        }, async () => {
            await this.getCollectionByCollectionId();
            await this.getCollectionById();
        }, async () => {
            this.setState({ isProcessing: false, })
        })
    }
    renderModeThreads() {
        let { response } = this.state;
        return (<>
            {
                response.map((collectionItem, collectionIndex) => {
                    return (<GenAILibraryThreadItem
                        // handleOnOpenedContextMenuClick={}
                        // deleteAnswerThreadDataBySessionId={}
                        // openedContextMenuId={this.state.openedContextMenuId}
                        // updateThreadAndCollection={this.updateThreadAndCollection}
                        source={collectionItem._source}
                        threadCollections={[]}
                        key={`thread-History${collectionIndex}`} />)
                })
            }
        </>)
    }
    render() {
        let { isProcessing, response, scrollingUp, collectionDetails } = this.state;
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-collection-thread-main" ref={(el) => { this.myDiv = el; }}>
                <header className={`${scrollingUp ? this.context.isCollapsedSpin ? "stickyHeaderCollapse" : "stickyHeaderExpand" : ""}`}>
                    <div className="gen-ai-answering-collection-threads-header">
                        <div className="gen-ai-answering-collection-threads-header-empty">&nbsp;&nbsp;</div>
                        <div className="gen-ai-answering-collection-threads-header-div">
                            <h1 className="gen-ai-answering-collection-threads-header-h1">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="grid-round-2" className="svg-inline--fa fa-grid-round-2 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M128 176a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 128a96 96 0 1 1 192 0zM128 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 384a96 96 0 1 1 192 0zM336 128a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192zm0 208a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48a96 96 0 1 1 -192 0 96 96 0 1 1 192 0z">
                                    </path>
                                </svg>
                            </h1>
                            <h1 className="gen-ai-answering-collection-threads-header-h1">&nbsp;Collection</h1>
                        </div>
                        <div className="gen-ai-answering-collection-threads-header-controle">
                            <span>
                                <ContextMenuTrigger id="gen-ai-answering-library-threads-deleteall" >
                                    <button type="button" onClick={e => this.handleLeftMouseClick(e, "gen-ai-answering-collection-threads")} className="gen-ai-answering-library-threads-button-deleteall bg-offsetPlus dark:bg-offsetPlusDark text-textMain dark:text-textMainDark  md:hover:text-textOff md:dark:hover:text-textOffDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans  select-none items-center relative group/button  justify-center text-center items-center rounded cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm aspect-square h-8">
                                        <div className="flex items-center leading-none justify-center gap-xs">
                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis" className="svg-inline--fa fa-ellipsis fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg>
                                        </div>
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                </ContextMenuTrigger>

                            </span>
                            <GenAIClose />
                        </div>
                    </div>
                </header>
                <div className="gen-ai-answering-collection-threads-title-wrapper">
                    {collectionDetails && <div className="gen-ai-answering-collection-threads-collection-detail">
                        <h1 className="gen-ai-answering-collection-threads-collection-detail-h1">{collectionDetails.collection_name}</h1>
                    </div>}
                    <div className="gen-ai-answering-collection-threads-askquestion-section">
                        <GenAIAskQuestion isOpenInModelPopup={false} customWidth={this.state.customWidth} customHeight={this.state.customHeight} collectionDetails={collectionDetails} key='GenAICollectionsList' customeKey='GenAICollectionsList' />
                    </div>
                    <div className="gen-ai-answering-collection-threads-title">
                        <div className="k">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="layer-group" className="svg-inline--fa fa-layer-group fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 0c-8.5 0-17 1.7-24.8 5.1L53.9 94.8C40.6 100.5 32 113.5 32 128s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 5.1C305 1.7 296.5 0 288 0zm-5.9 49.2C284 48.4 286 48 288 48s4 .4 5.9 1.2L477.7 128 293.9 206.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 128 282.1 49.2zM53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 235.5 477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256 146 235.5 85.1 209.4 53.9 222.8zm0 128C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4 53.9 350.8z"></path></svg>
                        </div>
                        <div className="">&nbsp;Threads</div>
                    </div>
                    {isProcessing ?
                        <div style={{ textAlign: "center", marginBottom: "10px" }}>
                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%", marginTop: "200px" }} />
                        </div> :
                        response.length > 0 ? <>
                            <div className="gen-ai-answering-library-collections-lists">
                                <div>
                                    {this.renderModeThreads()}
                                </div>
                            </div>

                            <Pagination
                                metadata={this.state.documentCount}
                                handlePageChange={this.handlePageChange}
                                itemsPerPage={parseInt(this.state.itemsPerPage)}
                                page={parseInt(this.state.currentPage)}
                                customWidth={this.state.width}
                                customHeight={this.state.height}
                            />
                            <div style={{ float: "left", clear: "both", height: "50px" }}>
                                &nbsp;
                            </div>
                        </> :
                            <div className="gen-ai-answering-library-threads-list">
                                <div className="gen-ai-answering-library-threads-list-no-threads">No threads yet</div>
                            </div>
                    }
                </div>
                {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
                {this.state.showRenamePop &&
                    <>
                        <RenameCollectionPopup showModal={this.state.showRenamePop} handleClose={(status) => this.handleRenameClose(status)} collectionName={this.state.itemtoBeRenamedName} collectionId={this.state.itemtoBeRenamedID} apiResponse={this.state.apiResponse} customWidth={this.props.customWidth} />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                <ContextMenu id="gen-ai-answering-collection-threads" className="gen-ai-answering-library-thread-item-contextMenu">
                    <MenuItem onClick={this.handleEditCollectionClick}>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen-clip" className="svg-inline--fa fa-pen-clip fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M320 62.1l42.7-42.7c25-25 65.5-25 90.5 0l39.4 39.4c25 25 25 65.5 0 90.5L229.5 412.5c-48 48-109.2 80.8-175.8 94.1l-25 5c-7.9 1.6-16-.9-21.7-6.6s-8.1-13.8-6.6-21.7l5-25c13.3-66.6 46.1-127.8 94.1-175.8L286.1 96 273 82.9c-9.4-9.4-24.6-9.4-33.9 0L137 185c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L205.1 49c28.1-28.1 73.7-28.1 101.8 0L320 62.1zm0 67.9L133.5 316.5c-38.8 38.8-66 87.5-78.6 140.7c53.2-12.7 101.9-39.9 140.7-78.6L382.1 192 320 129.9z"></path></svg>
                        <span>Edit Collection</span>
                    </MenuItem>
                    <MenuItem onClick={this.handleDeleteCollectionClick}>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" class="svg-inline--fa fa-trash fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path></svg>
                        <span>Delete Collection</span>
                    </MenuItem>
                </ContextMenu>
            </div>
            )
        }
    }

    handleNextClick = () => {
        try {
            if (
                this.state.currentPage <
                Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            ) {
                // if (this.state.currentPage <= 1) {
                this.setState(
                    {
                        currentPage: parseInt(this.state.currentPage) + 1,
                    },
                    () => {
                        this.handlePageChange(this.state.currentPage);
                    }
                );
            }
        } catch (error) {
            this.setState({ error: error });
        }
    };
    handlePreviousClick = () => {
        try {
            if (this.state.currentPage >= 2) {
                this.setState(
                    {
                        currentPage: this.state.currentPage - 1,
                    },
                    () => {
                        this.handlePageChange(this.state.currentPage);
                    }
                );
            }
        } catch (error) {
            this.setState({ error: error });
        }
    };
    navigateToLastPage = () => {
        try {
            this.setState(
                {
                    currentPage: Math.ceil(
                        this.state.documentCount / this.state.itemsPerPage
                    ),
                },
                () => {
                    this.handlePageChange(this.state.currentPage);
                }
            );
        } catch (error) {
            this.setState({ error: error });
        }
    };
    navigateToFirstPage = () => {
        try {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.handlePageChange(this.state.currentPage);
                }
            );
        } catch (error) {
            this.setState({ error: error });
        }
    };
    handlePageChange = (currentPage) => {
        try {
            window.scrollTo({ behavior: "smooth", top: "0px" });
            this.setState(
                {
                    currentPage: currentPage,
                    response: [],
                    isProcessing: true,
                },
                async () => {
                    await this.getThreadByCollectionId();
                }
            );

        } catch (error) {
            this.setState({ error: error });
        }
    };

}
export default withRouter(GenAICollectionsList);
