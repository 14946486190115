import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { sanitize } from 'dompurify';
import { Modal } from "react-bootstrap";
import GenAIAnsweringPreview from "../Preview/GenAIAnsweringPreview"
import GenAIAuthoringDisplayStringSup from "./GenAIAuthoringDisplayStringSup";


class GenAIAuthoringDisplayString extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayAns: props.displayAns ? props.displayAns : "",
            sourceNo: props.sourceNo ? props.sourceNo : [],
            pageNumber: props.pageNumber ? props.pageNumber : [],
            Sources: props.sources ? props.sources : [],
            contextIds: props.contextIds ? props.contextIds : [],
            selectedSource: null,
            selectedPageNumber: [],
            showPreviewModel: false,
            isMultiFiles: props.isMultiFiles,
            attachedFiles: props.attachedFiles,
            eventid: props.eventid,
            DisplayString: props.DisplayString ? props.DisplayString : [],
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.attachedFiles.length !== this.props.attachedFiles.length) {
            this.setState({ attachedFiles: nextProps.attachedFiles });
        }
    }
    renderModelPopup() {
        const { showPreviewModel, selectedSource, selectedPageNumber, isMultiFiles, attachedFiles } = this.state;
        return (<Modal
            show={showPreviewModel}
            size="xl"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="answering-popup-transition"
            className="z-index-preview"
            backdropClassName="z-index-preview-backdrop"
            scrollable={false}>
            <Modal.Body>
                <div style={{ overflow: "auto" }}>
                    <GenAIAnsweringPreview selectedSource={selectedSource} selectedPageNumber={selectedPageNumber}
                        attachedFiles={attachedFiles}
                        isMultiFiles={isMultiFiles}
                        handleClose={this.handlePreviewClose} />
                </div>
            </Modal.Body>
        </Modal>)
    }
    handleClickOnCitationNumber = (document_guid, pageNumbers) => {
        pageNumbers = pageNumbers.sort((a, b) => a - b);
        this.props.handleClickOnCitationNumber(document_guid, pageNumbers);
    }
    handleClickOnSourceNumber = (singleSource, pageNumbers) => {
        pageNumbers = pageNumbers.sort((a, b) => a - b);
        const { attachedFiles, isMultiFiles, eventid, DisplayString } = this.state;
        let prviewObject = {
            selectedSource: singleSource,
            selectedPageNumber: pageNumbers,
            attachedFiles: attachedFiles,
            isMultiFiles: isMultiFiles,
            DisplayString: DisplayString,
            isRecommendation: false,
        }
        let _id = "";
        if (isMultiFiles && singleSource.document_guid) {
            _id = singleSource.document_guid
        } else if (singleSource.searchID) {
            _id = singleSource.searchID
        }
        if (eventid && _id) {
            let width = 0.99 * window.innerWidth
            let height = 1.05 * window.innerHeight
            let left = 0.05 * window.innerWidth
            let top = 0.05 * window.innerHeight
            let windowPreview = window.open(`/genAIAnsweringPreview/${eventid}?docid=${_id}`, '', `width=${width},height=${height},top=${top},left=${left},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
            windowPreview[`${eventid}-${_id}`] = prviewObject;
        }
    }
    handlePreviewClose = async () => {

        this.setState({ showPreviewModel: false },
            async () => {
                setTimeout(() => {
                    document.body.classList.remove('custome-modal-open');
                }, 1000);
            })
    }
    renderSourceNo = () => {
        let { sourceNo, Sources, pageNumber, displayAns } = this.state;
        let { sessionID, Question, questionType, threadID } = this.props;
        return (<>
            {sourceNo.map((sitem, dsindex) => {
                let _sourceIndex = Sources.filter(x => x.contexts).findIndex(x => x.contexts.findIndex(y => y.context_id === sitem) !== -1);
                if (_sourceIndex > -1) {
                    let singleSource = Sources.filter(x => x.contexts)[_sourceIndex];
                    return (
                        <GenAIAuthoringDisplayStringSup key={`display-string-${dsindex}`} singleSource={singleSource} pageNumber={pageNumber[dsindex]} handleClickOnSourceNumber={this.handleClickOnSourceNumber} handleClickOnCitationNumber={this.handleClickOnCitationNumber} sitem={sitem} isMultiFiles={false}
                            sessionID={sessionID} Question={Question} questionType={questionType} threadID={threadID} />
                    )
                } else {
                    return (
                        <sup key={`display-string-${dsindex}`} className="gen-ai-answering-result-main-answer-sup">{sitem}</sup>
                    )
                }
            })}
        </>)
    }

    renderSourceNoMultiFiles = () => {
        let { sourceNo, Sources, pageNumber, displayAns, contextIds } = this.state;
        return (<>
            {contextIds.map((ctitem, ctindex) => {
                let _sourceIndex = Sources.filter(x => x.contexts).findIndex(x => x.contexts.findIndex(y => y.context_id === ctitem) !== -1);
                if (_sourceIndex > -1) {
                    let singleSource = Sources.filter(x => x.contexts)[_sourceIndex];
                    return (
                        <GenAIAuthoringDisplayStringSup key={`display-string-${ctindex}`} singleSource={singleSource} pageNumber={pageNumber[ctindex]} handleClickOnSourceNumber={this.handleClickOnSourceNumber} handleClickOnCitationNumber={this.handleClickOnCitationNumber} sitem={ctitem} isMultiFiles={true} />
                    )
                } else {
                    return (
                        <sup key={`display-string-${ctindex}`} className="gen-ai-answering-result-main-answer-sup">{ctitem}</sup>
                    )
                }
            })}
        </>)
    }
    render() {
        let { displayAns, isMultiFiles, contextIds } = this.state;
        return (<><span dangerouslySetInnerHTML={{ __html: sanitize(`${displayAns}`) }} />
            {(isMultiFiles && contextIds && contextIds.length > 0) && this.renderSourceNoMultiFiles()}
            {(!isMultiFiles) && this.renderSourceNo()}
            <br />
            {this.renderModelPopup()}
        </>
        )
    }
}
export default withRouter(GenAIAuthoringDisplayString);