
import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component, useEffect, useState } from "react";
import Button from "../Button";
import './CreateFolderPopUp.css';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import ApiServices from "../../utils/apiServices";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import axios from "axios";

 const DeleteLexiPopUp = (props) =>{
    const selectedId = props.id;
    const [alertMessage, setAlertMessage] = useState("");
    const [alertMessageShowModal,setAlertMessageShowModal] = useState(false);
    
    const handleDelete = async () => {
        const service = new ApiServices();
            //api
           let dbQuery =`delete from "iMS".phm_ims_ttsphoneme where id = ${props.id}`;
           let res = await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=${dbQuery}`);
           if (res) {
            setAlertMessage("Folder Name Deleted successfully");
            setAlertMessageShowModal(true)
        }
       }
    const closeAlertMessage = () => {
        setAlertMessage("");
        setAlertMessageShowModal(false);

    }
    return (<>
        <Modal
            show={props.showModal}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            scrollable={false}
        >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                <div className="popup-cancel-btn-wrapper">
                    <img
                        src={popCancelBttn}
                        alt=""
                        onClick={e => {
                            props.handleClose()
                        }}
                    />
                </div>
                <div>
                    <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                        <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                            <Modal.Title>
                                <div>
                                    Delete File
                                </div>
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                        <div className="reading-list-button-wrapper1">
                        <div className="text">Are you sure you want to delete the selected item ? This action is irreversible.</div>
                            <Button text="Delete" customColor='#0074B0' action={() => {
                                handleDelete()
                            }} />
                            <Button text="Cancel" customColor="#0074B0" action={() => {
                                props.handleClose()
                            }} />
                        </div>
                    </Modal.Body>
                </div>
            </div>
            {alertMessageShowModal && <AlertMessagePopUpModal color='#00c12c' content={alertMessage} 
            showModal={alertMessageShowModal} 
            handleClose={() => 
                {closeAlertMessage();
                props.handleClose();
                //window.location.reload();
            }} />}
        </Modal>
    </>)
 }
 export default DeleteLexiPopUp;