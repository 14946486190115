/* eslint-disable no-useless-escape */
import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component } from "react";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'
import './CollectionPopUp.css'
import ApiServices from "../../utils/apiServices";
import MobileViewButton from "../Button/MobileViewButton";
import RenameCollectionPopup from "./RenameCollectionPopUp";
import trackingService from "../../services/trackingService";
import { snippetLongString } from "../../utils/utilityServices"
import ConfirmMessagePopUpModal from "./ConfirmMessagePopUpModal"
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import SuccessReadingList from "./SucessReadingList";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";

class CollectionpopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateCollection: true,
      isManage: false,
      apiResponse: [],
      collectionName: '',
      successMessage: "",
      error: "",
      nodeName: "",
      isContinue: false,
      searchQuery: '',
      showRenamePop: false,
      itemtoBeRenamedName: '',
      itemtoBeRenamedID: '',
      itemtoBeRenamedSearch: '',
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageIndex: -1,
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
      selectedCollectionLists: this.props.threadCollections && this.props.threadCollections.length > 0 ? Object.assign([], this.props.threadCollections) : [],
      showSuccess: false,
      removedCollectionLists: [],
      isLoaded: false,
      isUpdateAnything: false
    };
  }
  async componentDidMount() {
    let wwid = JSON.parse(localStorage.getItem('wwid'));
    let service = new ApiServices();
    let apiRes = await service.getCollectionById(wwid);
    apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
    this.setState({
      apiResponse: apiRes,
      searchQuery: window.location.search,
      isLoaded: true
    })
    if (this.props.isfromUserProfile) {
      this.setState({ isCreateCollection: false, isManage: true })
    }
  }

  showConfirmMessage = (message, index) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageIndex: index
    })
  }

  confirmHandler = async () => {
    const { confirmMessageIndex } = this.state;
    let wwid = JSON.parse(localStorage.getItem('wwid'));
    let service = new ApiServices();
    await service.deleteCollection(wwid, confirmMessageIndex)
    this.setState({
      isLoaded: false,
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    }, async () => {
      let apiRes = await service.getCollectionById(wwid);
      apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
      this.setState({
        apiResponse: apiRes,
        isLoaded: true,
        isUpdateAnything: true
      })
    })
  }

  handleOnFocusInput = (e) => {
    this.setState({ nodeName: e.target.nodeName });
  };
  viewCollection = (index) => {
    let url;
    if(window.location.pathname.startsWith('/genAILibraryPOC')){
      url = `${window.location.origin}/genAICollectionPOC/${this.state.apiResponse[index].collection_id}`
    }
    else{
     url = `${window.location.origin}/genAICollection/${this.state.apiResponse[index].collection_id}`
    }
    window.open(url, '_blank');
  }

  deleteCollection = async (index) => {
    this.showConfirmMessage("Are you sure to delete this Collection?", index);
  }
  handleInput = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      collectionName: e.target.value
    })
  }
  successAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#00c12c",
      alertMessageShowModal: true
    })
  }
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }
  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false,
      showPreview: false,
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    let notebookList = this.state.apiResponse.map((data) =>
      data.collection_name.toLowerCase()
    );
    if (
      this.state.collectionName.length <= 0 &&
      this.state.selectedCollectionLists.length <= 0 &&
      this.state.nodeName !== "INPUT" &&
      this.state.removedCollectionLists.length === 0
    ) {
      formIsValid = false;
      this.errorAlertMessage("Please enter either new collection name or select any existing collection to proceed.")
      // errors["collectionName"] = 'Please enter a valid name to proceed.';
    }
    if (
      this.state.collectionName.length <= 0 &&
      this.state.nodeName === "INPUT" &&
      this.state.selectedCollectionLists.length <= 0
    ) {
      formIsValid = false;
      errors["collectionName"] = `Please enter a valid name to proceed.`;
    }
    if (notebookList.includes(this.state.collectionName.toLowerCase())) {
      formIsValid = false;
      errors[
        "collectionName"
      ] = `You already have a collection with entered title. Please enter a new title.`;
    }
    this.setState({ error: errors });
    return formIsValid;
  }

  indexData = (collectionId, collection_name, session_id, eventAction) => {
    let genAiAnswer = {
      "collectionid": collectionId,
      "collectionname": collection_name,
      "session_id": session_id,
    }
    const ts = new trackingService();
    ts.indexTrackingData(localStorage.getItem('userdisplayName'),
      localStorage.getItem('wwid'),
      localStorage.getItem('username'),
      localStorage.getItem('function'),
      localStorage.getItem('region'),
      localStorage.getItem('userproducts'),
      localStorage.getItem('doctypepref'),
      localStorage.getItem('sid'),
      localStorage.getItem('deviceType'),
      localStorage.getItem('deviceOS'),
      localStorage.getItem('browserName'),
      localStorage.getItem('browserVersion'),
      localStorage.getItem('jnjDepartment'),
      localStorage.getItem('companyname'),
      eventAction,
      '',
      window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      {}, {}, genAiAnswer);

  }

  onSaveCollection = async () => {
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    let { removedCollectionLists } = this.state;
    let service = new ApiServices();
    if (this.handleValidation()) {
      this.setState({ isLoaded: false })
      if (removedCollectionLists.length > 0) {
        await service.deleteAllThreadFromCollection(
          this.props.sessionId,
          removedCollectionLists
        );
        removedCollectionLists.map(x => {
          this.indexData(x, "", this.props.sessionId, "Remove from Collection");

        })
      }
      if (this.state.collectionName !== "") {
        let listID = uuidv4();
        await service.createCollection(
          wwid,
          `CL_${listID}`,
          this.state.collectionName,
          this.props.sessionId
        );
        this.indexData(`CL_${listID}`, this.state.collectionName, this.props.sessionId, "Create Collection");
        this.indexData(`CL_${listID}`, this.state.collectionName, this.props.sessionId, "Add to Collection");
        this.setState({
          successMessage:
            "Collection saved successfully.",
        });
      }
      if (this.state.selectedCollectionLists.length > 0) {
        for (const itr of this.state.selectedCollectionLists) {
          let nlName = this.state.apiResponse.find(
            (e) => e.collection_id === itr
          );

          await service.createCollection(
            wwid,
            itr,
            nlName.collection_name,
            this.props.sessionId
          );
          this.indexData(itr, nlName.collection_name, this.props.sessionId, "Add to Collection");
        }

        this.setState({
          successMessage:
            "Collection saved successfully.",
        });
      }

      if (
        this.state.collectionName !== "" &&
        this.state.selectedCollectionLists.length > 0
      ) {
        this.setState({
          successMessage:
            "Collection saved successfully.",
        });
      }

      if (this.props.threadCollections.length > 0) {
        this.setState({
          successMessage:
            "Collection saved successfully.",
        });
      }
      this.setState({
        isLoaded: true,
        showSuccess: true,
        isInsertSuccess: true
      });
    }
  };
  handleSuccessClose = (status) => {
    this.setState({
      showSuccess: status,
      isManage: true,
      isCreateCollection: false,
      isLoaded: false,
      isUpdateAnything: true
    },
      async () => {
        if (!window.location.pathname.startsWith('/genAISearch')) {
          let wwid = JSON.parse(localStorage.getItem("wwid"));
          let service = new ApiServices();
          let apiRes = await service.getCollectionById(wwid);
          this.setState(
            {
              apiResponse: apiRes,
              isLoaded: true,
            }
          );
        } else {
          this.props.handleClose(this.state.isUpdateAnything);
        }

      });
  };
  dateFormate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`
  }
  handleViewManageCollection = () => {
    let wwid = JSON.parse(localStorage.getItem('wwid'));
    let service = new ApiServices();
    this.setState({
      isCreateCollection: false,
      isManage: true,
      isLoaded: false
    }, async () => {
      let apiRes = await service.getCollectionById(wwid);
      apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
      this.setState({
        apiResponse: apiRes,
        isLoaded: true,
        isUpdateAnything: true
      })
    })
  }
  handleRename = (id, name) => {
    this.setState({
      showRenamePop: true,
      itemtoBeRenamedName: name,
      itemtoBeRenamedID: id,
    })
  }
  handleRenameClose = (status) => {
    this.setState({
      showRenamePop: status,
      isLoaded: false
    }, async () => {
      let wwid = JSON.parse(localStorage.getItem('wwid'));
      let service = new ApiServices();
      let apiRes = await service.getCollectionById(wwid);
      apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
      this.setState({
        apiResponse: apiRes,
        isLoaded: true,
        isUpdateAnything: true
      })
    })
  }
  handelAddCollection = () => {
    let params = new URLSearchParams(this.state.searchQuery)
    let query = params.get('query');
    let filter = params.get('filter');
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem('companyname'),
      `Create Collection`,
      "",
      window.location.href,
      query,
      filter
    );
  }
  handleCheckBox = (e) => {
    let { removedCollectionLists } = this.state;
    let arr = this.state.selectedCollectionLists;
    if (e.target.checked) {
      arr.push(e.target.value);
      if (removedCollectionLists.includes(e.target.value)) {
        removedCollectionLists = removedCollectionLists.filter(x => x !== e.target.value)
      }
    } else {
      if (this.props.threadCollections.includes(e.target.value)) {
        removedCollectionLists.push(e.target.value);
      }

      var idx = arr.indexOf(e.target.value);
      if (idx !== -1) {
        arr.splice(idx, 1);
      }
    }
    this.setState({
      selectedCollectionLists: arr,
      removedCollectionLists: removedCollectionLists
    });
  };
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
    const { confirmMessageContent, confirmMessageShowModal } = this.state;
    if (this.props.customWidth < 600) {
      return (
        <Modal
          show={this.props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition-mob-tab"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Modal.Title>
                    <div className="actionItem-headerPart-mobile">
                      {this.props.isfromUserProfile ? (
                        <>
                          <div className="alert-active-features-button">
                            <span>View/Manage Collections</span>
                          </div>
                        </>) : (
                        <>
                          <div onClick={e => { this.setState({ isCreateCollection: true, isManage: false }) }} className={this.state.isCreateCollection ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>Add to Collections</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                          <div onClick={this.handleViewManageCollection} className={this.state.isManage ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>View/Manage Collections</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "60vh" }}>
                {this.state.isCreateCollection &&
                  (
                    this.state.isContinue ?
                      <>
                        <span className="noAlertText-mobile">Your collection has been saved successfully.</span>
                        <br />
                        <span className="saved-collection-text-mobile">Please go to 'View/Manage Collections' tab to view all your saved ones.</span>
                      </>
                      :
                      <div>
                        <span className="create-collection-field" style={{ color: "black" }}>
                          Add Thread to Existing Collections :
                        </span>
                        {this.state.apiResponse.length > 0 ? (
                          <span>
                            <ul className="collection-list-wrapper" style={{ maxHeight: "21vh" }}>
                              {this.state.apiResponse.map((item) => {
                                return (
                                  <li key={item.collection_id}>
                                    <span>
                                      <input
                                        type="checkbox"
                                        value={item.collection_id}
                                        checked={this.state.selectedCollectionLists.includes(item.collection_id)}
                                        className="reading-list-checkbox-wrapper"
                                        onChange={e => this.handleCheckBox(e)}
                                      />
                                    </span>
                                    {item.collection_name}
                                  </li>
                                );
                              })}
                            </ul>
                            <br />
                          </span>
                        ) : (
                          <span>
                            You currently do not have any collections created.
                            <br />
                          </span>
                        )}
                        {this.props.sessionId ?
                          <span className="create-collection-field">Add Thread to a New Collection :</span> :
                          <span className="create-collection-field">Give a Name to Your Collection :</span>
                        }
                        <br />
                        <input type="text" onFocus={this.handleOnFocusInput} placeholder="Type here" value={this.state.collectionName} className="create-collection-input-box" onChange={this.handleInput} />
                        <br />
                        <span className="length-info">Name with a maximum of 100 characters.</span>
                        <div className="errorInvalidName">{this.state.error["collectionName"]}</div>
                        <br />
                      </div>
                  )
                }
                {this.state.isManage &&
                  <div>
                    {this.state.isLoaded ? <>
                      {this.state.apiResponse.length > 0 ?
                        <span>
                          <table className="reading-list-view-wrapper">
                            <tr>
                              <th style={{ color: "#333", fontSize: "14.5px" }}>Collection Name</th>
                              <th style={{ textAlign: "center", color: "#333", fontSize: "14.5px" }}>Actions</th>
                            </tr>
                            <tbody>
                              {this.state.apiResponse.map((item, index) => {
                                return (<tr key={item.collection_id}>
                                  <td>{item.collection_name}</td>
                                  <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%", flexDirection: "column" }}><span onClick={() => this.viewCollection(index)} title="View" className="reading-list-action-text">View</span><span title="Rename" className="reading-list-action-text" onClick={e => this.handleRename(item.collection_id, item.collection_name)}>Rename</span><span onClick={e => this.deleteCollection(item.collection_id)} title="Delete" className="reading-list-action-text">Delete</span></td>
                                </tr>)
                              })}
                            </tbody>
                          </table>
                        </span>
                        : <><span className="noAlertText-mobile">No Collections.</span>
                          <br />
                          <span className="no-collection-text-mobile">It looks like you don't have any collections yet.</span>
                        </>}
                    </> :
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                      </div>
                    }
                  </div>
                }
              </Modal.Body>
              {this.state.isCreateCollection ?
                this.state.isContinue ?
                  <div className="reading-list-button-wrapper-mobile">
                    <MobileViewButton text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(this.state.isUpdateAnything);
                    }} />
                  </div>
                  :
                  <div className="gsf-popup-button-wrapper-mobile">
                    <div className="welcomeNxtBtn-mobile">
                      <MobileViewButton text="Add to Collection" customColor="#2EB000" action={this.onSaveCollection} />
                    </div>
                    {/* } */}
                    <div className="welcomeNxtBtn-mobile">
                      <MobileViewButton text="Cancel" customColor="#0074B0" action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(this.state.isUpdateAnything);
                      }} />
                    </div>

                  </div>
                :
                <div className="reading-list-button-wrapper-mobile">
                  <div className="welcomeNxtBtn-mobile">
                    <MobileViewButton text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(this.state.isUpdateAnything);
                    }} />
                  </div>
                </div>
              }
            </div>
          </div>
          {this.state.showSuccess && (
            <>
              <SuccessReadingList
                display={this.state.showSuccess}
                handleClose={(status) => this.handleSuccessClose(status)}
                data={this.state.successMessage}
                width={this.props.customWidth}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
          {this.state.showRenamePop &&
            <>
              <RenameCollectionPopup showModal={this.state.showRenamePop} handleClose={(status) => this.handleRenameClose(status)} collectionName={this.state.itemtoBeRenamedName} collectionId={this.state.itemtoBeRenamedID} searchProperty={this.state.itemtoBeRenamedSearch} apiResponse={this.state.apiResponse} customWidth={this.props.customWidth} />
              <div className="savemodal-outer-wrapper"></div>
            </>
          }
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeAlertMessage()} handleConfirm={() => this.confirmHandler()} />}
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        </Modal>
      );
    }
    else if (this.props.customWidth < 1000) {
      return (
        <Modal
          show={this.props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition-mob-tab"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "center" }}>
                <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div className="actionItem-headerPart">
                      {this.props.isfromUserProfile ? (
                        <>
                          <div className="alert-active-features-button">
                            <span>View/Manage Collections</span>
                          </div>
                        </>) : (
                        <>
                          <div onClick={e => { this.setState({ isCreateCollection: true, isManage: false }) }} className={this.state.isCreateCollection ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>Add to Collections</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                          <div onClick={this.handleViewManageCollection} className={this.state.isManage ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>View/Manage Collections</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh" }}>
                {this.state.isCreateCollection &&
                  (
                    this.state.isContinue ?
                      <>
                        <span className="noAlertText-mobile">Your collection has been saved successfully.</span>
                        <br />
                        <span className="saved-collection-text-mobile">Please go to 'View/Manage Collections' tab to view all your saved ones.</span>
                      </>
                      :
                      <div>
                        <span className="create-collection-field" style={{ color: "black" }}>
                          Add Thread to Existing Collections :
                        </span>
                        {this.state.apiResponse.length > 0 ? (
                          <span>
                            <ul className="collection-list-wrapper" style={{ maxHeight: "21vh" }}>
                              {this.state.apiResponse.map((item) => {
                                return (
                                  <li key={item.collection_id}>
                                    <span>
                                      <input
                                        type="checkbox"
                                        value={item.collection_id}
                                        checked={this.state.selectedCollectionLists.includes(item.collection_id)}
                                        className="reading-list-checkbox-wrapper"
                                        onChange={e => this.handleCheckBox(e)}
                                      />
                                    </span>
                                    {item.collection_name}
                                  </li>
                                );
                              })}
                            </ul>
                            <br />
                          </span>
                        ) : (
                          <span>
                            You currently do not have any collections created.
                            <br />
                          </span>
                        )}
                        {this.props.sessionId ?
                          <span className="create-collection-field">Add Thread to a New Collection :</span> :
                          <span className="create-collection-field">Give a Name to Your Collection :</span>
                        }
                        <br />
                        <input type="text" onFocus={this.handleOnFocusInput} placeholder="Type here" value={this.state.collectionName} className="create-collection-input-box" onChange={this.handleInput} />
                        <br />
                        <span className="length-info">Name with a maximum of 100 characters.</span>
                        <div className="errorInvalidName">{this.state.error["collectionName"]}</div>
                        <br />
                      </div>
                  )
                }
                {this.state.isManage &&
                  <div>
                    {this.state.isLoaded ? <>
                      {this.state.apiResponse.length > 0 ?
                        <span>
                          <table className="manage-personal-alerts-wrapper">
                            <tr>
                              <th style={{ width: "20%" }}>Collection Name</th>
                              {/* <th style={{ width: "20%", textAlign: "center" }} >Alert Frequency</th> */}
                              <th style={{ width: "20%", textAlign: "center" }}>Date Saved</th>
                              <th style={{ width: "20%", textAlign: "center" }}>Actions</th>
                            </tr>
                            <tbody>
                              {this.state.apiResponse.map((item, index) => {
                                return (
                                  <tr key={item.collection_id}>
                                    <td>{item.collection_name}</td>
                                    <td style={{ textAlign: "center" }}>{moment(item.added_on).format("D-MMM-YYYY")}</td>
                                    <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={() => this.viewCollection(index)} title="View" className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Rename" className="reading-list-action-text" onClick={e => this.handleRename(item.collection_id, item.collection_name)}>Rename</span><span className="action-items-separate-divider"></span><span onClick={e => this.deleteCollection(item.collection_id)} title="Delete" className="reading-list-action-text">Delete</span></td>
                                  </tr>)
                              })}
                            </tbody>
                          </table>
                        </span>
                        : <><span className="noAlertText-mobile">No Collections.</span>
                          <br />
                          <span className="no-collection-text-mobile">It looks like you don't have any collections yet.</span>
                        </>}
                    </> :
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                      </div>
                    }
                  </div>
                }
              </Modal.Body>
              {this.state.isCreateCollection ?
                this.state.isContinue ?
                  <div className="reading-list-button-wrapper-tablet">
                    <Button text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(this.state.isUpdateAnything);
                    }} />
                  </div>
                  :
                  <div className="gsf-popup-button-wrapper-tablet">
                    <div className="welcomeNxtBtn">
                      <Button text="Cancel" customColor="#0074B0" action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(this.state.isUpdateAnything);
                      }} />
                    </div>
                    <div className="welcomeNxtBtn">
                      <Button text="Add to Collection" customColor="#2EB000" action={this.onSaveCollection} />
                    </div>
                  </div>
                :
                <div className="reading-list-button-wrapper-tablet">
                  <Button text="Close" customColor="#0074B0" action={() => {
                    this.setState({ showPop: false });
                    this.props.handleClose(this.state.isUpdateAnything);
                  }} />
                </div>
              }
            </div>
          </div>
          {this.state.showSuccess && (
            <>
              <SuccessReadingList
                display={this.state.showSuccess}
                handleClose={(status) => this.handleSuccessClose(status)}
                data={this.state.successMessage}
                width={this.props.customWidth}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
          {this.state.showRenamePop &&
            <>
              <RenameCollectionPopup showModal={this.state.showRenamePop} handleClose={(status) => this.handleRenameClose(status)} collectionName={this.state.itemtoBeRenamedName} collectionId={this.state.itemtoBeRenamedID} searchProperty={this.state.itemtoBeRenamedSearch} apiResponse={this.state.apiResponse} customWidth={this.props.customWidth} />
              <div className="savemodal-outer-wrapper"></div>
            </>
          }
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeAlertMessage()} handleConfirm={() => this.confirmHandler()} />}
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        </Modal>
      );
    }
    else {
      return (
        <Modal
          show={this.props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="savePopUp"
          className="gen-ai-answering-search-spinner-popup"
          backdropClassName="z-index-gen-ai-answering-search-spinner-popup"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={e => {
                  this.setState({ showPop: false });
                  this.props.handleClose(this.state.isUpdateAnything);
                }}
              />
            </div>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div className={!this.props.isfromUserProfile ? "actionItem-headerPart" : "actionItem-headerPart-single"}>
                      {this.props.isfromUserProfile ? (
                        <>
                          <div className="alert-active-features-button">
                            <span>View/Manage Collections</span>
                          </div>
                        </>) : (
                        <>
                          <div onClick={e => { this.setState({ isCreateCollection: true, isManage: false }) }} className={this.state.isCreateCollection ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>Add to Collections</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                          <div onClick={this.handleViewManageCollection} className={this.state.isManage ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>View/Manage Collections</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "60vh", padding: " 1rem 3rem" }}>
                {this.state.isCreateCollection &&
                  (
                    this.state.isContinue ?
                      <>
                        <span className="no-collection-heading">Your collection has been saved successfully.</span>
                        <br />
                        <span className="no-collection-text">Please go to 'View/Manage Collections' tab to view all your saved ones.</span>
                      </>
                      : <>
                        {this.state.isLoaded ? <> <div>
                          {this.props.sessionId && <>
                            <span className="create-collection-field" style={{ color: "black" }}>
                              Add Thread to Existing Collections :
                            </span>
                            {this.state.apiResponse.length > 0 ? (
                              <span>
                                <ul className="collection-list-wrapper" style={{ maxHeight: "26vh" }}>
                                  {this.state.apiResponse.map((item) => {
                                    return (
                                      <li key={item.collection_id}>
                                        <span>
                                          <input
                                            type="checkbox"
                                            value={item.collection_id}
                                            checked={this.state.selectedCollectionLists.includes(item.collection_id)}
                                            className="reading-list-checkbox-wrapper"
                                            onChange={e => this.handleCheckBox(e)}
                                          />
                                        </span>
                                        {item.collection_name}
                                      </li>
                                    );
                                  })}
                                </ul>
                                <br />
                              </span>
                            ) : (
                              <span>
                                <br />
                                You currently do not have any collections created.
                                <br />
                                <br />
                                <br />
                              </span>
                            )}
                          </>}
                          {this.props.sessionId ?
                            <span className="create-collection-field">Add Thread to a New Collection :</span> :
                            <span className="create-collection-field">Give a Name to Your Collection :</span>
                          }
                          <br />
                          <input type="text" onFocus={this.handleOnFocusInput} placeholder="Enter Collection Name" value={this.state.collectionName} className="create-collection-input-box" onChange={this.handleInput} />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidName">{this.state.error["collectionName"]}</div>
                        </div></> :
                          <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                          </div>
                        }
                      </>
                  )
                }
                {this.state.isManage &&
                  <div>
                    {this.state.isLoaded ? <>
                      {this.state.apiResponse.length > 0 ?
                        <span>
                          <table className="manage-personal-alerts-wrapper">
                            <tr>
                              <th style={{ width: "20%" }}>Collection Name</th>
                              {/* <th style={{ width: "20%", textAlign: "center" }} >Alert Frequency</th> */}
                              <th style={{ width: "20%", textAlign: "center" }}>Date Saved</th>
                              <th style={{ width: "20%", textAlign: "center" }}>Actions</th>
                            </tr>
                            <tbody>
                              {this.state.apiResponse.map((item, index) => {
                                return (
                                  <tr key={item.collection_id}>
                                    <td>{item.collection_name}</td>
                                    <td style={{ textAlign: "center" }}>{moment(item.added_on).format("D-MMM-YYYY")}</td>
                                    <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={() => this.viewCollection(index)} title="View" className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Rename" className="reading-list-action-text" onClick={e => this.handleRename(item.collection_id, item.collection_name)}>Rename</span><span className="action-items-separate-divider"></span><span onClick={e => this.deleteCollection(item.collection_id)} title="Delete" className="reading-list-action-text">Delete</span></td>
                                  </tr>)
                              })}
                            </tbody>
                          </table>
                        </span>
                        : <><span className="no-collection-heading">No Collections.</span>
                          <br />
                          <span className="no-collection-text">It looks like you don't have any collections yet.</span>
                        </>}
                    </> :
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                      </div>
                    }
                  </div>
                }
              </Modal.Body>
              {this.state.isCreateCollection ?
                this.state.isContinue ?
                  <div className="reading-list-button-wrapper">
                    <Button text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(this.state.isUpdateAnything);
                    }} />
                  </div>
                  :
                  <div className="collection-popup-button-wrapper">
                    <Button text="Cancel" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(this.state.isUpdateAnything);
                    }} />
                    <Button text="Add to Collection" customColor="#2EB000" action={this.onSaveCollection} />
                  </div>
                :
                <div className="reading-list-button-wrapper">
                  <Button text="Close" customColor="#0074B0" action={() => {
                    this.setState({ showPop: false });
                    this.props.handleClose(this.state.isUpdateAnything);
                  }} />
                </div>
              }
            </div>
          </div>
          {this.state.showSuccess && (
            <>
              <SuccessReadingList
                display={this.state.showSuccess}
                handleClose={(status) => this.handleSuccessClose(status)}
                data={this.state.successMessage}
                width={this.props.customWidth}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
          {this.state.showRenamePop &&
            <>
              <RenameCollectionPopup showModal={this.state.showRenamePop} handleClose={(status) => this.handleRenameClose(status)} collectionName={this.state.itemtoBeRenamedName} collectionId={this.state.itemtoBeRenamedID} searchProperty={this.state.itemtoBeRenamedSearch} apiResponse={this.state.apiResponse} customWidth={this.props.customWidth} />
              <div className="savemodal-outer-wrapper"></div>
            </>
          }
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeAlertMessage()} handleConfirm={() => this.confirmHandler()} />}
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        </Modal>
      );
    }
  }
}
export default CollectionpopUp;
