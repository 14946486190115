import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { footerLogo } from '../../assets';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import GenAIAskQuestion from './GenAIAskQuestion'
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import GenAIThreadHistory from './GenAIThreadHistory';
import ConfirmMessagePopUpModal from "./../PopUp/ConfirmMessagePopUpModal"
import { UserData } from "../../auth/Authorization";
import threadingService from "../../services/threadingService";
import ApiServices from "../../utils/apiServices";
import trackingService from "../../services/trackingService";

class GenAISidebar extends Component {
    static contextType = GenAIAnsweringContext
    constructor(props) {
        super(props);
        let isCollapsedSidebar = localStorage.getItem('isCollapsedSidebar');
        if (isCollapsedSidebar) {
            isCollapsedSidebar = JSON.parse(isCollapsedSidebar)
        }
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            collapsed: isCollapsedSidebar ? true : false,
            isNewThread: false,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,
        }
    }
    onclickLogo = () => {
        this.props.history.push('/');
    }
    handelCloseNewThreadModal = () => {
        this.setState({
            isNewThread: false
        })
    }
    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }
    confirmHandler = async () => {
        const { confirmMessageId } = this.state;
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                await this.deleteAnswerThreadDataBySessionId(confirmMessageId);
                this.setState({ confirmMessageId: -1 });
            }
        );
    }
    updateThreadAndCollection = async () => {
        try {
            await this.context.getAnswerThreadDataByUser();
            await this.context.getAnswerCollectionsDataByUser();
        } catch (error) {
            console.log(error);
        }

    }
    handleOndeleteAnswerThreadIndexTrackingData = (sessionid) => {
        let genAiAnswer = {

            "session_id": sessionid,
        }
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem("companyname"),
            "Delete Thread GenAIAnswers2.0",
            "",
            window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            {}, {}, genAiAnswer
        );

    }
    deleteAnswerThreadDataBySessionId = async (sessionid) => {
        this.context.updateLibraryThreadProcessing(true);
        this.context.updateLibraryCollectionProcessing(true);
        const ts = new threadingService();
        if (UserData && UserData.sub) {
            await ts.deleteAnswerThreadDataBySessionId(UserData.sub, sessionid)
                .then(async (response) => {
                    let service = new ApiServices();
                    await service.deleteThreadById(sessionid);
                    await this.context.deleteLatestAnswerThread(sessionid);
                    this.handleOndeleteAnswerThreadIndexTrackingData(sessionid);
                })
                .catch((error) => {
                    console.log(error);
                    this.context.updateLibraryThreadProcessing(false);
                    this.context.updateLibraryCollectionProcessing(false);

                }).finally(() => {
                    setTimeout(async () => {
                        await this.context.getLatestAnswerThread();
                        await this.updateThreadAndCollection();
                        this.context.updateLibraryThreadProcessing(false);
                        this.context.updateLibraryCollectionProcessing(false);
                        if (window.location.pathname.endsWith(sessionid)) {
                            this.props.history.push({
                                pathname: '/genAIAnswering',
                            })
                        }
                    }, 500);
                });
        } else {
            this.context.updateLibraryThreadProcessing(false);
            this.context.updateLibraryCollectionProcessing(false);
        }
    }

    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    handleDeleteThreadClick = (id) => {
        this.showConfirmMessage("Are you sure you want to delete this thread?", id)
    }
    renderModelNewThread() {
        let { isNewThread } = this.state;
        return (<Modal
            show={isNewThread}
            size="lg"
            centered
            onBackdropClick={e => { this.setState({ isNewThread: false }) }}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="savePopUp"
            className="document-processing-newthread"
            scrollable={false}
            backdropClassName="document-processing-newthread-backdrop"
            onHide={e => { this.setState({ isNewThread: false }) }}
        >
            <Modal.Body>
                <GenAIAskQuestion isOpenInModelPopup={true} customWidth={this.state.customWidth} customHeight={this.state.customHeight} handelCloseNewThreadModal={this.handelCloseNewThreadModal} key='GenAISidebar' customeKey='GenAISidebar' />
            </Modal.Body>
        </Modal>)
    }
    renderModeThreadHistory() {
        let { latestAnswerThreads } = this.context;
        return (<>
            {
                latestAnswerThreads.map((threadItem, threadIndex) => {
                    return (<GenAIThreadHistory source={threadItem._source} key={`Thread-History${threadItem._id}`} handleDeleteThreadClick={this.handleDeleteThreadClick} />)
                })
            }
        </>)
    }
    render() {
        let { collapsed } = this.state;
        let { latestAnswerThreads } = this.context;
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        let pathname = window.location.pathname
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<>
                <Sidebar className="gen-ai-answering-sidebar" collapsed={collapsed}>
                    <Menu>
                        <div className="gen-ai-answering-menuitem-header">
                            {collapsed ?
                                <>
                                    <div className="gen-ai-answering-menuitem-arrow-left-to-line" onClick={() => { localStorage.setItem('isCollapsedSidebar', false); this.setState({ collapsed: false }); this.context.menuCollapse(); }}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right-from-line" class="svg-inline--fa fa-arrow-right-from-line fa-fw fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M48 88c0-13.3-10.7-24-24-24S0 74.7 0 88V424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88zM440.4 273.5c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-12.9-7.6-17.5l-136-128c-9.7-9.1-24.8-8.6-33.9 1s-8.6 24.8 1 33.9L363.5 232H280l-128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l128 0h83.5l-91.9 86.5c-9.7 9.1-10.1 24.3-1 33.9s24.3 10.1 33.9 1l136-128z"></path></svg>
                                    </div>
                                </> :
                                <>
                                    <img src={footerLogo} onClick={this.onclickLogo} alt="iMedical Search" />
                                    <div className="gen-ai-answering-menuitem-arrow-left-to-line" onClick={() => { localStorage.setItem('isCollapsedSidebar', true); this.setState({ collapsed: true }); this.context.menuExpand(); }}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-left-to-line" class="svg-inline--fa fa-arrow-left-to-line fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88c0-13.3-10.7-24-24-24S0 74.7 0 88L0 424zM135.6 238.5c-4.8 4.5-7.6 10.9-7.6 17.5s2.7 12.9 7.6 17.5l136 128c9.7 9.1 24.8 8.6 33.9-1s8.6-24.8-1-33.9L212.5 280l83.5 0 128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-128 0-83.5 0 91.9-86.5c9.7-9.1 10.1-24.3 1-33.9s-24.3-10.1-33.9-1l-136 128z"></path></svg>
                                    </div>
                                </>
                            }

                        </div>
                        {/* <div className="gen-ai-answering-menuitem-new-thread">
                            {collapsed ?
                                <>
                                    <div class="gen-ai-answering-menuitem-new-thread-collapsed">
                                        <button type="button" onClick={e => { this.setState({ isNewThread: true }) }}>
                                            <div>
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" class="svg-inline--fa fa-plus fa-fw fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path></svg>
                                            </div>
                                        </button>
                                    </div>
                                </> :
                                <>
                                    <div class="gen-ai-answering-menuitem-new-thread-expand">
                                        <button type="button" onClick={e => { this.setState({ isNewThread: true }) }}>
                                            New Thread
                                        </button>
                                    </div>
                                </>
                            }
                        </div> */}
                        {(window.location.pathname.startsWith('/genAIAnsweringPOC') ||
                            window.location.pathname.startsWith('/genAILibraryPOC') ||
                            window.location.pathname.startsWith('/genAISearchPOC')) ?
                            <MenuItem component={<Link to="/genAIAnsweringPOC" />} className={pathname === "/genAIAnswering" ? "gen-ai-answering-menuitem-selected" : ""}>
                                {collapsed ?
                                    <div>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" class="svg-inline--fa fa-magnifying-glass fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
                                    </div> : <div className="gen-ai-answering-menuitem-home">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" class="svg-inline--fa fa-magnifying-glass fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
                                        <div className="gen-ai-answering-text-align-left relative">Home</div>
                                    </div>}
                            </MenuItem>
                            : <MenuItem component={<Link to="/genAIAnswering" />} className={pathname === "/genAIAnswering" ? "gen-ai-answering-menuitem-selected" : ""}>
                                {collapsed ?
                                    <div>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" class="svg-inline--fa fa-magnifying-glass fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
                                    </div> : <div className="gen-ai-answering-menuitem-home">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" class="svg-inline--fa fa-magnifying-glass fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
                                        <div className="gen-ai-answering-text-align-left relative">Home</div>
                                    </div>}
                            </MenuItem>}
                        {(window.location.pathname.startsWith('/genAIAnsweringPOC') ||
                            window.location.pathname.startsWith('/genAILibraryPOC') ||
                            window.location.pathname.startsWith('/genAISearchPOC')) ?
                            <MenuItem component={<Link to="/genAILibraryPOC" />} className={pathname === "/genAILibrary" ? "gen-ai-answering-menuitem-selected" : ""}>
                                {collapsed ? <div>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="rectangle-vertical-history" className="svg-inline--fa fa-rectangle-vertical-history fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M256 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H256zM192 64c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zM96 72c0-13.3 10.7-24 24-24s24 10.7 24 24V440c0 13.3-10.7 24-24 24s-24-10.7-24-24V72zM0 120c0-13.3 10.7-24 24-24s24 10.7 24 24V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V120z"></path></svg>
                                </div> : <div className="gen-ai-answering-menuitem-library">
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="rectangle-vertical-history" className="svg-inline--fa fa-rectangle-vertical-history fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M256 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H256zM192 64c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zM96 72c0-13.3 10.7-24 24-24s24 10.7 24 24V440c0 13.3-10.7 24-24 24s-24-10.7-24-24V72zM0 120c0-13.3 10.7-24 24-24s24 10.7 24 24V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V120z"></path></svg>
                                    <div className="gen-ai-answering-text-align-left relative">Library</div>
                                </div>}


                            </MenuItem>
                            : <MenuItem component={<Link to="/genAILibrary" />} className={pathname === "/genAILibrary" ? "gen-ai-answering-menuitem-selected" : ""}>
                                {collapsed ? <div>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="rectangle-vertical-history" className="svg-inline--fa fa-rectangle-vertical-history fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M256 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H256zM192 64c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zM96 72c0-13.3 10.7-24 24-24s24 10.7 24 24V440c0 13.3-10.7 24-24 24s-24-10.7-24-24V72zM0 120c0-13.3 10.7-24 24-24s24 10.7 24 24V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V120z"></path></svg>
                                </div> : <div className="gen-ai-answering-menuitem-library">
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="rectangle-vertical-history" className="svg-inline--fa fa-rectangle-vertical-history fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M256 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H256zM192 64c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zM96 72c0-13.3 10.7-24 24-24s24 10.7 24 24V440c0 13.3-10.7 24-24 24s-24-10.7-24-24V72zM0 120c0-13.3 10.7-24 24-24s24 10.7 24 24V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V120z"></path></svg>
                                    <div className="gen-ai-answering-text-align-left relative">Library</div>
                                </div>}


                            </MenuItem>}
                        <div className="gen-ai-answering-menuitem-thread-history">
                            {(!collapsed && latestAnswerThreads.length > 0) && this.renderModeThreadHistory()}
                        </div>
                        <MenuItem component={<Link to={{ pathname: 'https://jnj.sharepoint.com/:b:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/GenAI_Answers_Overview.pdf?csf=1&web=1&e=kBCpQO' }} target="_blank" />}>
                            {collapsed ?
                                <div>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="book" className="svg-inline--fa fa-book fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M0 88C0 39.4 39.4 0 88 0H392c30.9 0 56 25.1 56 56V344c0 22.3-13.1 41.6-32 50.6V464h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H80c-44.2 0-80-35.8-80-80c0-2.7 .1-5.4 .4-8H0V88zM80 400c-17.7 0-32 14.3-32 32s14.3 32 32 32H368V400H80zM48 358.7c9.8-4.3 20.6-6.7 32-6.7H392c4.4 0 8-3.6 8-8V56c0-4.4-3.6-8-8-8H88C65.9 48 48 65.9 48 88V358.7zM152 112H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 80H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path></svg>

                                </div> : <div className="gen-ai-answering-menuitem-home">
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="book" className="svg-inline--fa fa-book fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 88C0 39.4 39.4 0 88 0H392c30.9 0 56 25.1 56 56V344c0 22.3-13.1 41.6-32 50.6V464h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H80c-44.2 0-80-35.8-80-80c0-2.7 .1-5.4 .4-8H0V88zM80 400c-17.7 0-32 14.3-32 32s14.3 32 32 32H368V400H80zM48 358.7c9.8-4.3 20.6-6.7 32-6.7H392c4.4 0 8-3.6 8-8V56c0-4.4-3.6-8-8-8H88C65.9 48 48 65.9 48 88V358.7zM152 112H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 80H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path></svg>
                                    <div className="gen-ai-answering-text-align-left relative">Training Material</div>
                                </div>}
                        </MenuItem>
                    </Menu>
                </Sidebar>
                {this.renderModelNewThread()}
                {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
            </>
            )
        }
    }

}
export default withRouter(GenAISidebar);