/* eslint-disable array-callback-return */
import { Component } from "react";
import HeaderSearch from "../../components/header/HeaderSearch";
import FilterTest from "./FilterTest";
import Footer from "../../components/footer/Footer";
import queryString from 'query-string';
import processFilters from '../FilterComponent/ProcessFilter'
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import GenerateArrayStrings from "../../components/GenerateArrayStrings";
import { imsLoader } from "../../assets";
import DocumentPreview from "../../components/Preview/DocumentPreview";
import axios from "axios";
import "./TestResults.css";
class Test extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        this.state = {
            allFilters: {},
            selectedFilters: [],
            appliedFilters: [], //Filters that are applied for while search is happening
            username: '',
            userExists: false,
            searchKeyword: '',
            isProcessing: true,
            isLoaded: false,
            document: [], filters: {}, selectedParentFilterArr: [], previewData: ``, previewText: ``, count: 0,
            orignalText: 'Table 3: Adverse Reactions Occurring in ≥2% of Adult TRD Patients Treated with SPRAVATO +\n Oral AD at Any Dose and at a Greater Rate than Patients Treated with Placebo Nasal Spray +\n Oral AD \n SPRAVATO + Oral AD\n (N=346)\n Placebo + Oral AD\n (N=222)\n Cardiac disorders\n Tachycardia* 6 (2%) 1 (0.5%)\n Ear and labyrinth disorders\n Vertigo* 78 (23%) 6 (3%)\n Gastrointestinal disorders\n Nausea 98 (28%) 19 (9%)\n Vomiting 32 (9%) 4 (2%)\n Diarrhea 23 (7%) 13 (6%)\n Dry mouth 19 (5%) 7 (3%)\n Constipation 11 (3%) 3 (1%)\n General disorders and administration site conditions\n Feeling drunk 19 (5%) 1 (0.5%)\n Feeling abnormal 12 (3%) 0 (0%)\n Investigations Th\n Blood pressure increased* 36 (10%) 6 (3%)',
            requirements: 'I want a pie graph',
            thread_id: '',
            isProcessing: false,
        }
    }
    async componentDidMount() {
        document.title = 'iMedical Search';
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.wwid = JSON.parse(localStorage.getItem('wwid'));
        this.userExists = localStorage.getItem('userExists');
        let service = new Services();
        let apiService = new ApiServices();
        if (this.userExists) {
            this.setState({
                userExists: this.userExists
            })
            /*Get Approval Status of user and assign localStorage Values*/
            let approvedState = await apiService.getUserStatus(this.wwid);
            let apiRes = await apiService.getUserApprovedRegionRole(this.wwid);
            if (approvedState.status === 'Accepted') {
                localStorage.setItem('region', approvedState.regions);
                localStorage.setItem('function', JSON.stringify(approvedState.profession));
            }
            else {
                if (apiRes) {
                    localStorage.setItem('region', apiRes.region);
                    localStorage.setItem('function', JSON.stringify(apiRes.function));
                }
                else {
                    localStorage.setItem('region', approvedState.regions);
                    localStorage.setItem('function', JSON.stringify('Commercial'));
                }
            }
            /*Get the localStorage values to getAllFilters POST Call*/
            /* if(localStorage.getItem('contentpreference') == null){ */
            let res = await apiService.getUserPreference(this.wwid);
            localStorage.setItem('contentpreference', JSON.stringify(res.contentprefernce));
            /* localStorage.setItem('region',res.regions); */
            localStorage.setItem('userproducts', res.products);
            localStorage.setItem('doctypepref', res.documenttypes);
            /* } */
            let reqBody = {
                "function": localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": localStorage.getItem('doctypepref'),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, '')
            }
            let response = await service.getAllFilters(reqBody)
            if (response) {
                let processedFilters = processFilters(response.data.body)
                this.setState({
                    document: response.data.body.hits.hits,
                    isPopupModalOpen: false,
                    documentCount: response.data.body.hits.total.value,
                    allFilters: processedFilters,
                    filters: processedFilters,
                    isProcessing: false,
                    isLoaded: true
                    //metadata: response.data.body.hits.total.value.toString(),
                    //wwid: wwid
                })
            }
            //let preview = await apiService.getPreview();
            //console.log(preview.page_text);
            //let byteArrayToString = arrayBufferToString(preview)
            /* function arrayBufferToString(buffer) {
             var byteArray = new Uint8Array(buffer);
             var str = "", cc = 0, numBytes = 0;
             for (var i = 0, len = byteArray.length; i < len; ++i) {
                 var v = byteArray[i];
                 if (numBytes > 0) {
                     //2 bit determining that this is a tailing byte + 6 bit of payload
                     if ((cc & 192) === 192) {
                         //processing tailing-bytes
                         cc = (cc << 6) | (v & 63);
                         // }else{
                         //     throw new Error("this is no tailing-byte");
                     }
                 } else if (v < 128) {
                     //single-byte
                     numBytes = 1;
                     cc = v; */
            // }else if(v < 192){
            //     //these are tailing-bytes
            //     throw new Error("invalid byte, this is a tailing-byte")
            /*  } else if (v < 224) {
                 //3 bits of header + 5bits of payload
                 numBytes = 2;
                 cc = v & 31;
             } else if (v < 240) {
                 //4 bits of header + 4bit of payload
                 numBytes = 3;
                 cc = v & 15; */
            // } else {
            //     //UTF-8 theoretically supports up to 8 bytes containing up to 42bit of payload
            //     //but JS can only handle 16bit.
            //     throw new Error("invalid encoding, value out of range")
            /*  }

             if (--numBytes === 0) {
                 str += String.fromCharCode(cc);
             }
         }
         if (numBytes) {
             throw new Error("the bytes don't sum up");
         }
         return str;
     }
     let byteArrayToString = arrayBufferToString(preview.page_content.data);
     var uint8array = new TextEncoder("utf-8").encode(byteArrayToString);
     var string1 = new TextDecoder().decode(uint8array); */
            //console.log(string1);
            //let stringToArray = byteArrayToString.split(", 0,")
            //console.log(stringToArray);
            //console.log(byteArrayToString);
            //console.log(stringToArray[0]);
            //let arrayToInt = stringToArray.map(i => parseInt(i))
            //console.log(arrayToInt);
            /*  this.setState({
                  previewData :stringToArray,
                  previewText : preview.page_text */
            //})
            //console.log(atob(preview));
            /* await axios.get('https://predev-ims.jnj.com/api/PreviewService/Preview?strID=1a08dc3a4077c024d071f88b33f97c22&strPageNum=1&strKeyword=kwe',
            {headers:
            {'Content-Type': 'application/json',
            'x-api-key':`${process.env.REACT_APP_IMS_API_KEY}`
            }
            })
            .then((response)=>console.log(response))
            .catch((error)=>console.log(error)) */
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        //let params = queryString.parse(this.props.location.search);
        //console.log(this.props);
    }
    handleIsProcessing = (isLoaded) => {
        this.setState({
            isLoaded: isLoaded
        })
    }
    handleAllFilters = (allfilters, appliedfilters, selectedFilters) => {
        //console.log(selectedFilters)
        this.setState({
            allFilters: allfilters,
            appliedFilters: appliedfilters,
            selectedFilters: selectedFilters
        })

    }
    handleOnChangeInfilter = (allfilters, appliedfilters, selectedFilters) => {
        //console.log(selectedFilters)
        this.handleAllFilters(allfilters, appliedfilters, selectedFilters);
        let params = queryString.parse(this.props.location.search);
        var filters = appliedfilters;
        const newUrl = this.buildUrl(params.query, params.q, filters);
        this.props.history.push({ pathname: '/results', search: newUrl, state: { selectedFilters: selectedFilters, allFilters: allfilters, appliedFilters: appliedfilters } });
        //this.props.history.push({pathname:"/registration",state:{ fromDisclaimer : true}});
    }
    handleChangeInSearch = (query) => {
        //console.log(query)
        this.setState({
            searchKeyword: query
        })
    }
    generateArrayString = (array) => {
        let arrStr = ''
        //console.log(array)
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        //console.log(arrStr)
        return arrStr
    }
    buildUrl = (searchKeyword, dropdownValue, filters) => {
        let filterStr = GenerateArrayStrings(filters)
        if (searchKeyword === undefined || dropdownValue === undefined) {
            return `?query=&q=&filters=${filterStr}`;
        }
        else {
            return `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        }

    }
    findSearchKeyword = (keyword) => {
        if (this.state.previewText.length > 0) {

            let arr = this.state.previewText.split(' ');
            return (
                arr.map((i, index) => {
                    if (i.startsWith('thrombo')) {
                        return <div>{i} is present in position {index}</div>
                    }
                })
            )
        }
        //console.log(arrIdx.join(','));
        //return arrIdx.join(',');
    }
    componentDidUpdate() {
        //let elem = document.getElementById('page-content-1').querySelector('svg');
        //console.log(elem);
    }
    handleSummery = (e) => {
        this.setState({ orignalText: e.target.value })
    }
    handleRequirement = (e) => {
        this.setState({ requirements: e.target.value })
    }
    TestAPIClick = async () => {
        this.setState({ isProcessing: true });
        let { orignalText, requirements } = this.state;
        let payload = {
            "data": orignalText,
            "requirements": requirements
        }
        await axios.post(
            `https://predev-ims.jnj.com/api/graph/generate_graph`,
            payload,
            {
                headers: { "Content-Type": "application/json" },
            }
        ).then((response) => {
            if (response.data && response.data.status_code) {
                this.setState({ thread_id: response.data.thread_id });
                window.open('', "_blank", `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`).document.write(response.data.graph);
            } else {
                alert("Something went wrong.")
            }
            console.log(response)
        }).catch((error) => {
            console.log(error);
            alert("Something went wrong.")
        }).finally(() => {
            this.setState({ isProcessing: false });
        });
    }
    modifyGraphAPIClick = async () => {
        this.setState({ isProcessing: true });
        let { orignalText, requirements, thread_id } = this.state;
        let payload = {
            "data": orignalText,
            "change": requirements,
            "thread_id": thread_id
        }
        await axios.post(
            `https://predev-ims.jnj.com/api/graph/modify_graph`,
            payload,
            {
                headers: { "Content-Type": "application/json" },
            }
        ).then((response) => {

            if (response.data && response.data.status_code) {
                window.open('', "_blank", `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`).document.write(response.data.graph);
            } else {
                alert("Something went wrong.")
            }

            console.log(response)
        }).catch((error) => {
            console.log(error);
            alert("Something went wrong.")
        }).finally(() => {
            this.setState({ isProcessing: false });
        });;
    }
    render() {
        return (
            <div>
                {this.state.userExists ? <>
                    <HeaderSearch history={this.props.history} dropdownValue='All Fields' searchKeyword=''
                        appliedFilters={this.state.appliedFilters} handleChangeInSearch={this.handleChangeInSearch} />
                    <div className="recommended-contents-wrapper">
                        {/* <div className="filterMenuWrapper" style={{ float: "left", borderBottom: "solid 1px #dcdcdc", width: "20%" }}>
                            <div className="filterMenuHeader">
                                <span>Filters</span>
                            </div>
                            <div className="filter-menu-content">
                                {this.state.isLoaded ?
                                    <FilterTest allFilters={this.state.allFilters}
                                        filters={this.state.filters}
                                        appliedFilters={this.state.appliedFilters}
                                        handleAllfilters={this.handleAllFilters}
                                        handleOnChangeInfilter={this.handleOnChangeInfilter}
                                        selectedFilters={this.state.selectedFilters}
                                        selectedParentFilterArr={this.state.selectedParentFilterArr}
                                        querytext={this.state.searchKeyword}
                                        isProcessing={this.state.isProcessing}
                                        handleIsProcessing={this.handleIsProcessing} />
                                    :
                                    <div style={{ textAlign: "center", padding: "10%" }}>
                                        <img src={imsLoader} alt="Loading Filters" style={{ width: "20%" }} />
                                    </div>}
                            </div>
                        </div> */}
                        {/* <div className="content-shortcut-wrapper">
                        <GlobalProduct/>
                </div> */}
                        <div>
                            {/* <div id="page-content-1" dangerouslySetInnerHTML={{__html:this.state.previewData}}></div> */}
                            {/* <iframe src="https://predev-ims.jnj.com/api/PreviewService/Preview?strID=1a08dc3a4077c024d071f88b33f97c22&strPageNum=1&strKeyword=kwe" title=""></iframe> */}
                            {/* <DocumentPreview fileName={""}  synonyms={[]}/> */}

                            <div className="pocGraphSection">
                                <div className="pocGraphSourceData">
                                    <label>Source Data</label>
                                    <textarea className="txt-authoring-gpt-summarize" name="exportText" placeholder="Enter the source text here." style={{ width: "600px", height: "100px" }} value={this.state.orignalText} onChange={(e) => this.handleSummery(e)} />
                                </div>
                                <div className="pocGraphSourceData">
                                    <label>Requirement OR Modification</label>
                                    <textarea className="txt-authoring-gpt-summarize" name="exportText" placeholder="Enter the requirement here." style={{ width: "600px", height: "100px" }} value={this.state.requirements} onChange={(e) => this.handleRequirement(e)} />
                                </div>
                                <div className="pocGraphButton">
                                    {this.state.isProcessing ? <>
                                        <div style={{ textAlign: "center", padding: "10%" }}>
                                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                                        </div>
                                    </> : <>
                                        <button type="button" style={{ border: "solid 1px" }} onClick={this.TestAPIClick}>Generate Graph</button>
                                        <button type="button" style={{ border: "solid 1px" }} onClick={this.modifyGraphAPIClick}>Modify Graph</button></>}

                                </div>

                            </div>
                            <div>

                            </div>
                        </div>
                        <div style={{ display: "flex", flexFlow: "column", textAlign: "left", fontSize: "14px" }}>
                            {/* <div>Count of Search Keyword : {this.state.count}</div> */}
                            {/* this.findSearchKeyword(this.state.searchKeyword) */}
                        </div>
                    </div>
                    <div style={{ margin: "0% 1.5%", marginTop: "auto" }}>
                        <Footer />
                    </div> </>
                    :
                    <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                    </div>
                }
            </div>
        )
    }
}
export default Test;