import React, { Component, createRef, Fragment } from "react";
import { withRouter, Link } from 'react-router-dom';
import { sanitize } from 'dompurify';
import { correctCircle } from './../../assets'
import GenAIRecommendation from './GenAIRecommendation'
import GenAIDisplayString from './GenAIDisplayString'
import { Modal } from "react-bootstrap";
import GenAIAnsweringPreview from "../Preview/GenAIAnsweringPreview"
import GenAICombinedSummary from "./GenAICombinedSummary"
import { processFilters, GenAI2DocTypes } from '../../utils/documentTypeUtilityServices';
import GenAIDocumentTypesFilters from './GenAIDocumentTypesFilters';
import { isEqual, sortBy, clone } from 'underscore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import threadingService from "../../services/threadingService";
import trackingService from "../../services/trackingService";
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import { imsLoader, crossIcon, GenAiDOCIcon, GenAiPDFIcon, GenAiPPTIcon, quickActionDropDownBlack } from '../../assets/index';
import ApiServices from "../../utils/apiServices";
import { MISEHCP, AllContent, MISEHCPFilter, LocationOrigin, DocmentTypeForProtocolNo } from '../../utils/utilityServices';
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal";
import moment from "moment";
import OpenWindow from '../OpenWindow';
import GenerateArrayStrings from "../GenerateArrayStrings";
import ToggleSwitch from '../ToggleSwitch/index';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

let ts = new threadingService();
let _locationOrigin = LocationOrigin;
_locationOrigin = 'https://ims.jnj.com';
const ReactLike = 'Like';
const ReactUnlike = 'Unlike';
const ReactNone = 'None';

class GenAIResultIndex extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        // this.messagesEndRef = createRef()
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            Question: props.Question,
            OutputAnswer: props.OutputAnswer,
            // Sources: props.Sources,
            // DisplayString: props.DisplayString,
            RecommandationResults: props.RecommandationResults,
            isShowMore: false,
            selectedSource: null,
            selectedPageNumber: [],
            showPreviewModel: false,
            isInEditMode: false,
            updatedQuestion: props.Question,
            conversationIndex: props.conversationIndex,
            eventid: props.eventid,
            isMultiFiles: props.isMultiFiles,
            DisplayStringMultiFiles: props.DisplayStringMultiFiles,
            SourcesMultiFiles: props.SourcesMultiFiles,
            attachedFiles: props.attachedFiles,
            selectedFilters: props.selectedFilters ? Object.assign([], props.selectedFilters) : [],
            renderfilters: props.renderfilters ? Object.assign([], props.renderfilters) : [],
            updatedSelectedFilters: [],
            updatedRenderfilters: [],
            CombinedSummaryMultiFiles: props.CombinedSummaryMultiFiles,
            displayFilters: {},
            dtDropDownCollapsed: false,
            reaction: props.reaction ? props.reaction : ReactNone,
            feedback: props.feedback ? props.feedback : '',
            currentFeedback: props.feedback ? props.feedback : '',
            isComprehensive: props.isComprehensive ? clone(props.isComprehensive) : false,
            isFeedbackProcessing: false,
            isReactProcessing: false,
            isClickOnReportAns: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            isCopiedAns: false,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,
            lastCreatedDatehour: props.lastCreatedDatehour,
            RelevantInformation: props.RelevantInformation,
            ShowRelevantInformation: props.ShowRelevantInformation,
            threadAttachedFiles: (props.threadAttachedFiles && props.threadAttachedFiles.length > 0) ? clone(props.threadAttachedFiles) : [],
        }
    }
    componentDidMount() {
        this.generateDisplayFilters();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.attachedFiles.length !== this.props.attachedFiles.length) {
            this.setState({ attachedFiles: nextProps.attachedFiles });
        }
        if (nextProps.lastCreatedDatehour !== this.props.lastCreatedDatehour) {
            this.setState({ lastCreatedDatehour: nextProps.lastCreatedDatehour });
        }
        if (nextProps.ShowRelevantInformation !== this.props.ShowRelevantInformation) {
            this.setState({ ShowRelevantInformation: nextProps.ShowRelevantInformation });
        }

        if (nextProps.selectedFilters && this.props.selectedFilters && !isEqual(sortBy(nextProps.selectedFilters), sortBy(this.props.selectedFilters))) {
            this.setState({ selectedFilters: Object.assign([], nextProps.selectedFilters) });
        }
        if (nextProps.renderfilters && this.props.renderfilters && !isEqual(sortBy(nextProps.renderfilters), sortBy(this.props.renderfilters))) {
            this.setState({ renderfilters: Object.assign([], nextProps.renderfilters) }, () => {
                this.generateDisplayFilters();
            });
        }

        // if (nextProps.selectedFilters && nextProps.selectedFilters.length !== this.props.selectedFilters.length) {
        //     this.setState({ selectedFilters: nextProps.selectedFilters });
        // }
        // if (nextProps.renderfilters && nextProps.renderfilters.length !== this.props.renderfilters.length) {
        //     this.setState({ renderfilters: nextProps.renderfilters }, () => {
        //         this.generateDisplayFilters();
        //     });
        // }
        if (nextProps.reaction !== this.props.reaction) {
            this.setState({ reaction: nextProps.reaction });
        }
        if (nextProps.feedback !== this.props.feedback) {
            this.setState({ feedback: nextProps.feedback, currentFeedback: nextProps.feedback });
        }
        if (nextProps.isComprehensive !== this.props.isComprehensive) {
            this.setState({ isComprehensive: clone(nextProps.isComprehensive) });
        }
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        })
    }
    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }

    confirmHandler = async () => {
        this.setState(
            {
                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                let { Question, conversationIndex, eventid, selectedFilters, renderfilters } = this.state;
                await this.props.updateRunGenAISearch(Question, conversationIndex, eventid, selectedFilters, renderfilters);
            }
        );
    }

    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    handleSelectedFilters = (selectedFilters) => {
        this.setState({
            updatedSelectedFilters: selectedFilters
        })
    }
    handleRenderfilters = (renderfilters) => {
        this.setState({
            updatedRenderfilters: renderfilters
        })
    }
    generateDisplayFilters = () => {
        let { renderfilters } = this.state;
        const displayFilters = {}
        renderfilters.forEach(filter => {
            const filterParts = filter.split('/')
            const filterTop = filterParts.shift()
            if (filterTop !== 'Document Type') {
                displayFilters[filterTop] = displayFilters[filterTop] || []
                displayFilters[filterTop].push(filterParts.join('/'))
            } else {
                let documentTypeitem = Object.keys(GenAI2DocTypes).filter(x => GenAI2DocTypes[x] === filter);
                if (documentTypeitem) {
                    displayFilters[documentTypeitem] = displayFilters[documentTypeitem] || [];
                    displayFilters[documentTypeitem].push(filterParts.join('/'))
                }
            }
        })
        this.setState({ displayFilters: displayFilters })
    }
    renderModelPopup() {
        const { showPreviewModel, selectedSource, selectedPageNumber, attachedFiles, isMultiFiles } = this.state;
        return (<Modal
            show={showPreviewModel}
            size="xl"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="answering-popup-transition"
            className="z-index-preview"
            backdropClassName="z-index-preview-backdrop"
            scrollable={false}>
            <Modal.Body>
                <div style={{ overflow: "auto" }}>
                    <GenAIAnsweringPreview selectedSource={selectedSource}
                        selectedPageNumber={selectedPageNumber}
                        attachedFiles={attachedFiles}
                        isMultiFiles={isMultiFiles}
                        handleClose={this.handlePreviewClose} />
                </div>
            </Modal.Body>
        </Modal>)
    }
    handleClickOnSourceNumber = (singleSource, pageNumbers, DisplayString) => {

        pageNumbers = pageNumbers.sort((a, b) => a - b);
        const { attachedFiles, isMultiFiles, eventid } = this.state;
        let prviewObject = {
            selectedSource: singleSource,
            selectedPageNumber: pageNumbers,
            attachedFiles: attachedFiles,
            isMultiFiles: isMultiFiles,
            DisplayString: DisplayString,
            isRecommendation: false,
        }
        let _id = "";
        if (isMultiFiles && singleSource.document_guid) {
            _id = singleSource.document_guid
        } else if (singleSource.searchID) {
            _id = singleSource.searchID
        }
        if (eventid && _id) {
            let width = 0.99 * window.innerWidth
            let height = 1.05 * window.innerHeight
            let left = 0.05 * window.innerWidth
            let top = 0.05 * window.innerHeight
            let windowPreview = window.open(`/genAIAnsweringPreview/${eventid}?docid=${_id}`, '', `width=${width},height=${height},top=${top},left=${left},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
            windowPreview[`${eventid}-${_id}`] = prviewObject;
        }
    }
    handlePreviewClose = async () => {
        this.setState({ showPreviewModel: false },
            async () => {
                setTimeout(() => {
                    document.body.classList.remove('custome-modal-open');
                }, 1000);
            })
    }
    extractNCTId = (protocolIdsArr) => {
        var pidNCTArray = [];
        var pidStream = "";
        var nctStream = "";
        let nctId = protocolIdsArr.filter((elm) => elm.startsWith("NCT"));
        let pIds = protocolIdsArr.filter((elm) => !elm.startsWith("NCT"));
        if (pIds.length > 0) {
            pidStream = pIds.toString().split(",").join(";");
        }
        if (nctId.length > 0) {
            nctStream = nctId.toString().split(",").join(";");
        }
        pidNCTArray.push(pidStream);
        pidNCTArray.push(nctStream);
        return pidNCTArray;
    };
    handleProtocolID = (protocolIDs) => {
        let ids = GenerateArrayStrings(protocolIDs);
        let queryIds = protocolIDs.join(" OR ");
        let url =
            window.location.origin +
            `/results?query=${queryIds}&sbQuery=&q=All%20Fields&filters=&f1=protocolIDs&fval1=${ids}&fcount=1`;
        OpenWindow(url);
    }
    handleOnChangeToggleSwitch = (checked) => {
        this.setState({
            isComprehensive: checked
        })
    }
    indexDownloadData = (event) => {
        var docTitle = [event.target.getAttribute("data-title")];
        var searchID = [event.target.getAttribute("data-docid")];
        var url = [event.target.getAttribute("data-docurl")];
        var docNumber = [event.target.getAttribute("data-docNumber")];
        let { eventid } = this.state;
        let { sessionID, Question, questionType, threadID } = this.props;
        let assistantinfo = {
            "session_id": sessionID,
            "thread_id": threadID,
            "question_type": questionType,
            "question": Question,
            "eventid": eventid
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `View OR Download Answering2.0 Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', docNumber, searchID, url, '', '', `View OR Download Answering2.0 Document`, '', '', '', '', '', '', '', '', {}, {}, assistantinfo);
    };
    indexMultiFilesDocumentPreview = (docTitle) => {
        let { sessionID, Question, questionType, threadID } = this.props;
        let { eventid } = this.state;
        let assistantinfo = {
            "session_id": sessionID,
            "thread_id": threadID,
            "question_type": questionType,
            "question": Question,
            "ismultifiles": true,
            "eventid": eventid
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `Preview Answering2.0 Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', '', '', '', '', '', `Preview Answering2.0 Document`, '', '', '', '', '', '', '', '', {}, {}, assistantinfo);
    };
    indexDocumentPreview = (docTitle, searchID, url, docNumber) => {
        let { eventid } = this.state;
        let { sessionID, Question, questionType, threadID } = this.props;
        let assistantinfo = {
            "session_id": sessionID,
            "thread_id": threadID,
            "question_type": questionType,
            "question": Question,
            "ismultifiles": false,
            "eventid": eventid,
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `Preview Answering2.0 Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', docNumber, searchID, url, '', '', `Preview Answering2.0 Document`, '', '', '', '', '', '', '', '', {}, {}, assistantinfo);
    };
    renderSources = () => {
        let { Sources, DisplayString } = this.state;
        return (<div className="gen-ai-answering-result-source-main">
            <div className="gen-ai-answering-result-source-header">
                <div className="gen-ai-answering-result-source-header-icon">
                    <div className="w-[24px]">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="list-timeline" className="svg-inline--fa fa-list-timeline fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M219.9 112H400V80H219.9l-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM64 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0 160a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm187.9 16H400V400H219.9l-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM203.9 256l16 16H464V240H219.9l-16 16zm-13.3 54.6l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H480c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4z"></path>
                        </svg>
                    </div>
                </div>
                <div className="gen-ai-answering-result-source-header-title">Sources</div>
            </div>
            <div className="gen-ai-answering-result-source-items-wrapper">
                <div className="gen-ai-answering-result-source-items" style={{ width: `${Sources.length * 185}px` }}>
                    {Sources.map((conversation, conversationIndex) => {
                        let { context_id, context, document_title, searchID, download_url, doc_number, contexts, enclosureDocID, docType } = conversation;
                        let { protocolIDs, ctNames, ctNameID } = conversation;
                        let _protocolIDs = [], _ctNames = [];
                        if (protocolIDs) {
                            _protocolIDs = protocolIDs.split(', ');
                        }
                        if (ctNames) {
                            _ctNames = ctNames.replace('[, ]', '').split(', ');
                        }
                        let extractedIds = this.extractNCTId(_protocolIDs);
                        if (enclosureDocID && enclosureDocID.trim()) {
                            download_url += `&enclosureID=${enclosureDocID}`
                        }
                        if ((conversation.contexts && conversation.contexts.length > 0)) {
                            conversation.contexts = conversation.contexts.sort((a, b) => a.context_id - b.context_id);
                        }
                        return (
                            <div className="gen-ai-answering-result-source-item" key={`gen-ai-answering-result-source-item${conversationIndex}`}>
                                <div className="gen-ai-answering-result-source-item-title-wrapper gen-ai-answering-result-source-margin">
                                    <Link
                                        className="gen-ai-answering-result-source-item-title"
                                        id="docdata"
                                        to={{ pathname: download_url }}
                                        target="_blank"
                                        onClick={this.indexDownloadData}
                                        data-title={document_title}
                                        data-docid={searchID}
                                        data-docurl={download_url}
                                        data-docNumber={doc_number}
                                        title={`${conversationIndex + 1}. ${document_title.replace(/_/g, " ").replace(/-/g, " ")}`}
                                    >
                                        {conversationIndex + 1}. {document_title.replace(/_/g, " ").replace(/-/g, " ")}
                                    </Link>

                                </div>
                                <div className="gen-ai-answering-result-source-item-docnumber gen-ai-answering-result-source-margin">
                                    <b>{doc_number}</b>
                                </div>
                                {docType &&
                                    <div className="gen-ai-answering-result-source-item-docnumber gen-ai-answering-result-source-margin">
                                        {docType}
                                    </div>}
                                {(extractedIds && extractedIds.length > 1 && extractedIds[0] && DocmentTypeForProtocolNo.includes(docType)) && <div className="gen-ai-answering-result-source-item-protocol">
                                    <div className="gen-ai-answering-result-source-item-citation-lable gen-ai-answering-result-source-margin"><b>Protocol No:</b>&nbsp;          <a
                                        href="javascript:void(0)"
                                        title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab"
                                        onClick={(evt) =>
                                            this.handleProtocolID(
                                                extractedIds[0].split(';')
                                            )
                                        }>
                                        {GenerateArrayStrings((_ctNames.concat(extractedIds[0].split(';'))).filter(x => x))}
                                    </a></div>
                                </div>}
                                {(conversation.contexts && conversation.contexts.length > 0) &&
                                    <div className="gen-ai-answering-result-source-item-citations gen-ai-answering-result-source-margin">
                                        <div className="gen-ai-answering-result-source-item-citation-lable gen-ai-answering-result-source-item-citation-lable-custome"><b>Citation:</b></div>
                                        <div className="gen-ai-answering-result-source-item-citation-number">
                                            {
                                                conversation.contexts.map((conItem, consIndex) => {
                                                    let _displayStringFilter = DisplayString.filter(x => x.source_no.includes(conItem.context_id));
                                                    if (_displayStringFilter.length > 0) {
                                                        let _displayStringSingle = _displayStringFilter[0];
                                                        let displayIndex = _displayStringSingle.source_no.findIndex(x => x === conItem.context_id);

                                                        return (<Tippy content={<>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {document_title}
                                                            </div>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {doc_number}
                                                            </div>
                                                            {docType &&
                                                                <div className="gen-ai-answering-result-source-margin">
                                                                    {docType}
                                                                </div>}
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                Pages: {sortBy(_displayStringSingle.page_number[displayIndex]).join(', ')}
                                                            </div></>}>
                                                            <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} onClick={() => { this.indexDocumentPreview(document_title, searchID, download_url, doc_number); this.handleClickOnSourceNumber(conversation, _displayStringSingle.page_number[displayIndex], DisplayString); }} className="gen-ai-answering-result-main-answer-source-label">{conItem.context_id}</label>
                                                        </Tippy>)
                                                    } else {
                                                        return (<Tippy content={<>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {document_title}
                                                            </div>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {doc_number}
                                                            </div>
                                                            {docType &&
                                                                <div className="gen-ai-answering-result-source-margin">
                                                                    {docType}
                                                                </div>}
                                                        </>}>
                                                            <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} className="gen-ai-answering-result-main-answer-source-label">{conItem.context_id}</label>
                                                        </Tippy>)
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>)
    }
    handleHideShowRelevantInformation = () => {
        let { ShowRelevantInformation, conversationIndex } = this.state;
        this.setState({ ShowRelevantInformation: !ShowRelevantInformation }, async () => {
            await this.props.handleHideShowRelevantInformation(conversationIndex, !ShowRelevantInformation);
        });
    }
    renderRelevantInformation = () => {
        let { RelevantInformation } = this.state;
        let { updatedQuestion, conversationIndex, eventid, updatedSelectedFilters, updatedRenderfilters, isComprehensive, ShowRelevantInformation } = this.state;

        return (<>
            {Object.keys(RelevantInformation).filter((x, i) => { return (ShowRelevantInformation || i < 1) }).map((conversation, conversationIndex) => {
                let { Sources, DisplayString, CreatedDatehour } = RelevantInformation[conversation];
                return (<Fragment key={`renderRelevantInformation-${conversationIndex}`}>
                    {this.renderRelevantInformationDisplayString(Sources, DisplayString, conversationIndex)}
                    {Sources && Sources.length > 0 && this.renderRelevantInformationSources(Sources, DisplayString)}
                    <div className="gen-ai-answering-result-main-answer-control">
                        <div className="gen-ai-answering-result-main-answer-control-left">
                            {(Object.keys(RelevantInformation).length > 1 && ((!ShowRelevantInformation && conversationIndex == 0) || (ShowRelevantInformation && conversationIndex == Object.keys(RelevantInformation).length - 1))) &&
                                <>
                                    {ShowRelevantInformation ?
                                        <button className="gen-ai-answering-result-main-answer-control-hide-show" onClick={this.handleHideShowRelevantInformation}>
                                            <span>Hide </span>
                                            <span>
                                                <img src={quickActionDropDownBlack} alt="dropdownIcon" style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)", marginBottom: '3px' }} />
                                            </span>
                                        </button> :
                                        <button className="gen-ai-answering-result-main-answer-control-hide-show" onClick={this.handleHideShowRelevantInformation}>
                                            <span>Show </span>
                                            <span>
                                                <img src={quickActionDropDownBlack} alt="dropdownIcon" style={{ transform: null, marginBottom: '2px' }} />
                                            </span>
                                        </button>}</>
                            }
                        </div>
                        <div className="gen-ai-answering-result-main-answer-control-right">
                            {CreatedDatehour && <div className="gen-ai-answering-library-thread-item-time-ago" title={moment(CreatedDatehour).format("MMM DD, YYYY hh:mm a")} data-state="closed">
                                <span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                    </svg>
                                </span>
                                <span className="truncate">&nbsp; {moment(CreatedDatehour).format("MMM DD, YYYY hh:mm a")}</span>
                            </div>}
                        </div>
                    </div>
                    {((conversationIndex == Object.keys(RelevantInformation).length - 1) && Sources.length > 0) &&
                        <div className="gen-ai-answering-result-main-answer-control-report-ans-button">
                            <button type="button" className="gen-ai-answering-result-main-more-information-answer" title="More Information" onClick={async e => { await this.props.handleOnClickMoreInformation((parseInt(conversation) + 7), updatedQuestion, conversationIndex, eventid, updatedSelectedFilters, updatedRenderfilters, isComprehensive); }}>More Information</button>
                        </div>}
                    <hr style={{ margin: "0 20px 20px 20px" }}></hr>
                </Fragment>)
            })}
        </>)
    }
    renderRelevantInformationSources = (Sources, DisplayString) => {

        return (<div className="gen-ai-answering-result-source-main">
            <div className="gen-ai-answering-result-source-header">
                <div className="gen-ai-answering-result-source-header-icon">
                    <div className="w-[24px]">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="list-timeline" className="svg-inline--fa fa-list-timeline fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M219.9 112H400V80H219.9l-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM64 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0 160a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm187.9 16H400V400H219.9l-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM203.9 256l16 16H464V240H219.9l-16 16zm-13.3 54.6l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H480c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4z"></path>
                        </svg>
                    </div>
                </div>
                <div className="gen-ai-answering-result-source-header-title">Sources</div>
            </div>
            <div className="gen-ai-answering-result-source-items-wrapper">
                <div className="gen-ai-answering-result-source-items" style={{ width: `${Sources.length * 185}px` }}>
                    {Sources.map((conversation, conversationIndex) => {
                        let { context_id, context, document_title, searchID, download_url, doc_number, contexts, enclosureDocID, docType } = conversation;
                        let { protocolIDs, ctNames, ctNameID } = conversation;
                        let _protocolIDs = [], _ctNames = [];
                        if (protocolIDs) {
                            _protocolIDs = protocolIDs.split(', ');
                        }
                        if (ctNames) {
                            _ctNames = ctNames.replace('[, ]', '').split(', ');
                        }
                        let extractedIds = this.extractNCTId(_protocolIDs);
                        if (enclosureDocID && enclosureDocID.trim()) {
                            download_url += `&enclosureID=${enclosureDocID}`
                        }
                        if ((conversation.contexts && conversation.contexts.length > 0)) {
                            conversation.contexts = conversation.contexts.sort((a, b) => a.context_id - b.context_id);
                        }
                        return (
                            <div className="gen-ai-answering-result-source-item" key={`gen-ai-answering-result-source-item${conversationIndex}`}>
                                <div className="gen-ai-answering-result-source-item-title-wrapper gen-ai-answering-result-source-margin">
                                    <Link
                                        className="gen-ai-answering-result-source-item-title"
                                        id="docdata"
                                        to={{ pathname: download_url }}
                                        target="_blank"
                                        onClick={this.indexDownloadData}
                                        data-title={document_title}
                                        data-docid={searchID}
                                        data-docurl={download_url}
                                        data-docNumber={doc_number}
                                        title={`${conversationIndex + 1}. ${document_title.replace(/_/g, " ").replace(/-/g, " ")}`}
                                    >
                                        {conversationIndex + 1}. {document_title.replace(/_/g, " ").replace(/-/g, " ")}
                                    </Link>

                                </div>
                                <div className="gen-ai-answering-result-source-item-docnumber gen-ai-answering-result-source-margin">
                                    <b>{doc_number}</b>
                                </div>
                                {docType &&
                                    <div className="gen-ai-answering-result-source-item-docnumber gen-ai-answering-result-source-margin">
                                        {docType}
                                    </div>}
                                {(extractedIds && extractedIds.length > 1 && extractedIds[0] && DocmentTypeForProtocolNo.includes(docType)) && <div className="gen-ai-answering-result-source-item-protocol">
                                    <div className="gen-ai-answering-result-source-item-citation-lable gen-ai-answering-result-source-margin"><b>Protocol No:</b>&nbsp;          <a
                                        href="javascript:void(0)"
                                        title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab"
                                        onClick={(evt) =>
                                            this.handleProtocolID(
                                                extractedIds[0].split(';')
                                            )
                                        }>
                                        {GenerateArrayStrings((_ctNames.concat(extractedIds[0].split(';'))).filter(x => x))}
                                    </a></div>
                                </div>}
                                {(conversation.contexts && conversation.contexts.length > 0) &&
                                    <div className="gen-ai-answering-result-source-item-citations gen-ai-answering-result-source-margin">
                                        <div className="gen-ai-answering-result-source-item-citation-lable gen-ai-answering-result-source-item-citation-lable-custome"><b>Citation:</b></div>
                                        <div className="gen-ai-answering-result-source-item-citation-number">
                                            {
                                                conversation.contexts.map((conItem, consIndex) => {
                                                    let _displayStringFilter = DisplayString.filter(x => x.source_no.includes(conItem.context_id));
                                                    if (_displayStringFilter.length > 0) {
                                                        let _displayStringSingle = _displayStringFilter[0];
                                                        let displayIndex = _displayStringSingle.source_no.findIndex(x => x === conItem.context_id);

                                                        return (<Tippy content={<>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {document_title}
                                                            </div>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {doc_number}
                                                            </div>
                                                            {docType &&
                                                                <div className="gen-ai-answering-result-source-margin">
                                                                    {docType}
                                                                </div>}
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                Pages: {sortBy(_displayStringSingle.page_number[displayIndex]).join(', ')}
                                                            </div></>}>
                                                            <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} onClick={() => { this.indexDocumentPreview(document_title, searchID, download_url, doc_number); this.handleClickOnSourceNumber(conversation, _displayStringSingle.page_number[displayIndex], DisplayString); }} className="gen-ai-answering-result-main-answer-source-label">{conItem.context_id}</label>
                                                        </Tippy>)
                                                    } else {
                                                        return (<Tippy content={<>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {document_title}
                                                            </div>
                                                            <div className="gen-ai-answering-result-source-margin">
                                                                {doc_number}
                                                            </div>
                                                            {docType &&
                                                                <div className="gen-ai-answering-result-source-margin">
                                                                    {docType}
                                                                </div>}
                                                        </>}>
                                                            <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} className="gen-ai-answering-result-main-answer-source-label">{conItem.context_id}</label>
                                                        </Tippy>)
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>)
    }
    renderRelevantInformationDisplayString = (Sources, DisplayString, ansIndex) => {
        let { OutputAnswer, isMultiFiles, attachedFiles, eventid } = this.state;
        let { sessionID, Question, questionType, threadID } = this.props;
        if (DisplayString.length > 0) {
            return (<div className="gen-ai-answering-result-main-answer-wrapper-relevant-information">
                {DisplayString.map((dsitem, dsindex) => {
                    dsitem.display_ans = dsitem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                    return (<GenAIDisplayString key={`gen-ai-answering-result-main-answer${dsindex}`}
                        displayAns={dsitem.display_ans} sourceNo={dsitem.source_no}
                        pageNumber={dsitem.page_number} sources={Sources}
                        isMultiFiles={isMultiFiles} attachedFiles={attachedFiles} eventid={eventid}
                        DisplayString={DisplayString}
                        sessionID={sessionID} Question={Question} questionType={questionType} threadID={threadID} />)
                })}
            </div>)
        } else {
            if (ansIndex === 0) {
                return (<div className="gen-ai-answering-result-main-answer-wrapper-relevant-information">
                    No Answer found. Please try a new question.
                </div>)
            } else {
                return (<div className="gen-ai-answering-result-main-answer-wrapper-relevant-information">
                    No more information found.
                </div>)
            }
        }
    }
    getSourceFileName(singleFile) {
        let filename = singleFile.split('.').slice(0, -1).join('.');
        let fileExtention = singleFile.substring(singleFile.lastIndexOf('.') + 1, singleFile.length) || singleFile;
        filename = this.snippetLongStringForKeyword(filename);
        return `${filename} .${fileExtention}`;;
    }
    snippetLongStringForKeyword = (snippet, strLength = 35) => {
        if (snippet.length > strLength) {
            let text = snippet.substring(0, strLength)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }

    indexData = (eventAction, answer_reaction, answer_feedback) => {
        let { eventid, isMultiFiles } = this.state;
        let { sessionID, Question, questionType, threadID, OutputAnswer, Sources, DisplayString, DisplayStringMultiFiles, SourcesMultiFiles, selectedFilters, renderfilters, RelevantInformation } = this.props;
        let assistantinfo = {
            "session_id": sessionID,
            "thread_id": threadID,
            "question_type": questionType,
            "question": Question,
            "ismultifiles": isMultiFiles,
            "eventid": eventid,
            answer_reaction: answer_reaction,
            answer_feedback: answer_feedback,
            outputAnswer: OutputAnswer,
            sources: Sources,
            displayString: DisplayString,
            displayStringMultiFiles: DisplayStringMultiFiles,
            sourcesMultiFiles: SourcesMultiFiles,
            selectedFilters: selectedFilters ? Object.assign([], selectedFilters) : [],
            renderfilters: renderfilters ? Object.assign([], renderfilters) : [],
            relevantInformation: RelevantInformation
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'),
            localStorage.getItem('wwid'),
            localStorage.getItem('username'),
            localStorage.getItem('function'),
            localStorage.getItem('region'),
            localStorage.getItem('userproducts'),
            localStorage.getItem('doctypepref'),
            localStorage.getItem('sid'),
            localStorage.getItem('deviceType'),
            localStorage.getItem('deviceOS'),
            localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'),
            localStorage.getItem('jnjDepartment'),
            localStorage.getItem('companyname'),
            eventAction,
            '',
            window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            {}, {}, assistantinfo);

    }

    renderSourcesMultiFiles = () => {
        let { SourcesMultiFiles, DisplayStringMultiFiles } = this.state;
        return (<div className="gen-ai-answering-result-source-main">
            <div className="gen-ai-answering-result-source-header">
                <div className="gen-ai-answering-result-source-header-icon">
                    <div className="w-[24px]">
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="list-timeline" className="svg-inline--fa fa-list-timeline fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M219.9 112H400V80H219.9l-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM64 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0 160a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm187.9 16H400V400H219.9l-16 16 16 16zm-72.6-4.7c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3zM203.9 256l16 16H464V240H219.9l-16 16zm-13.3 54.6l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3c6-6 14.1-9.4 22.6-9.4H480c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4z"></path>
                        </svg>
                    </div>
                </div>
                <div className="gen-ai-answering-result-source-header-title">Sources</div>
            </div>
            <div className="gen-ai-answering-result-source-items-wrapper">
                <div className="gen-ai-answering-result-source-items" style={{ width: `${SourcesMultiFiles.length * 190}px` }}>
                    {SourcesMultiFiles.map((conversation, conversationIndex) => {
                        if ((conversation.contexts && conversation.contexts.length > 0)) {
                            conversation.contexts = conversation.contexts.sort((a, b) => a.context_id - b.context_id);
                        }
                        return (
                            <div className="gen-ai-answering-result-source-item" key={`gen-ai-answering-result-source-item${conversationIndex}`}>
                                <div className="gen-ai-answering-result-source-item-title-wrapper gen-ai-answering-result-source-margin">
                                    <span
                                        className="gen-ai-answering-result-source-item-title"
                                        title={`${conversationIndex + 1}. ${conversation.document_title}`}
                                        onClick={() => { this.indexMultiFilesDocumentPreview(conversation.document_title); this.handleClickOnSourceNumber(conversation, [1], DisplayStringMultiFiles) }}>
                                        {conversationIndex + 1}. {this.getSourceFileName(conversation.document_title.replace(/_/g, " ").replace(/-/g, " "))}
                                    </span>
                                </div>
                                {(conversation.contexts && conversation.contexts.length > 0) && <div className="gen-ai-answering-result-source-item-citations">
                                    <div className="gen-ai-answering-result-source-item-citation-lable gen-ai-answering-result-source-item-citation-lable-custome gen-ai-answering-result-source-margin"><b>Citation:</b></div>
                                    <div className="gen-ai-answering-result-source-item-citation-number">
                                        {
                                            conversation.contexts.map((conItem, consIndex) => {
                                                let _displayStringFilter = DisplayStringMultiFiles.filter(x => x.context_id && x.context_id.includes(conItem.context_id));
                                                if (_displayStringFilter.length > 0) {
                                                    let _displayStringSingle = _displayStringFilter[0];
                                                    let displayIndex = _displayStringSingle.context_id.findIndex(x => x === conItem.context_id);
                                                    return (<Tippy content={<>
                                                        <div className="gen-ai-answering-result-source-margin">
                                                            {conversation.document_title}
                                                        </div>
                                                        <div className="gen-ai-answering-result-source-margin">
                                                            Pages: {sortBy([_displayStringSingle.page_number[displayIndex]]).join(', ')}
                                                        </div></>}>
                                                        <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} onClick={() => { this.indexMultiFilesDocumentPreview(conversation.document_title); this.handleClickOnSourceNumber(conversation, [_displayStringSingle.page_number[displayIndex]], DisplayStringMultiFiles); }} className="gen-ai-answering-result-main-answer-source-label">{conItem.context_id}</label>
                                                    </Tippy>)
                                                } else {
                                                    return (<Tippy content={<>
                                                        <div className="gen-ai-answering-result-source-margin">
                                                            {conversation.document_title}
                                                        </div>
                                                    </>}>
                                                        <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} className="gen-ai-answering-result-main-answer-source-label">{conItem.context_id}</label>
                                                    </Tippy>)
                                                }
                                            })
                                        }
                                    </div>
                                </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>)
    }
    handleIsShowMore = (_isShowMore) => {
        this.setState({ isShowMore: _isShowMore }, () => {
            this.RecommendationEnd.scrollIntoView({ behavior: "smooth" });
        });
    }
    handleClickOnEditQuestion = () => {
        this.setState({
            isInEditMode: !this.state.isInEditMode,
            updatedQuestion: this.state.Question,
            updatedSelectedFilters: this.state.selectedFilters,
            updatedRenderfilters: this.state.renderfilters,
        }, () => {
            this.scrollToBottom();
        })
    }
    handleClickOnRewriteAnswer = () => {
        this.showConfirmMessage("Are you sure you want to refresh the answer?", "")
    }

    renderRecommendation = () => {
        let showResultNumber = 5;
        let { RecommandationResults, isShowMore, eventid } = this.state;
        let { sessionID, Question, questionType, threadID } = this.props;
        let totalDocuments = (isShowMore || RecommandationResults.length < showResultNumber) ? RecommandationResults.length : showResultNumber;
        return (<div className="gen-ai-answering-result-recommendation-items-wrapper">
            <div className="gen-ai-answering-result-recommendation-items">
                {RecommandationResults.filter((x, i) => (isShowMore || i < showResultNumber)).map((document, documentIndex) => {
                    return (<GenAIRecommendation key={`recommendation-documents-${documentIndex}`} documentInfo={document}
                        documentIndex={documentIndex + 1} totalDocuments={totalDocuments}
                        customWidth={this.state.customWidth} customHeight={this.state.customHeight} eventid={eventid}
                        sessionID={sessionID} Question={Question} questionType={questionType} threadID={threadID} />)
                })}
                {RecommandationResults.length > showResultNumber && <>{isShowMore ?
                    <>
                        <span className='cbm-show-more-less' onClick={() => this.handleIsShowMore(false)}>
                            Show Less
                        </span>
                    </>
                    :
                    <>
                        <span className='cbm-show-more-less' onClick={() => this.handleIsShowMore(true)}>
                            Show More
                        </span>
                    </>
                }
                </>
                }
            </div>
            <div style={{ float: "left", clear: "both" }}
                ref={(el) => { this.RecommendationEnd = el; }}>
            </div>
        </div>)

    }

    renderDisplayString = () => {
        let { Sources, DisplayString, OutputAnswer, isMultiFiles, attachedFiles, eventid } = this.state;
        let { sessionID, Question, questionType, threadID } = this.props;
        if (DisplayString.length > 0) {
            return (<div className="gen-ai-answering-result-main-answer-wrapper-relevant-information">
                {DisplayString.map((dsitem, dsindex) => {
                    dsitem.display_ans = dsitem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                    return (<GenAIDisplayString key={`gen-ai-answering-result-main-answer${dsindex}`}
                        displayAns={dsitem.display_ans} sourceNo={dsitem.source_no}
                        pageNumber={dsitem.page_number} sources={Sources}
                        isMultiFiles={isMultiFiles} attachedFiles={attachedFiles} eventid={eventid}
                        DisplayString={DisplayString}
                        sessionID={sessionID} Question={Question} questionType={questionType} threadID={threadID} />)
                })}
            </div>)
        } else {
            if (OutputAnswer === "We don't know the answer. Can you try new questions.") {
                OutputAnswer = `No Answer found. Please try a new question.`;
            }
            return (<div className="gen-ai-answering-result-main-answer-wrapper-relevant-information">
                {OutputAnswer}
            </div>)
        }
    }

    textDisplayString = () => {
        let { OutputAnswer, RelevantInformation } = this.state;
        let _textDisplayString = '';
        try {
            Object.keys(RelevantInformation).map((RIItem, RIIndex) => {
                let { DisplayString, Sources } = RelevantInformation[RIItem];
                let _displayString = clone(DisplayString);
                if (_displayString.length > 0) {
                    _displayString.map((dsitem, dsindex) => {
                        let _dsItem = clone(dsitem)
                        _dsItem.display_ans = _dsItem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                        _dsItem.display_ans = _dsItem.display_ans.replace(/<li>/g, '\n- <li>');
                        let regex = /<\/ul>/i;
                        _dsItem.display_ans = _dsItem.display_ans.replace(regex, '</ul>');
                        _textDisplayString += _dsItem.display_ans.replace(/(<([^>]+)>)/ig, '')
                        _dsItem.source_no.map((sitem, dsindex) => {
                            let _sourceIndex = Sources.filter(x => x.contexts).findIndex(x => x.contexts.findIndex(y => y.context_id === sitem) !== -1);
                            if (_sourceIndex > -1) {
                                let singleSource = Sources.filter(x => x.contexts)[_sourceIndex];
                                _textDisplayString += `[${sitem}]`
                            } else {
                                _textDisplayString += `[${sitem}]`
                            }
                        })
                        _textDisplayString = _textDisplayString.trim();
                        _textDisplayString += '\n';
                    })
                    _textDisplayString += "\n"
                    _textDisplayString += "Sources\n"
                    Sources.map((conversation, conversationIndex) => {
                        let { context_id, context, document_title, section_id, download_url, doc_number, contexts, enclosureDocID } = conversation;
                        if (enclosureDocID && enclosureDocID.trim()) {
                            download_url += `&enclosureID=${enclosureDocID}`
                        }
                        if ((conversation.contexts && conversation.contexts.length > 0)) {
                            conversation.contexts = conversation.contexts.sort((a, b) => a.context_id - b.context_id);
                        }
                        _textDisplayString += `${conversationIndex + 1}. Document Name: ${document_title}\n`
                        _textDisplayString += `Document URL: ${_locationOrigin}${download_url}\n`
                        conversation.contexts.map((conItem, consIndex) => {
                            let _displayStringFilter = DisplayString.filter(x => x.source_no.includes(conItem.context_id));
                            if (_displayStringFilter.length > 0) {
                                let _displayStringSingle = _displayStringFilter[0];
                                let displayIndex = _displayStringSingle.source_no.findIndex(x => x === conItem.context_id);
                                _textDisplayString += `Citation: [${conItem.context_id}], Pages No: [${_displayStringSingle.page_number[displayIndex].join(',')}]\n`
                            } else {
                                _textDisplayString += `Citation: [${conItem.context_id}]\n`
                            }
                        })
                        _textDisplayString += "\n"
                    })
                } else {
                    if (RIIndex === 0) {
                        _textDisplayString += `No Answer found. Please try a new question.\n`
                    } else {
                        _textDisplayString += `No more information found.\n`
                    }
                }
                _textDisplayString += "____________________________________________________________________________\n"
            })
        } catch (error) {
            _textDisplayString = JSON.stringify({ RelevantInformation: RelevantInformation })
        }
        return _textDisplayString;
    }
    textDisplayStringMultiFiles = () => {
        let { SourcesMultiFiles, DisplayStringMultiFiles, OutputAnswer } = this.state;

        let _textDisplayString = '';
        try {
            let _displayStringMultiFiles = clone(this.state.DisplayStringMultiFiles);
            if (_displayStringMultiFiles.length > 0) {

                SourcesMultiFiles.map((smitem, smindex) => {
                    let filterDisplayStringMultiFiles = _displayStringMultiFiles.filter(x => x.source_no.includes(smindex + 1));
                    _textDisplayString += `File: ${smitem.document_title}\n`;
                    filterDisplayStringMultiFiles.map((dsitem, dsindex) => {
                        let _dsItem = clone(dsitem)
                        _dsItem.display_ans = _dsItem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                        _dsItem.display_ans = _dsItem.display_ans.replace(/<li>/g, '\n- <li>');
                        let regex = /<\/ul>/i;
                        _dsItem.display_ans = _dsItem.display_ans.replace(regex, '</ul>');
                        _textDisplayString += _dsItem.display_ans.replace(/(<([^>]+)>)/ig, '')
                        if (_dsItem.context_id && Array.isArray(_dsItem.context_id)) {
                            _dsItem.context_id.map((ctitem, ctindex) => {
                                let _sourceIndex = SourcesMultiFiles.filter(x => x.contexts).findIndex(x => x.contexts.findIndex(y => y.context_id === ctitem) !== -1);
                                if (_sourceIndex > -1) {
                                    _textDisplayString += `[${ctitem}]`

                                } else {
                                    _textDisplayString += `[${ctitem}]`
                                }
                            })
                            _textDisplayString += `\n`;
                        }
                    })
                    _textDisplayString += "\n_________________________________________________________\n\n"


                })
                _textDisplayString += "Sources\n"
                SourcesMultiFiles.map((conversation, conversationIndex) => {
                    if ((conversation.contexts && conversation.contexts.length > 0)) {
                        conversation.contexts = conversation.contexts.sort((a, b) => a.context_id - b.context_id);
                    }
                    _textDisplayString += `${conversationIndex + 1}. Document Name: ${conversation.document_title}\n`
                    conversation.contexts.map((conItem, consIndex) => {
                        let _displayStringFilter = DisplayStringMultiFiles.filter(x => x.context_id && x.context_id.includes(conItem.context_id));
                        if (_displayStringFilter.length > 0) {
                            let _displayStringSingle = _displayStringFilter[0];
                            let displayIndex = _displayStringSingle.context_id.findIndex(x => x === conItem.context_id);

                            _textDisplayString += `Citation: [${conItem.context_id}], Pages No: [${[_displayStringSingle.page_number[displayIndex]].join(',')}]\n`

                        } else {
                            _textDisplayString += `Citation: [${conItem.context_id}]\n`
                        }
                    })

                })

            } else {
                _textDisplayString = OutputAnswer;
            }
        } catch (error) {
            _textDisplayString = JSON.stringify({ SourcesMultiFiles: SourcesMultiFiles, DisplayStringMultiFiles: DisplayStringMultiFiles })
        }
        return _textDisplayString;
    }
    renderDisplayStringMultiFiles = () => {
        let { SourcesMultiFiles, DisplayStringMultiFiles, OutputAnswer, isMultiFiles, attachedFiles, eventid, threadAttachedFiles } = this.state;
        let { sessionID, Question, questionType, threadID } = this.props;
        if (DisplayStringMultiFiles.length > 0) {
            return (<div className="gen-ai-answering-result-main-answer-wrapper">{
                SourcesMultiFiles.map((smitem, smindex) => {
                    let filterDisplayStringMultiFiles = DisplayStringMultiFiles.filter(x => x.source_no.includes(smindex + 1));
                    let isImageUpload = threadAttachedFiles.some(x =>
                        x.name.toLowerCase().endsWith('.gif')
                        || x.name.toLowerCase().endsWith('.jpeg')
                        || x.name.toLowerCase().endsWith('.jpg')
                        || x.name.toLowerCase().endsWith('.png'))
                    if (isImageUpload) {
                        filterDisplayStringMultiFiles = DisplayStringMultiFiles;
                    }

                    return (<>
                        <div className="gen-ai-answering-result-main-answer-display-string-multi-files-document-title">
                            <b>
                                {this.renderFileIcon(smitem.document_title)}
                                {/* <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" className="svg-inline--fa fa-file " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>*/}File: {smitem.document_title}</b>
                        </div>
                        {
                            filterDisplayStringMultiFiles.map((dsitem, dsindex) => {
                                dsitem.display_ans = dsitem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                                return (<GenAIDisplayString key={`gen-ai-answering-result-main-answer${dsindex}`}
                                    displayAns={dsitem.display_ans} sourceNo={dsitem.source_no}
                                    contextIds={dsitem.context_id}
                                    pageNumber={dsitem.page_number} sources={SourcesMultiFiles}
                                    isMultiFiles={isMultiFiles} attachedFiles={attachedFiles}
                                    eventid={eventid}
                                    DisplayString={DisplayStringMultiFiles}
                                    sessionID={sessionID} Question={Question} questionType={questionType} threadID={threadID} />)
                            })
                        }
                        {(SourcesMultiFiles.length > smindex + 1) && <hr style={{ margin: "10px 00px" }}></hr>}
                    </>)
                })
            }</div>)
        } else {
            let isImageUpload = threadAttachedFiles.some(x =>
                x.name.toLowerCase().endsWith('.gif')
                || x.name.toLowerCase().endsWith('.jpeg')
                || x.name.toLowerCase().endsWith('.jpg')
                || x.name.toLowerCase().endsWith('.png'))
            if (isImageUpload) {
                return (<div className="gen-ai-answering-result-main-answer-wrapper">
                    <span dangerouslySetInnerHTML={{ __html: sanitize(`${OutputAnswer}`) }} />
                </div>)
            } else {
                if (OutputAnswer === "We don't know the answer. Can you try new questions.") {
                    OutputAnswer = `No Answer found. Please try a new question.`;
                }
                return (<div className="gen-ai-answering-result-main-answer-wrapper">
                    {OutputAnswer}
                </div>)
            }


        }
    }
    renderDisplayFilters = () => {
        let { displayFilters } = this.state;
        return (<div className="gen-ai-answering-result-display-filters">
            <div className="gen-ai-answering-result-display-filters-lists">
                <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    width="16px" height="16px" viewBox="0 0 971.986 971.986">
                    <g>
                        <path d="M370.216,459.3c10.2,11.1,15.8,25.6,15.8,40.6v442c0,26.601,32.1,40.101,51.1,21.4l123.3-141.3
		c16.5-19.8,25.6-29.601,25.6-49.2V500c0-15,5.7-29.5,15.8-40.601L955.615,75.5c26.5-28.8,6.101-75.5-33.1-75.5h-873
		c-39.2,0-59.7,46.6-33.1,75.5L370.216,459.3z"/>
                    </g>
                </svg>
                {
                    Object.keys(displayFilters).map((x) => {
                        return (<div className="gen-ai-answering-result-display-filters-item">{x}</div>)
                    })
                }
            </div>
        </div>)
    }
    handleOnClickDisplayFilters = (e) => {
        let { dtDropDownCollapsed } = this.state;
        this.setState({ dtDropDownCollapsed: !dtDropDownCollapsed })
    }
    handleEditQuestion = (e) => {
        this.setState({ updatedQuestion: e.target.value })
    }
    handleClickOnCancelEditQuestion = (e) => {
        this.setState({
            isInEditMode: false,
            updatedQuestion: this.state.Question
        })
    }
    handleClickOnSaveEditQuestion = async (e) => {
        let { updatedQuestion, conversationIndex, eventid, updatedSelectedFilters, updatedRenderfilters, isComprehensive } = this.state;
        await this.props.updateRunGenAISearch(updatedQuestion, conversationIndex, eventid, updatedSelectedFilters, updatedRenderfilters, isComprehensive);
        this.setState({
            isInEditMode: false,
        })
    }
    scrollToBottom = () => {
        this.messagesEndRef.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
    handleOnClickReaction = async (_reaction) => {
        let { conversationIndex } = this.state;
        this.setState({ reaction: _reaction, isReactProcessing: true }, async () => {
            await this.props.handleOnClickReaction(conversationIndex, _reaction);
            let { eventid } = this.state;
            await ts.updateThreadAnswerReactionData(eventid, _reaction).then((response) => {
                this.indexData("Answering2.0 Reaction", _reaction, "");
                setTimeout(async () => {
                    await this.context.getLatestAnswerThread();
                }, 500);
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.setState({ isReactProcessing: false });
            });
        });
    }
    handleFeedback = (e) => {
        this.setState({ currentFeedback: e.target.value })
    }
    generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }
    handleOnClickReportAnswerSubmit = async () => {
        let { currentFeedback, isMultiFiles, conversationIndex, renderfilters } = this.state;
        let { sessionID, eventid, Question, questionType, threadID, isGetAnyAns, isSuccess } = this.props;

        let _display_answer = '';
        if (isMultiFiles) {
            _display_answer = this.textDisplayStringMultiFiles();
        } else {
            _display_answer = this.textDisplayString();
        }

        if (currentFeedback) {
            this.setState({ isFeedbackProcessing: true }, async () => {
                await this.props.handleOnClickFeedback(conversationIndex, currentFeedback);
                let filterStr = "";
                let selectedFilter = [];
                let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
                if (userAccessGroup && userAccessGroup.includes(MISEHCP) && !userAccessGroup.includes(AllContent)) {
                    selectedFilter.push(MISEHCPFilter);
                } else {
                    selectedFilter = processFilters(renderfilters);
                    if (selectedFilter.length === 0) {
                        // eslint-disable-next-line array-callback-return
                        Object.keys(GenAI2DocTypes).map(key => {
                            selectedFilter.push(GenAI2DocTypes[key]);
                        })
                    }
                }
                filterStr = this.generateArrayString(selectedFilter);
                let wwid = JSON.parse(localStorage.getItem('wwid'));
                let service = new ApiServices();
                await service.createGenAIAnswerFeedback(
                    wwid,
                    sessionID,
                    eventid,
                    Question,
                    _display_answer,
                    questionType,
                    threadID,
                    isMultiFiles,
                    currentFeedback,
                    isGetAnyAns,
                    isSuccess,
                    filterStr
                ).then(async (createResponse) => {
                    await ts.updateThreadAnswerReportFeedbackData(eventid, currentFeedback)
                        .then((response) => {
                            this.indexData("Answering2.0 Feedback", ReactNone, currentFeedback)
                            setTimeout(async () => {
                                await this.context.getLatestAnswerThread();
                                setTimeout(async () => {
                                    await this.context.getLatestAnswerThread();
                                    setTimeout(async () => {
                                        await this.context.getLatestAnswerThread();
                                    }, 1000);
                                }, 1000);
                            }, 600);
                        }).catch((error) => {
                            console.log(error)
                        })
                }).catch((error) => {
                    this.errorAlertMessage("Sorry, something went wrong.");
                    console.log(error)
                }).finally(() => {
                    this.setState({ feedback: currentFeedback, }, () => {
                        this.setState({ isFeedbackProcessing: false });
                    });
                });
            });
        }
    }
    handleClickOnCopyAns = () => {
        let { OutputAnswer, SourcesMultiFiles, DisplayStringMultiFiles, isMultiFiles, RelevantInformation } = this.state;
        let _textDisplayString = '';
        try {
            if (isMultiFiles) {
                let _displayStringMultiFiles = clone(this.state.DisplayStringMultiFiles);
                if (_displayStringMultiFiles.length > 0) {
                    SourcesMultiFiles.map((smitem, smindex) => {
                        let filterDisplayStringMultiFiles = _displayStringMultiFiles.filter(x => x.source_no.includes(smindex + 1));
                        _textDisplayString += `File: ${smitem.document_title}\n`;
                        filterDisplayStringMultiFiles.map((dsitem, dsindex) => {
                            let _dsItem = clone(dsitem)
                            _dsItem.display_ans = _dsItem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                            _dsItem.display_ans = _dsItem.display_ans.replace(/<li>/g, '\n- <li>');
                            let regex = /<\/ul>/i;
                            _dsItem.display_ans = _dsItem.display_ans.replace(regex, '</ul>');
                            _textDisplayString += _dsItem.display_ans.replace(/(<([^>]+)>)/ig, '')
                            if (_dsItem.context_id && Array.isArray(_dsItem.context_id)) {
                                _dsItem.context_id.map((ctitem, ctindex) => {
                                    let _sourceIndex = SourcesMultiFiles.filter(x => x.contexts).findIndex(x => x.contexts.findIndex(y => y.context_id === ctitem) !== -1);
                                    if (_sourceIndex > -1) {
                                        _textDisplayString += `[${ctitem}]`

                                    } else {
                                        _textDisplayString += `[${ctitem}]`
                                    }
                                })
                                _textDisplayString += `\n`;
                            }
                        })
                        _textDisplayString += "\n\n"
                    })
                    _textDisplayString += "Sources\n"
                    SourcesMultiFiles.map((conversation, conversationIndex) => {
                        if ((conversation.contexts && conversation.contexts.length > 0)) {
                            conversation.contexts = conversation.contexts.sort((a, b) => a.context_id - b.context_id);
                        }
                        _textDisplayString += `${conversationIndex + 1}. Document Name: ${conversation.document_title}\n`
                        conversation.contexts.map((conItem, consIndex) => {
                            let _displayStringFilter = DisplayStringMultiFiles.filter(x => x.context_id && x.context_id.includes(conItem.context_id));
                            if (_displayStringFilter.length > 0) {
                                let _displayStringSingle = _displayStringFilter[0];
                                let displayIndex = _displayStringSingle.context_id.findIndex(x => x === conItem.context_id);

                                _textDisplayString += `Citation: [${conItem.context_id}], Pages No: [${[_displayStringSingle.page_number[displayIndex]].join(',')}]\n`

                            } else {
                                _textDisplayString += `Citation: [${conItem.context_id}]\n`
                            }
                        })
                    })
                } else {
                    if (OutputAnswer === "We don't know the answer. Can you try new questions.") {
                        OutputAnswer = `No Answer found. Please try a new question.`;
                    }
                    _textDisplayString = OutputAnswer;
                }
            } else {
                Object.keys(RelevantInformation).map((RIItem, RIIndex) => {
                    let { DisplayString, Sources } = RelevantInformation[RIItem];
                    let _displayString = clone(DisplayString);
                    if (_displayString.length > 0) {
                        _displayString.map((dsitem, dsindex) => {
                            let _dsItem = clone(dsitem)
                            _dsItem.display_ans = _dsItem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                            _dsItem.display_ans = _dsItem.display_ans.replace(/<li>/g, '\n- <li>');
                            let regex = /<\/ul>/i;
                            _dsItem.display_ans = _dsItem.display_ans.replace(regex, '</ul>');
                            _textDisplayString += _dsItem.display_ans.replace(/(<([^>]+)>)/ig, '')
                            _dsItem.source_no.map((sitem, dsindex) => {
                                let _sourceIndex = Sources.filter(x => x.contexts).findIndex(x => x.contexts.findIndex(y => y.context_id === sitem) !== -1);
                                if (_sourceIndex > -1) {
                                    let singleSource = Sources.filter(x => x.contexts)[_sourceIndex];
                                    _textDisplayString += `[${sitem}]`
                                } else {
                                    _textDisplayString += `[${sitem}]`
                                }
                            })
                            _textDisplayString = _textDisplayString.trim();
                            _textDisplayString += '\n';
                        })
                        _textDisplayString += "\n"
                        _textDisplayString += "Sources\n"
                        Sources.map((conversation, conversationIndex) => {
                            let { context_id, context, document_title, section_id, download_url, doc_number, contexts, enclosureDocID } = conversation;
                            if (enclosureDocID && enclosureDocID.trim()) {
                                download_url += `&enclosureID=${enclosureDocID}`
                            }
                            if ((conversation.contexts && conversation.contexts.length > 0)) {
                                conversation.contexts = conversation.contexts.sort((a, b) => a.context_id - b.context_id);
                            }
                            _textDisplayString += `${conversationIndex + 1}. Document Name: ${document_title}\n`
                            _textDisplayString += `Document URL: ${_locationOrigin}${download_url}\n`
                            conversation.contexts.map((conItem, consIndex) => {
                                let _displayStringFilter = DisplayString.filter(x => x.source_no.includes(conItem.context_id));
                                if (_displayStringFilter.length > 0) {
                                    let _displayStringSingle = _displayStringFilter[0];
                                    let displayIndex = _displayStringSingle.source_no.findIndex(x => x === conItem.context_id);
                                    _textDisplayString += `Citation: [${conItem.context_id}], Pages No: [${_displayStringSingle.page_number[displayIndex].join(',')}]\n`
                                } else {
                                    _textDisplayString += `Citation: [${conItem.context_id}]\n`
                                }
                            })
                            _textDisplayString += "\n"
                        })
                    } else {
                        if (RIIndex === 0) {
                            _textDisplayString += `No Answer found. Please try a new question.\n`
                        } else {
                            _textDisplayString += `No more information found.\n`
                        }
                    }
                    _textDisplayString += "____________________________________________________________________________\n"
                })

            }
            navigator.clipboard.writeText(_textDisplayString).then(() => {
                this.setState({ isCopiedAns: true }, () => {
                    setTimeout(async () => {
                        this.setState({ isCopiedAns: false });
                    }, 3000);
                })
            })
        } catch (error) {
            console.log(error)
            this.errorAlertMessage("Sorry, something went wrong.");
        }
    }
    handleOnClickReportAnswer = async () => {
        let { isClickOnReportAns } = this.state;
        this.setState({ isClickOnReportAns: !isClickOnReportAns, currentFeedback: "" });
    }
    renderFileIcon = (name) => {
        if (name.toLowerCase().endsWith('.pdf')) {
            return (<><img src={GenAiPDFIcon} style={{ marginBottom: "5px" }} /></>)
        }
        else if (name.toLowerCase().endsWith('.doc') || name.toLowerCase().endsWith('.docx')) {
            return (<><img src={GenAiDOCIcon} style={{ marginBottom: "5px" }} /></>)
        }
        else if (name.toLowerCase().endsWith('.ppt') || name.toLowerCase().endsWith('.pptx')) {
            return (<><img src={GenAiPPTIcon} style={{ marginBottom: "2px" }} /></>)
        }
        else if (name.toLowerCase().endsWith('.jpg')
            || name.toLowerCase().endsWith('.jpeg')
            || name.toLowerCase().endsWith('.png')
            || name.toLowerCase().endsWith('.gif')) {
            return (<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5" stroke-width="0" fill="#059cf7" opacity="0.1"></polygon>
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5"></polygon>
                    <rect x="2" y="2" width="20" height="20" rx="0"></rect>
                    <line x1="6.99" y1="7" x2="7" y2="7" stroke-linecap="round" stroke-width="2"></line>
                </g>
            </svg> &nbsp;</>)
        }
        else {
            return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>)
        }
    }
    render() {
        let { Sources, Question, reaction, isReactProcessing, isInEditMode, updatedQuestion, isMultiFiles, SourcesMultiFiles } = this.state;
        let { RecommandationResults, CombinedSummaryMultiFiles, displayFilters, updatedSelectedFilters, updatedRenderfilters,
            selectedFilters, currentFeedback, isFeedbackProcessing, feedback, isClickOnReportAns, lastCreatedDatehour } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, isCopiedAns } = this.state;
        const { confirmMessageContent, confirmMessageShowModal, isComprehensive } = this.state;
        let isDisable = (Question === updatedQuestion && isEqual(sortBy(updatedSelectedFilters), sortBy(selectedFilters)) && isComprehensive === this.props.isComprehensive);
        if (this.state.customWidth >= 600 && this.state.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.state.customWidth > 1000) {
            return (<> <div className="gen-ai-answering-result-main">
                <div ref={(el) => { this.messagesEndRef = el; }} style={{ marginTop: "-50px" }}>&nbsp;</div>
                <div className="gen-ai-answering-result-left" >
                    {isInEditMode ?
                        <div className="gen-ai-answering-result-update-question">
                            <div className="gen-ai-answering-result-update-question-textarea-section">
                                <textarea placeholder="" className="" onChange={(e) => this.handleEditQuestion(e)} value={updatedQuestion} />
                            </div>
                            <div className="gen-ai-answering-result-update-question-control">
                                {/*(!isMultiFiles) && <div className="gen-ai-answering-main-action-section-comprehensive"><b>Comprehensive Answer</b>&nbsp;<ToggleSwitch key={`gen-ai-search-toggle-${this.props.conversationIndex}`} customKey={`gen-ai-search-toggle-${this.props.conversationIndex}`} isComprehensive={isComprehensive} handleOnChangeToggleSwitch={this.handleOnChangeToggleSwitch} /></div>*/}
                                <button onClick={this.handleClickOnCancelEditQuestion} type="button" className="gen-ai-answering-result-update-question-cancel">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.handleClickOnSaveEditQuestion} disabled={isDisable} className="gen-ai-answering-result-update-question-save">
                                    Save
                                </button>

                            </div>
                            {(!isMultiFiles) && <GenAIDocumentTypesFilters
                                handleSelectedFilters={this.handleSelectedFilters}
                                handleRenderfilters={this.handleRenderfilters}
                                selectedFilters={updatedSelectedFilters}
                                renderfilters={updatedRenderfilters}
                                customHeight={this.state.customHeight} customWidth={this.state.customWidth}
                                isOpenInPopup={false} />}
                        </div>
                        : <><div className="gen-ai-answering-result-question">
                            <h2 className="gen-ai-answering-result-question-h2">{Question}</h2>
                            <div className="gen-ai-answering-result-question-control-section">
                                <button type="button" onClick={this.handleClickOnEditQuestion} className="gen-ai-answering-result-main-answer-control-edit" title="Rewrite" data-state="closed">
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen-to-square" className="svg-inline--fa fa-pen-to-square fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z">
                                        </path>
                                    </svg>
                                    Rewrite
                                </button>
                                <button type="button" onClick={this.handleClickOnRewriteAnswer} className="gen-ai-answering-result-main-answer-control-rewrite" title="Refresh Answer" data-state="closed">
                                    <svg height="18px" width="16px" className="feather feather-repeat" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <polyline points="17 1 21 5 17 9" />
                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />
                                        <polyline points="7 23 3 19 7 15" />
                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                    </svg>
                                    Refresh
                                </button>
                            </div>
                        </div>
                            {(displayFilters && Object.keys(displayFilters).length > 0) && this.renderDisplayFilters()}
                        </>}

                    {isMultiFiles ? <><div className="gen-ai-answering-result-main-answer-main">
                        <div className="gen-ai-answering-result-main-answer-header">
                            <div className="gen-ai-answering-result-main-answer-header-icon">
                                <img src={correctCircle} alt="Loading Data" style={{ width: "24px" }} />
                            </div>
                            <div className="gen-ai-answering-result-main-answer-header-title">Summary</div>
                            <button title={isCopiedAns ? "Copied" : "Copy"} style={isCopiedAns ? { backgroundColor: "green" } : {}} className={isCopiedAns ? "gen-ai-answering-result-main-answer-control-copied" : "gen-ai-answering-result-main-answer-control-copy"} onClick={e => !isCopiedAns ? this.handleClickOnCopyAns() : () => { }}>
                                {isCopiedAns ?
                                    <svg aria-hidden="true" width="15px" height="15px" focusable="false" data-prefix="far" data-icon="clipboard" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.765 335.765">
                                        <g>
                                            <g>
                                                <polygon fill="#ffffff" points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 	" />
                                            </g>
                                        </g>
                                    </svg>
                                    :
                                    <svg width="15px" height="15px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clipboard" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path fill="currentColor" d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                                    </svg>
                                }
                            </button>
                        </div>
                        {this.renderDisplayStringMultiFiles()}
                    </div>
                        {SourcesMultiFiles && SourcesMultiFiles.length > 0 && this.renderSourcesMultiFiles()}
                    </>
                        :
                        <><div className="gen-ai-answering-result-main-answer-main-relevant-information">
                            <div className="gen-ai-answering-result-main-answer-header">
                                <div className="gen-ai-answering-result-main-answer-header-icon">
                                    <img src={correctCircle} alt="Loading Data" style={{ width: "24px" }} />
                                </div>
                                <div className="gen-ai-answering-result-main-answer-header-title">Summary</div>
                                <button title={isCopiedAns ? "Copied" : "Copy"} style={isCopiedAns ? { backgroundColor: "green" } : {}} className={isCopiedAns ? "gen-ai-answering-result-main-answer-control-copied" : "gen-ai-answering-result-main-answer-control-copy"} onClick={e => !isCopiedAns ? this.handleClickOnCopyAns() : () => { }}>
                                    {isCopiedAns ?
                                        <svg aria-hidden="true" width="15px" height="15px" focusable="false" data-prefix="far" data-icon="clipboard" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.765 335.765">
                                            <g>
                                                <g>
                                                    <polygon fill="#ffffff" points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 	" />
                                                </g>
                                            </g>
                                        </svg>
                                        :
                                        <svg width="15px" height="15px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clipboard" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path fill="currentColor" d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                            {this.renderRelevantInformation()}
                        </>}
                    <div className="gen-ai-answering-result-main-answer-control">
                        <div className="gen-ai-answering-result-main-answer-control-left">
                            <div className="gen-ai-answering-result-main-answer-control-reaction">
                                {isReactProcessing ? <img src={imsLoader} alt="" height={31} /> : <>
                                    <button title="Like" class={reaction === ReactLike ? "btn btn-like-green" : "btn"} onClick={e => {
                                        reaction === ReactLike ? this.handleOnClickReaction(ReactNone) : this.handleOnClickReaction(ReactLike)
                                    }}><FontAwesomeIcon fixedWidth icon="thumbs-up" /></button>
                                    <button title="Dislike" class={reaction === ReactUnlike ? "btn btn-unlike-red" : "btn"} onClick={e => {
                                        reaction === ReactUnlike ? this.handleOnClickReaction(ReactNone) : this.handleOnClickReaction(ReactUnlike)
                                    }}><FontAwesomeIcon fixedWidth icon="thumbs-down" /></button></>}
                            </div>
                            <div className={isFeedbackProcessing ? "gen-ai-answering-result-main-answer-control-report-ans-processing" : "gen-ai-answering-result-main-answer-control-report-ans"}>
                                {feedback ? <>
                                    <div className="gen-ai-answering-result-main-answer-control-report-message">
                                        <svg className="svg-inline--fa fa-pen-to-square fa-fw fa-1x " clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fill-rule="nonzero" fill="currentColor" /></svg>
                                        <span>Answer reported successfully.</span>
                                    </div>
                                </> : isFeedbackProcessing ? <img src={imsLoader} alt="" height={38} /> : <>
                                    {isClickOnReportAns ? <div className="gen-ai-answering-result-main-answer-control-report-ans-textbox-submit">
                                        <div className="gen-ai-answering-result-main-answer-control-report-ans-textbox">
                                            <input type="text" placeholder="Feedback" value={currentFeedback} onChange={(e) => this.handleFeedback(e)} />
                                        </div>
                                        <div className="gen-ai-answering-result-main-answer-control-report-ans-button">
                                            <button type="button" className="gen-ai-answering-result-main-answer-control-report-ans-button-submit" title="Submit" onClick={e => { this.handleOnClickReportAnswerSubmit() }}>Submit</button>
                                        </div>
                                        <div className="gen-ai-answering-result-main-answer-control-report-ans-close-button">
                                            <img src={crossIcon} onClick={e => { this.handleOnClickReportAnswer() }} />
                                        </div>
                                    </div> : <div className="gen-ai-answering-result-main-answer-control-report-ans-button">
                                        <button type="button" className="gen-ai-answering-result-main-answer-control-report-ans-button-report-answer" title="Report Answer" onClick={e => { this.handleOnClickReportAnswer() }}>Report Answer</button>
                                    </div>}
                                </>}
                            </div>
                        </div>
                        <div className="gen-ai-answering-result-main-answer-control-right">
                            {(lastCreatedDatehour && isMultiFiles) && <div className="gen-ai-answering-library-thread-item-time-ago" title={moment(lastCreatedDatehour).format("MMM DD, YYYY hh:mm a")} data-state="closed">
                                <span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                    </svg>
                                </span>
                                <span className="truncate">&nbsp; {moment(lastCreatedDatehour).format("MMM DD, YYYY hh:mm a")}</span>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="gen-ai-answering-result-right">
                    {RecommandationResults && RecommandationResults.length > 0 &&
                        <div className="gen-ai-answering-result-recommendation-wrapper">
                            <div className="gen-ai-answering-result-recommendation-header">
                                <div className="gen-ai-answering-result-recommendation-header-title">
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="layer-plus" className="svg-inline--fa fa-layer-plus fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M464 4c-11 0-20 9-20 20V60H408c-11 0-20 9-20 20s9 20 20 20h36v36c0 11 9 20 20 20s20-9 20-20V100h36c11 0 20-9 20-20s-9-20-20-20H484V24c0-11-9-20-20-20zM288 128c-8.5 0-17 1.7-24.8 5.1L53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 133.1c-7.8-3.4-16.3-5.1-24.8-5.1zm-5.9 49.2c1.9-.8 3.9-1.2 5.9-1.2s4 .4 5.9 1.2L477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256l183.8-78.8zM85.1 337.4L53.9 350.8C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4z"></path></svg>
                                    &nbsp;
                                    <span className="gen-ai-answering-result-recommendation-header-title-span">Recommendations</span>
                                </div>
                            </div>
                            {this.renderRecommendation()}
                        </div>
                    }
                    {(CombinedSummaryMultiFiles && isMultiFiles && ((Array.isArray(CombinedSummaryMultiFiles) && CombinedSummaryMultiFiles && CombinedSummaryMultiFiles.length > 0) || (!Array.isArray(CombinedSummaryMultiFiles) && CombinedSummaryMultiFiles))) && <div className="gen-ai-answering-result-combined-summary-wrapper">
                        <div className="gen-ai-answering-result-combined-summary-header">
                            <div className="gen-ai-answering-result-combined-summary-header-title">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                &nbsp;
                                <span className="gen-ai-answering-result-combined-summary-header-title-span">Overview</span>
                            </div>
                        </div>
                        <GenAICombinedSummary combinedSummaryMultiFiles={this.state.CombinedSummaryMultiFiles} />
                    </div>}
                </div>
            </div >
                {this.renderModelPopup()}
                {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} btnText="Yes" />}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </>)
        }
    }
}
export default withRouter(GenAIResultIndex);