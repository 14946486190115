import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal"
import trackingService from "../../services/trackingService";

class GenAIAuthoringSidebar extends Component {
    static contextType = GenAIAnsweringContext
    constructor(props) {
        super(props);

        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            collapsed: false,
            isNewThread: false,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,
            attachedFiles: (props.attachedFiles && props.attachedFiles.length > 0) ? props.attachedFiles : [],
            selectedAttachedFileguid: props.selectedAttachedFileguid ? props.selectedAttachedFileguid : '',
            selectedPageNo: props.selectedPageNo ? props.selectedPageNo : 1,
            previewURL: '',
            isChangePage: true,
        }
    }

    async componentDidMount() {
        if (this.props.attachedFiles && this.props.attachedFiles.length > 0) {
            const singleFile = this.props.attachedFiles[0];
            var url = URL.createObjectURL(singleFile);
            this.setState({
                previewURL: url, isChangePage: !this.state.isChangePage
            });
        }

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedAttachedFileguid !== this.props.selectedAttachedFileguid) {
            this.setState({ selectedAttachedFileguid: nextProps.selectedAttachedFileguid });
        }
        if (nextProps.selectedPageNo !== this.props.selectedPageNo) {
            this.setState({ selectedPageNo: nextProps.selectedPageNo, isChangePage: !this.state.isChangePage });
        }
    }
    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }
    confirmHandler = async () => {
        const { confirmMessageId } = this.state;
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                this.setState({ confirmMessageId: -1 });
            }
        );
    }

    handleOndeleteAnswerThreadIndexTrackingData = (sessionid) => {
        let genAiAnswer = {

            "session_id": sessionid,
        }
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem("companyname"),
            "Delete Thread GenAIAnswers2.0",
            "",
            window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            {}, {}, genAiAnswer
        );

    }


    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    handleChangefiles = (e) => {
        let _previewURL = '';
        let _attachedFiles = this.state.attachedFiles.filter(x => x.guid === e.target.value);
        if (_attachedFiles && _attachedFiles.length > 0) {
            const singleFile = _attachedFiles[0];
            _previewURL = URL.createObjectURL(singleFile);
        }
        this.setState({ selectedAttachedFileguid: e.target.value, previewURL: _previewURL }, () => {
            this.props.handleChangefiles(e.target.value);
        });
    };
    attachedFilesDropdownRender = () => {
        let { attachedFiles, selectedAttachedFileguid } = this.state;
        return (<select className="answering-authoring-file-dropdown" value={selectedAttachedFileguid} onChange={this.handleChangefiles}>
            {attachedFiles.map((item, index) => {
                return (<option key={`attachedFilesDropdown-${item.guid}`} value={item.guid}>{item.name}</option>)
            })}
        </select>)
    }


    render() {
        let { collapsed, isChangePage } = this.state;
        const { confirmMessageContent, confirmMessageShowModal, previewURL, selectedAttachedFileguid, selectedPageNo } = this.state;
        let pathname = window.location.pathname
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<>
                <Sidebar className={collapsed ? "gen-ai-answering-authoring-sidebar gen-ai-answering-authoring-sidebar-collapsed" : "gen-ai-answering-authoring-sidebar gen-ai-answering-authoring-sidebar-expand"} collapsed={collapsed}>
                    <Menu>
                        <div className="gen-ai-answering-authoring-menuitem-header">
                            {collapsed ?
                                <>
                                    <div className="gen-ai-answering-authoring-menuitem-arrow-left-to-line gen-ai-answering-authoring-expand-icon" onClick={() => { this.setState({ collapsed: false }); this.props.handleCollapsedClick(false); }}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right-from-line" class="svg-inline--fa fa-arrow-right-from-line fa-fw fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M48 88c0-13.3-10.7-24-24-24S0 74.7 0 88V424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88zM440.4 273.5c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-12.9-7.6-17.5l-136-128c-9.7-9.1-24.8-8.6-33.9 1s-8.6 24.8 1 33.9L363.5 232H280l-128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l128 0h83.5l-91.9 86.5c-9.7 9.1-10.1 24.3-1 33.9s24.3 10.1 33.9 1l136-128z"></path></svg>
                                    </div>
                                </> :
                                <>
                                    <div className="gen-ai-answering-authoring-menuitem-arrow-expand-left-to-line" >
                                        <div className="gen-ai-answering-authoring-collapsed-dropdown">
                                            <div className="gen-ai-answering-authoring-collapsed-icon" onClick={() => { this.setState({ collapsed: true }); this.props.handleCollapsedClick(true); }}>
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-left-to-line" class="svg-inline--fa fa-arrow-left-to-line fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88c0-13.3-10.7-24-24-24S0 74.7 0 88L0 424zM135.6 238.5c-4.8 4.5-7.6 10.9-7.6 17.5s2.7 12.9 7.6 17.5l136 128c9.7 9.1 24.8 8.6 33.9-1s8.6-24.8-1-33.9L212.5 280l83.5 0 128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-128 0-83.5 0 91.9-86.5c9.7-9.1 10.1-24.3 1-33.9s-24.3-10.1-33.9-1l-136 128z"></path></svg>
                                            </div>
                                            <div className="gen-ai-answering-authoring-dropdown">
                                                {this.attachedFilesDropdownRender()}
                                            </div>
                                        </div>
                                        <div className="gen-ai-document-answering-authoring-window-preview-panel">
                                            <object key={`gen-ai-document-window-preview-panel-${selectedAttachedFileguid}-${selectedPageNo}-${isChangePage}`} type="application/pdf" data={`${previewURL}#page=${selectedPageNo}`} height="100%" width="100%">
                                                <p>Unable to display PDF file.</p>
                                            </object>
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                    </Menu>
                </Sidebar>
                {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
            </>
            )
        }
    }

}
export default withRouter(GenAIAuthoringSidebar);